import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import DefaultLayout from '~/components/pages/_layouts/default'

const RouteWrapper = ({ component: Component, parent, ...rest }) => {
  const ParentLayout = parent || DefaultLayout

  return (
    <Route
      {...rest}
      render={props => (
        <ParentLayout>
          <Component {...props} />
        </ParentLayout>
      )}
    />
  )
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  parent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

RouteWrapper.defaultProps = { parent: DefaultLayout }

export default RouteWrapper
