import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import { v4 } from 'uuid'

import { Text, BlockCollapse, H3 } from '~/components/atoms'
import { getActiveLanguage, priceFormat } from '~/config/utils'

import * as S from './styled'

const ServiceSummary = ({ count }) => {
  const { invoice } = useSelector(state => state.auth || {})
  const { services } = invoice || {}

  return (
    <S.ServicesSummaryContainer id='service-summary-container' count={count}>
      <ServicesSummaryHeader />
      <ServiceSummaryList data={services?.data} isRdi={invoice?.isRdi} />
      <ServicePricesTotalItem total={services?.total} />
    </S.ServicesSummaryContainer>
  )
}

ServiceSummary.propTypes = { count: PropTypes.number }

ServiceSummary.defaultProps = { count: 0 }

const ServicesSummaryHeader = () => (
  <S.ServicesSummaryHeader>
    {/* <S.ServicesIcons>
        <S.ServicesIcon>
          <S.IconGroup>
            <Icon Source={SerIcon} width='32px' height='40px' color={theme.colors.orange.regular} />
          </S.IconGroup>
          <Text fontSize='16px' fontType='semibold' fontColor={theme.colors.orange.regular}>
            {t('Servicios')}
          </Text>
        </S.ServicesIcon>
      </S.ServicesIcons> */}
  </S.ServicesSummaryHeader>
)

const ServicePricesTotalItem = ({ total }) => {
  const { t } = useTranslation()

  return (
    <S.ServicePricesTotalItem>
      <Text fontSize='16px' fontType='semibold'>
        {t('Total servicios')}
      </Text>
      <H3 fontType='semibold'>{priceFormat(parseFloat(total))}</H3>
    </S.ServicePricesTotalItem>
  )
}

ServicePricesTotalItem.propTypes = { total: PropTypes.number }

ServicePricesTotalItem.defaultProps = { total: 0 }

const ServiceSummaryList = ({ data }) => {
  const lng = getActiveLanguage()

  if (!data) return null

  return (
    <S.ServiceSummaryList>
      {data.map(service => {
        if (service == null) return false
        const { title, description, concepts } = service || {}

        return (
          <S.ServicesSummaryDescription key={v4()}>
            <H3 fontType='semibold'>{title}</H3>
            {description ? (
              <BlockCollapse withBorder={false}>
                <Text>{description[lng] ? description[lng] : description}</Text>
              </BlockCollapse>
            ) : null}
            {concepts.map(serviceV2 => (
              <S.ServicePricesItem key={serviceV2.value}>
                <Text fontSize='16px'>{serviceV2.description}</Text>
                <H3>{priceFormat(parseFloat(serviceV2.value))}</H3>
              </S.ServicePricesItem>
            ))}
          </S.ServicesSummaryDescription>
        )
      })}
    </S.ServiceSummaryList>
  )
}

ServiceSummaryList.propTypes = { data: PropTypes.arrayOf(PropTypes.ServiceSummary) }

ServiceSummaryList.defaultProps = { data: null }

export default ServiceSummary
