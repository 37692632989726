import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { Text } from '~/components/atoms'

export const FooterContainer = styled.footer`
  background-color: ${theme.colors.blue.regular};
  padding: 25px 0 90px;
  margin-top: 40px;

  @media (min-width: ${theme.breakpoints.large}px) {
    padding: 75px 0 25px;
  }
`

export const FooterWrapper = styled.div`
  @media (min-width: ${theme.breakpoints.large}px) {
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
  }
`

export const FooterRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;

  @media (min-width: ${theme.breakpoints.large}px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }
`

export const FooterButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  svg {
    transform: rotate(${({ isActive }) => (isActive ? '-90deg' : '90deg')});
    transition: 0.3s ease-in-out;
  }
`

export const FooterColumnCarousel = styled.div`
  max-height: ${({ isActive }) => (isActive ? '500px' : '0px')};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`

export const FooterColumn = styled.div`
  height: auto;
  padding: 0 15px;

  h5 {
    color: ${theme.colors.white};
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    h5 {
      padding: 0 15px 0 0;
      margin-bottom: 30px;
      font-size: 1em;
      font-weight: bold;
    }
  }
`

export const FooterColumnList = styled.div`
  a {
    color: ${theme.colors.blue.lighter};
    p {
      color: ${theme.colors.blue.lighter};
      line-height: 23px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .image-group {
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    a {
      cursor: pointer;
      height: 86px;
      img {
        margin-right: 10px;
        margin-top: 10px;
      }
    }

    img {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
`
export const ImagesRow = styled.div`
  display: flex;
  padding: 10px 15px 40px 15px;
  border-bottom: 1px solid ${theme.colors.blue.light};

  > div {
    width: calc(((100% / 5) * 3) - 40px);

    img {
      height: 47px;
      width: auto;
      margin-right: 10px;
    }

    &:last-child {
      width: calc(((100% / 5) * 2) + 40px);

      img {
        height: 40px;
        width: auto;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.large}px) {
    flex-direction: column;

    > div {
      width: 100% !important;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
`

export const RRSS = styled.div`
  display: flex;
  padding: 55px 0 20px;
  position: relative;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${theme.breakpoints.large}px) {
  }
`

export const RrssLinks = styled.div`
  width: 230px;
  display: flex;
  justify-content: space-between;
`

export const FooterPolicy = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;

  > a {
    border-right: 1px solid ${theme.colors.blue.lighter};

    p {
      color: ${theme.colors.white};
    }

    &:last-child {
      border: none;
    }
  }
`

export const FooterPolicyItem = styled(Text)`
  padding: 0 15px;
  font-size: 0.85em;
`
