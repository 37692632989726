import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { theme } from '~/styles/theme'
import { getDateByLocale, getActiveLanguage, getWelcomeTextBySlug } from '~/config/utils'
import { Icon, Text, H1 } from '~/components/atoms'
import { WelcomeIcon } from '~/assets/icons'
import { useMediaQuery } from '~/hooks'

import * as S from './styled'
import { getTypeProduct } from '../../../config/utils'
import { getProductSummaryText } from './utils'

const Welcome = () => (
  <S.WelcomeContainer data-cy='welcome'>
    <S.WelcomeContent>
      <WelcomeTitle />
      <WelcomeUserData />
    </S.WelcomeContent>
  </S.WelcomeContainer>
)

const WelcomeTitle = () => {
  const lng = getActiveLanguage()
  const { t } = useTranslation()
  const { isMobile } = useMediaQuery()
  const { invoice } = useSelector(state => state.auth || {})
  const productType = getTypeProduct(invoice)
  const { billingData } = invoice || {}
  const { services, gas, electricity } = invoice || {}
  const invoiceDate = getDateByLocale(billingData?.billedAt, lng, 'LLLL yyyy', 'capitalize')
  const textProducts = getProductSummaryText(productType, electricity, gas, services, t)
  const { eco: ecoGas } = gas || {}
  const { eco: ecoElectricity } = electricity || {}

  const isRdi = invoice?.isRdi

  const ecoResult = () => ecoElectricity || ecoGas
  const ecoCheck = ecoResult(ecoElectricity, ecoGas)

  return (
    <S.WelcomeTitle>
      <Text fontSize={!isMobile ? '16px' : '14px'}>
        {isRdi
          ? `${t('Naturgy Iberia, S.A.')} ${t('- Estás en mercado libre')}`
          : `${t('Naturgy Clientes, S.A.U.')} ${t('- Estás en mercado libre')}`}
      </Text>
      <S.WelcomeDate>
        <Icon
          Source={WelcomeIcon}
          width={!isMobile ? '26px' : '20px'}
          height={!isMobile ? '37px' : '27px'}
          color={theme.colors.blue.regular}
        />
        <div>
          <Text fontSize={!isMobile ? '16px' : '14px'} fontType='semibold'>
            {t('Resumen de tu factura de')} {textProducts}
          </Text>
          <Text
            fontSize={!isMobile ? '24px' : '14px'}
            fontLineHeight={!isMobile ? '28px' : '17px'}
            fontType='semibold'
            fontColor={ecoCheck ? theme.colors.eco.regular : theme.colors.orange.regular}
          >
            {invoiceDate}
          </Text>
        </div>
      </S.WelcomeDate>
    </S.WelcomeTitle>
  )
}

const WelcomeUserData = () => {
  const { invoice } = useSelector(state => state.auth || {})
  const { t } = useTranslation()
  const { isMobile } = useMediaQuery()
  const lng = getActiveLanguage()
  const { welcome } = invoice || {}
  const { services } = invoice || {}
  const { gas } = invoice || {}
  const { electricity } = invoice || {}

  const userName = welcome?.customerName?.toLowerCase() || ''
  const address = welcome?.address || ''
  const productType = getTypeProduct(invoice)
  const textProducts = getProductSummaryText(productType, electricity, gas, services, t)
  const { notification } = welcome || {}

  const welcomeText = getWelcomeTextBySlug(notification, address, textProducts, Trans, lng, t)

  return (
    <S.UserDataWelcome>
      <H1 fontType='thin'>{`${t('Hola')}${userName !== '' ? ` ${userName},` : ','}`}</H1>
      <Text fontSize={!isMobile ? '20px' : '14px'} fontLineHeight={!isMobile ? '22px' : '17px'}>
        {welcomeText}
      </Text>
      {notification && (
        <Text fontSize={!isMobile ? '20px' : '14px'} fontLineHeight={!isMobile ? '22px' : '17px'} fontType='semibold'>
          {address}
        </Text>
      )}
    </S.UserDataWelcome>
  )
}

export default Welcome
