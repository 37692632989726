import { takeLatest, put, all, call } from 'redux-saga/effects'
import { Types } from './constants'
import { setLightHistoric, setGasHistoric } from './actions'
import { getLightHistoricFromApi, getGasHistoricFromApi } from '~/services/historic'

export function* setLightHistoricData(action) {
  const { token, qc, factnum } = action.payload

  try {
    if (token) {
      const historic = yield call(getLightHistoricFromApi, { token, qc, factnum })
      yield put(setLightHistoric({ lightHistoric: historic?.data || {} }))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* setGasHistoricData(action) {
  const { token, qc, factnum } = action.payload

  try {
    if (token) {
      const historic = yield call(getGasHistoricFromApi, { token, qc, factnum })
      yield put(setGasHistoric({ gasHistoric: historic?.data || {} }))
    }
  } catch (err) {
    console.log(err)
  }
}

export default all([
  takeLatest(Types.GET_lightHistoric, setLightHistoricData),
  takeLatest(Types.GET_gasHistoric, setGasHistoricData),
])
