export const theme = {
  colors: {
    black: '#111111',
    white: '#FFFFFF',
    yellow: '#FFED46',
    background: '#F2F5F8',
    backgroundAlternative: '#FCFCFC',
    separator: '#E5ECF0',
    sky: {
      regular: '#02A4FF',
      dark: '#81D1FF',
      light: '#83E4FF',
    },
    blue: {
      regular: '#004571',
      dark: '#005896',
      light: '#99B5C6',
      lighter: '#CCDAE3',
      chart: '#233984',
      chartLight: '#99B5C6',
    },
    orange: {
      regular: '#E57200',
      dark: '#FFC793',
      light: 'rgba(238, 114, 12, 0.5)',
      lighter: '#FFF4E9',
      chart: '#E5720A',
      inactive: '#EFAA66',
    },
    gray: {
      regular: '#dcdfe2',
      light: '#F2F6F8',
      lighter: '#F9F8F7',
      text: '#63666A',
    },
    red: {
      regular: '#FF1924',
      text: '#D3222A',
    },
    green: {
      regular: '#CFDD08',
      light: '#E7EE83',
      chart: '#8ABE2B',
    },
    eco: {
      regular: '#8ABE2B',
      dark: '#BFDA8F',
      light: '#DCE5D1',
      lighter: '#EDF2E8',
      inactive: '#B7D187',
      line: '#D0E5AA',
    },
    ecoAccent: {
      regular: '#A2AD00',
      dark: '#D0D680',
      light: '#ECEFCC',
      lighter: '#F6F7E5',
    },
  },
  gradients: {
    blue: 'linear-gradient(295.32deg, #004571 0%, #005A93 100%)',
    green: 'linear-gradient(139.23deg, #4E7E17 0%, #A2AD00 100%)',
    white: 'linear-gradient(144.27deg, #FFFFFF 0%, #FCFCFC 100%, #FCFCFC 100%)',
    blueLight: 'linear-gradient(180deg, #FFFFFF 0%, #F2F5F8 100%)',
  },
  breakpoints: {
    extraLarge: 1024,
    large: 992,
    tablet: 768,
    mobile: 680,
  },
  borderRadius: '10px',
}
