/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import * as S from './styled'
import { useMediaQuery } from '~/hooks'
import { Text, LinkRegular, H5, Icon } from '~/components/atoms'
import { getFooterItems } from './utils'
import { Flecha2 } from '~/assets/icons'
import { getActiveLanguage } from '~/config/utils'

const Footer = () => {
  const { t } = useTranslation()
  const lng = getActiveLanguage()
  const { isDesktop } = useMediaQuery()
  const links = getFooterItems(t)
  const [footerActive, setFooterActive] = useState(null)

  const { iframeMode } = useSelector(state => state.auth)

  if (iframeMode) return null

  const handlerSetActive = index => {
    if (footerActive === index) {
      setFooterActive(null)
    } else {
      setFooterActive(index)
    }
  }

  const renderImages = img => {
    if (img.link && img.image) {
      return (
        <LinkRegular externalPath={img.link} key={v4()}>
          <img src={img.image} alt='' />
        </LinkRegular>
      )
      // eslint-disable-next-line no-else-return
    } else if (!img.link && img.image) {
      return <img src={img.image} alt='' key={v4()} />
    }
    return null
  }

  if (!isDesktop) {
    return (
      <S.FooterContainer data-cy='footer'>
        <S.FooterWrapper>
          <S.FooterRow>
            {links.regularLinks.map((item, index) => (
              <S.FooterColumn isActive={footerActive === index} key={v4()}>
                <S.FooterButton onClick={() => handlerSetActive(index)} isActive={footerActive === index}>
                  <Text fontColor='white' fontSize='1em' fontType='semibold'>
                    {item.title}
                  </Text>
                  <Icon Source={Flecha2} color={'white'} width='18px' height='18px' />
                </S.FooterButton>
                <S.FooterColumnCarousel isActive={footerActive === index}>
                  <S.FooterColumnList>
                    {item.items.map(li => (
                      <Fragment key={v4()}>
                        {li.links[lng] && (
                          <LinkRegular externalPath={li.links[lng]}>
                            <Text fontType='light' fontSize='16px'>
                              {li.name}
                            </Text>
                          </LinkRegular>
                        )}
                      </Fragment>
                    ))}
                    {renderImages(item)}
                  </S.FooterColumnList>
                </S.FooterColumnCarousel>
              </S.FooterColumn>
            ))}
          </S.FooterRow>
          <S.ImagesRow>
            <div>{links.imagesLinks.column1.map(item => renderImages(item))}</div>
            <div>{links.imagesLinks.column2.map(item => renderImages(item))}</div>
          </S.ImagesRow>
          <S.RRSS>
            <S.RrssLinks>
              {links.rrssLinks.map(item => (
                <LinkRegular externalPath={item.link} key={v4()}>
                  <img src={item.image} alt='' />
                </LinkRegular>
              ))}
            </S.RrssLinks>
          </S.RRSS>
          <S.FooterPolicy>
            {links.policyLinks.map(item => (
              <LinkRegular externalPath={item.links[lng]} key={v4()}>
                <S.FooterPolicyItem fontType={item.fontType}>{item.name}</S.FooterPolicyItem>
              </LinkRegular>
            ))}
          </S.FooterPolicy>
        </S.FooterWrapper>
      </S.FooterContainer>
    )
  }

  return (
    <S.FooterContainer data-cy='footer'>
      <S.FooterWrapper>
        <S.FooterRow>
          {links.regularLinks.map(item => (
            <S.FooterColumn key={v4()}>
              <H5>{item.title}</H5>
              <S.FooterColumnList>
                {item.items.map(li => (
                  <Fragment key={v4()}>
                    {li.links[lng] && (
                      <LinkRegular externalPath={li.links[lng]}>
                        <Text fontType='light' fontSize='16px'>
                          {li.name}
                        </Text>
                      </LinkRegular>
                    )}
                  </Fragment>
                ))}
              </S.FooterColumnList>
            </S.FooterColumn>
          ))}
        </S.FooterRow>
        <S.ImagesRow>
          <div>{links.imagesLinks.column1.map(item => renderImages(item))}</div>
          <div>{links.imagesLinks.column2.map(item => renderImages(item))}</div>
        </S.ImagesRow>
        <S.RRSS>
          <S.RrssLinks>
            {links.rrssLinks.map(item => (
              <LinkRegular externalPath={item.link} key={v4()}>
                <img src={item.image} alt='' />
              </LinkRegular>
            ))}
          </S.RrssLinks>
        </S.RRSS>
        <S.FooterPolicy>
          {links.policyLinks.map(item => (
            <LinkRegular externalPath={item.links[lng]} key={v4()}>
              <S.FooterPolicyItem fontType={item.fontType}>{item.name}</S.FooterPolicyItem>
            </LinkRegular>
          ))}
        </S.FooterPolicy>
      </S.FooterWrapper>
    </S.FooterContainer>
  )
}

export default Footer
