import styled from 'styled-components/macro'

export const DefaultLayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`

export const ContentContainer = styled.div`
  width: 100%;
  min-height: calc (100% - 48px);
  height: auto;
`

export const RouteContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
`

export const ContentWrapper = styled.div`
  max-width: 100%;
`
