import produce from 'immer'

import { Types } from './constants'

export const INITIAL_STATE = {
  lightHistoric: null,
  gasHistoric: null,
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_lightHistoric: {
        draft.lightHistoric = action.payload.lightHistoric
        break
      }
      case Types.SET_gasHistoric: {
        draft.gasHistoric = action.payload.gasHistoric
        break
      }
      default:
        break
    }
  })
}
