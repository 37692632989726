import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import * as S from './styled'
import { CollapseIcon } from '~/assets/icons/'
import { Icon } from '~/components/atoms'
import { postIframeHeightEvent } from '~/config/utils'

/**
  Uso:

  <BlockCollapse
    defaultOpen={true} //abierto o cerrado (cerrado por defecto)
    buttonPosition='bottom' //posición del botón ['bottom', 'top']] (bottom por defecto)
    withBorder={true} //Con separador o sin separador (con separador por defecto)
  >
    <p>Texto de Prueba</p>
  </BlockCollapse>
*/

const BlockCollapse = ({ children, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const { iframeMode } = useSelector(state => state.auth)

  const handleClick = () => {
    setIsOpen(!isOpen)

    if (iframeMode) setTimeout(postIframeHeightEvent, 500)
  }

  return (
    <S.BlockCollapseContainer>
      <S.ButtonCollapse onClick={handleClick} isOpen={isOpen} data-cy='button-collapse'>
        <Icon width='40px' height='40px' Source={CollapseIcon} />
      </S.ButtonCollapse>
      <S.BlockCollapseWrapper className={isOpen && 'active'} isOpen={isOpen}>
        {children}
      </S.BlockCollapseWrapper>
    </S.BlockCollapseContainer>
  )
}

BlockCollapse.defaultProps = {
  children: null,
  defaultOpen: false,
}
BlockCollapse.propTypes = {
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
}

export default BlockCollapse
