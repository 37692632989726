import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const GasSummaryContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({ color }) => color};
  border-radius: ${theme.borderRadius};
  padding: 24px ${({ count }) => (count < 3 ? '48px' : '24px')};

  br {
    margin: 10px 0;
  }
`

export const GasSummaryHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;

  h3 {
    text-align: center;
  }
`

export const GasIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconGroup = styled.div`
  margin-bottom: 10px;
  display: flex;

  .eco-icon {
    position: relative;
    top: 0px;
    left: 0px;
  }
`

export const GasIcons = styled.div`
  margin-bottom: 20px;
  width: auto;
`

export const GasProduct = styled.div`
  width: 100%;
`

export const GasDates = styled.div`
  width: 100%;
  height: 46px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 0.5px solid ${theme.colors.separator};

  > p {
    text-align: center;
  }
`

export const GasPrices = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`

export const GasPricesItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;

  > h3 {
    span {
      font-size: 14px;
    }
  }
`

export const GasPieContainer = styled.div`
  width: 100%;
  border-top: 0.5px solid ${theme.colors.separator};
  padding-top: 20px;

  > p {
    width: 100%;
    height: 36px;
    text-align: center;

    > strong {
      font-family: 'FS Emeric Bold';
      font-weight: normal;
    }
  }
`
