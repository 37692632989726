import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { theme } from '~/styles/theme'

/**
    Uso:

    import { EcoIcon } from '~/assets/icons'

    <Icon
      Source={EcoIcon} // Source del icono en src/assets/icons/index.js
      width='22px' // width
      height='22px' // height
      color='#f08226' // color (blanco por defecto)
    />
*/

const Icon = ({ Source, width, height, color, onClick, title, className }) => (
  <S.IconContainer
    className={`icon-container ${className}`}
    color={color}
    width={width}
    height={height}
    onClick={onClick}
    title={title}
  >
    <Source title={title} className='icon' width={width} height={height} />
  </S.IconContainer>
)

Icon.defaultProps = {
  Source: {},
  width: '22px',
  height: '22px',
  color: theme.colors.white,
  onClick: null,
  title: '',
  className: '',
}

Icon.propTypes = {
  Source: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
}

export default Icon
