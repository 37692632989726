import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import GlobalStyle from './styles/global'
import { theme } from '~/styles/theme'
import { store, persistor } from './store'
import Routes from './routes'
import '~/config/i18n'

require('dotenv').config()

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE ? Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE) : 1,
    environment: process.env.REACT_APP_ENVIROMENT || 'local',
    transactionContext: { name: 'frontend' },
  })
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate persistor={persistor} loading={null}>
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  )
}

export default App
