import { priceFormat } from '~/config/utils'
import { theme } from '~/styles/theme'

export const getCustomizedLabelValues = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
  const RADIAN = Math.PI / 180

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const mx = cx + outerRadius * cos
  const my = cy + outerRadius * sin
  let ex = mx + (cos >= 0 ? 1 : -1)
  ex += cos >= 0 ? 12 : 0
  ex += (cos >= 0 ? 1 : -1) * 14

  let ey = my
  let textAnchor = cos >= 0 ? 'start' : 'end'

  if (index === 0) {
    ey = 266
    textAnchor = 'middle'
    ex = x - 20
  }
  return {
    x,
    y,
    ex,
    ey,
    textAnchor,
  }
}

export const getData = ({ type, detail, detailChart, t, eco }) => {
  const { vat, vats } = detail
  const {
    totalVats,
    tollAndCharges,
    meterRental,
    otherRegulatedConcepts,
    regulatedConcepts,
    energy,
    electricTax,
    hydrocarbonsTax,
    amountElecTaxMin,
  } = detailChart || {}

  const costesReguladosLegend = []

  if (tollAndCharges) {
    costesReguladosLegend.push({
      fontStyle: 'light',
      value: priceFormat(tollAndCharges),
      concept: t('Peajes y cargos'),
    })
  }

  if (meterRental) {
    costesReguladosLegend.push({
      fontStyle: 'light',
      value: priceFormat(meterRental),
      concept: t('Alquiler'),
    })
  }

  if (otherRegulatedConcepts) {
    costesReguladosLegend.push({
      fontStyle: 'light',
      value: priceFormat(otherRegulatedConcepts),
      concept: t('Otros conceptos regulados'),
    })
  }

  costesReguladosLegend.push({
    fontStyle: 'semibold',
    value: priceFormat(regulatedConcepts),
    concept: t('Total Conceptos regulados'),
  })

  const costesRegulados = {
    name: t('Conceptos Regulados de tu energía'),
    value: regulatedConcepts,
    color: theme.colors.blue.light,
    legend: costesReguladosLegend,
  }

  const energia = {
    name: t('Tu energía'),
    value: energy,
    color: eco ? theme.colors.green.chart : theme.colors.blue.regular,
  }

  const impuestosLegend = [
    {
      fontStyle: 'light',
      value: priceFormat(vats),
      concept: t(vat) || 'IVA',
    },
    {
      fontStyle: 'light',
      value: type === 'light' ? priceFormat(electricTax) : priceFormat(hydrocarbonsTax),
      concept: type === 'light' ? t('Impuesto Eléctrico') : t('Impuesto Hidrocarburos'),
    },
    {
      fontStyle: 'light',
      value: type === 'light' ? priceFormat(amountElecTaxMin) : 0,
      concept: t('Impuesto Eléctrico'),
    },

    {
      fontStyle: 'semibold',
      value: priceFormat(totalVats),
      concept: t('Total impuestos'),
    },
  ]

  const impuestos = {
    name: t('Impuestos'),
    value: totalVats,
    color: theme.colors.orange.regular,
    legend: impuestosLegend,
  }

  return { costesRegulados, energia, impuestos }
}
