import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const PromotionsContainer = styled.div`
  background-color: transparent;
  width: ${({ count }) => (count < 2 ? '500px' : '340px')};
  padding: 0 25px;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    padding: 0 20px;
  }
`
export const PromotionsTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  > .icon-container {
    margin-bottom: 15px;
  }
`

export const PromotionsSubtitle = styled.div`
  margin: auto;
  margin-bottom: 20px;

  > p {
    text-align: center;
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    > p {
      font-size: 16px;
      line-hegith: 20px;
    }
  }
`

export const List = styled.div``

export const PromotionsTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;

  > h3 {
    > span {
      font-size: 14px;
    }
  }
`
