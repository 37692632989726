import { useState, useEffect } from 'react'
import { theme } from '~/styles/theme'

const getBreakPoint = windowWidth => {
  const { breakpoints } = theme
  const sizes = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  }

  if (windowWidth) {
    if (windowWidth < breakpoints.tablet) {
      sizes.isMobile = true
    }

    if (windowWidth >= breakpoints.tablet && windowWidth <= breakpoints.large) {
      sizes.isTablet = true
    }

    if (windowWidth > breakpoints.large) {
      sizes.isDesktop = true
    }
  } else {
    return sizes
  }

  return sizes
}

const useMediaQuery = () => {
  const isWindowClient = typeof window === 'object'

  const [windowSize, setWindowSize] = useState(isWindowClient ? getBreakPoint(window.innerWidth) : {})

  useEffect(() => {
    function setSize() {
      setWindowSize(getBreakPoint(window.innerWidth))
    }

    if (isWindowClient) {
      window.addEventListener('resize', setSize)

      return () => window.removeEventListener('resize', setSize)
    }

    return null
  }, [isWindowClient, setWindowSize])

  return windowSize
}

export default useMediaQuery
