import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const ScrollInteractionContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  .btn {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 2px solid ${theme.colors.orange.regular};

    p {
      padding-top: 2px;
    }

    .icon-container {
      width: 20px;
      height: 17px;

      .icon {
        width: 20px;
        height: 17px;
        transform: rotate(90deg);
        fill: ${theme.colors.orange.regular};
      }
    }
  }
`
