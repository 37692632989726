import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const SupplyResumeContainer = styled.div`
  width: 400px;
  background-color: transparent;
  height: auto;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`
export const SupplyResumeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background-color: ${theme.colors.gray.light};
  border-bottom-left-radius: ${theme.borderRadius};
  border-bottom-right-radius: ${theme.borderRadius};

  > p {
    margin-bottom: 20px;
    text-align: left;

    > span {
      color: ${theme.colors.orange.regular};
    }
  }

  > div {
    border-bottom: 2px solid ${theme.colors.white};

    &:last-child {
      border: none;
    }
  }

  .icon-container {
    .icon {
      transform: rotate(180deg);
    }
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    padding: 20px 30px;
  }
`
export const TitleSupply = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 600px;
  font-size: 24px;
  line-height: 28px;
  font-family: 'FS Emeric SemiBold';
  color: ${({ isEco }) => (isEco ? theme.colors.eco.regular : theme.colors.orange.regular)};
  background-color: ${theme.colors.gray.light};
  padding-top: 18px;
  border-top-left-radius: ${theme.borderRadius};
  border-top-right-radius: ${theme.borderRadius};
`

export const SupplyResumeItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
  margin: auto;
  padding: 16px 0;

  > div {
    &:first-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      ${({ cursorType }) => cursorType && 'cursor:pointer;'}
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
  }
`
export const ConceptItem = styled.div`
  display: flex;
  width: calc(100% - 170px);
`

export const WithLink = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > .icon-container {
    > .icon {
      ${({ isTax, taxesIsOpen }) => {
        if (isTax) {
          if (taxesIsOpen) {
            return 'transform: rotate(-90deg);'
          }
          return 'transform: rotate(90deg);'
        }
        return false
      }}
    }
  }
`
export const PriceItem = styled.div`
  display: flex;
  align-items: center;
  width: 170px;
  justify-content: flex-end;

  > h3 {
    span {
      font-size: 14px;
    }
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    padding-right: 0;
  }
`

export const SupplyResumeButton = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const TaxesBlock = styled.div`
  overflow: hidden;
  max-height: 0px;
  padding: 10px 0;
  padding: 0px;
  opacity: 0;

  &.active {
    max-height: 150px;
    padding: 10px 20px;
    opacity: 1;
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    max-width: 100%;
    margin: auto;
  }
`
export const TaxesBlockItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  > h2 {
    > span {
      font-size: 14px;
    }
  }
`
