import strapi from '../strapi'

export const getNotificationsBySlug = slugList => {
  let stringIds = ''
  slugList.map((id, index) => {
    stringIds = index === 0 ? `slug=${id}` : `${stringIds}&slug=${id}`
    return false
  })
  return strapi.get(`/notifications?${stringIds}`)
}
