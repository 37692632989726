import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import * as S from './styled'
import { TabPanel } from '~/components/molecules'
import { H2 } from '~/components/atoms'
import { theme } from '~/styles/theme'
import { GasIcon, EleIcon } from '~/assets/icons'
import { PRODUCT_TYPE_NEWCO } from '~/config/constants'
import HistoricSupplyStats from '../../molecules/HistoricSupplyStats'

const HistoricalNewco = () => {
  const { t } = useTranslation()
  const { invoice } = useSelector(state => state.auth || {})
  const gasHistoric = invoice?.barChart?.gas || null
  const lightHistoric = invoice?.barChart?.electricity || null
  const lightType = invoice?.electricity?.type.toLowerCase() || ''
  const gasType = invoice?.gas?.type.toLowerCase() || ''
  const [tabList, setTabList] = useState([])

  useEffect(() => {
    const list = []
    if (
      !isEmpty(lightHistoric?.values) &&
      !(lightType === PRODUCT_TYPE_NEWCO.PLANA || lightType === PRODUCT_TYPE_NEWCO.PLANA_RD)
    ) {
      list.push({
        index: 1,
        key: t('Electricidad'),
        color: theme.colors.blue.regular,
        colorSecondary: theme.colors.blue.regular,
        activeColor: invoice?.gas?.eco ? theme.colors.green.chart : theme.colors.blue.dark,
        icon: EleIcon,
        component: (
          <HistoricSupplyStats
            eco={lightHistoric.eco}
            dataType='light'
            colorPrimary={theme.colors.blue.dark}
            colorSecondary={theme.colors.blue.light}
            historic={lightHistoric.values}
          />
        ),
        eco: invoice?.electricity?.eco,
      })
    }

    if (
      !isEmpty(gasHistoric?.values) &&
      !(gasType === PRODUCT_TYPE_NEWCO.PLANA || gasType === PRODUCT_TYPE_NEWCO.PLANA_RD)
    ) {
      list.push({
        index: 0,
        key: t('Gas'),
        color: theme.colors.orange.dark,
        colorSecondary: theme.colors.orange.light,
        activeColor: invoice?.gas?.eco ? theme.colors.green.chart : theme.colors.orange.regular,
        icon: GasIcon,
        component: (
          <HistoricSupplyStats
            eco={gasHistoric?.eco}
            dataType='gas'
            colorPrimary={theme.colors.orange.regular}
            colorSecondary={theme.colors.orange.light}
            historic={gasHistoric.values}
          />
        ),
        eco: invoice?.gas?.eco,
      })
    }

    setTabList(list)
  }, [invoice, gasHistoric, lightHistoric, gasType, lightType, t])

  if (tabList.length < 1) return null
  if (isEmpty(gasHistoric) && isEmpty(lightHistoric)) return null

  return (
    <S.HistoricalContainer data-cy='historico-facturacion' className='layout-wrapper'>
      <S.HistoricalHeader>
        <H2>{t('Conoce tu evolución de consumo')}</H2>
      </S.HistoricalHeader>
      <TabPanel list={tabList} />
    </S.HistoricalContainer>
  )
}

export default HistoricalNewco
