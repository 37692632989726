import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as S from './styled'

/**
    Uso:

    <LinkRegular
        path='/transits' // path interno donde quieres redirigir
        linkColor='black' // color del texto si lo lleva (por defecto blanco)
        extraData={extraData} // objeto para meterlo en el state del location por si hace falta informacion extra en la navegacion
        externalPath='https://areaprivada.naturgy.es'
    >
      Puedes meter lo que quieras
    </LinkRegular>
*/

const LinkRegular = ({ path, children, linkColor, extraData, externalPath }) => {
  if (externalPath) {
    const target = externalPath.includes('tel:') ? '_self' : '_blank'
    return (
      <a href={externalPath} target={target} rel='noopener noreferrer'>
        {children}
      </a>
    )
  }

  return (
    <S.LinkRegularContainer linkColor={linkColor}>
      <Link className='link-regular' to={extraData ? { pathname: path, state: { ...extraData } } : path}>
        {children}
      </Link>
    </S.LinkRegularContainer>
  )
}

LinkRegular.defaultProps = {
  path: '/home',
  children: null,
  linkColor: 'black',
  extraData: null,
  externalPath: null,
}

LinkRegular.propTypes = {
  path: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  linkColor: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  extraData: PropTypes.any,
  externalPath: PropTypes.string,
}

export default LinkRegular
