import qs from 'qs'

import api from '../api'

export const getInvoiceData = ({ token, qc, factnum, tokenkey }) => {
  const params = qs.stringify({ qc, factnum, tokenkey }, { skipNulls: true })
  return api.get(`/invoice/${token}?${params}`)
}

export const getNewcoInvoiceData = ({ newcoQs }) => api.get(`/invoice/new-co/${newcoQs}`)
