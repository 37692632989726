import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const MenuContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${theme.colors.blue.regular};
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (!isMobile ? 'space-between' : 'center')};
  padding: 0px 35px;
  @media (max-width: ${theme.breakpoints.large}px) {
    height: 64px;
  }
`

export const LogoContainer = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;

  img {
    height: 22px;
    width: auto;
  }
`
