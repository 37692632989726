import React from 'react'
import { eu, es, ca, gl } from 'date-fns/locale'
import { format, isBefore } from 'date-fns'
import i18n from './i18n'
import { SIEBEL_SLUGS, SLUG_WITH_DIF_DESC, LOCK_PIE_DATE, PRODUCT_TYPE } from '~/config/constants'
import { getNotificationType } from '~/config/notificationTypes'
import { ELECTRICITY, GAS, SERVICES } from './constants'

export const getActiveLanguage = () => i18n.language

export const getLocaleByLanguage = lang => {
  switch (lang) {
    case 'es':
      return es
    case 'eu':
      return eu
    case 'ct':
      return ca
    case 'gl':
      return gl
    default:
      return es
  }
}

export const getDateByLocale = (date, lang, _format = 'EEE, dd MMM (HH:mm)', transform = '') => {
  if (!date) return ''

  function convertDateToSpanishTimeZone(originalDate) {
    const localDate = new Date(originalDate)
    if (Number.isNaN(localDate.getTime())) return ''
    const dateUTC = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000)
    const dateInSpain = new Date(dateUTC.getTime() + 1 * 3600000)
    return Number.isNaN(dateInSpain.getTime()) ? '' : dateInSpain.toISOString()
  }

  const dateFormatedGTM = convertDateToSpanishTimeZone(date)
  if (!dateFormatedGTM) return ''

  const locale = getLocaleByLanguage(lang)
  const dateFormated = format(new Date(dateFormatedGTM), _format, { locale })

  switch (transform) {
    case 'capitalize':
      return dateFormated[0].toUpperCase() + dateFormated.slice(1)
    case 'uppercase':
      return dateFormated.toUpperCase()
    default:
      return dateFormated
  }
}

export const getProductNameBySlug = (detalle, t, lng, Trans) => {
  const slug = detalle.type || null
  const productSlug = detalle.siebel || null
  const productName = detalle.name
  const cuota = detalle.quota || null

  if (SLUG_WITH_DIF_DESC.includes(productSlug)) {
    return (
      <Trans values={{ cuota, productName }}>
        Cuota personalizada nº {{ cuota }} {{ productName }}
      </Trans>
    )
  }

  switch (slug) {
    case SIEBEL_SLUGS.REG_PCF:
      return t('Regularización Plan de Pagos Fijos')
    case SIEBEL_SLUGS.CUOTA_PCF:
      return <Trans values={{ cuota }}>Cuota nº {{ cuota }} Plan de Pagos Fijos</Trans>
    default:
      return productName
  }
}

export const getProductDescriptionBySlug = (detalle, Trans) => {
  const slug = detalle.type || null
  const productSlug = detalle.siebel || null
  let productTitle1
  let productTitle2

  if (SLUG_WITH_DIF_DESC.includes(productSlug)) {
    return detalle.description || null
  }

  switch (slug) {
    case SIEBEL_SLUGS.REG_PCF:
      productTitle1 = detalle.name

      return (
        <Trans values={{ productTitle1 }}>
          Al tener plan de pagos fijos has pagado una cuota mensual fija durante 11 meses y en este mes se regulariza:
          se calcula la diferencia entre lo que hubieses pagado en el año con la {{ productTitle1 }} y lo que has pagado
          en las 11 cuotas mensuales
        </Trans>
      )
    case SIEBEL_SLUGS.CUOTA_PCF:
      productTitle2 = detalle.name

      return (
        <Trans values={{ productTitle2 }}>
          Tienes contratada una tarifa {{ productTitle2 }}. Al tener plan de pagos fijos, pagas una cuota mensual fija y
          se regularizará en la cuota 12
        </Trans>
      )
    default:
      return detalle?.description
  }
}

export const getWelcomeTextBySlug = (notification, address, productTypes = '', Trans = null, lng, t = () => {}) => {
  const slug = notification?.slug
  const matched = productTypes.includes(t('electricidad')) || false
  switch (slug) {
    case 'motivos-refacturacion':
    case 'motivos-ajuste':
    case 'motivos-anulacion':
    case 'motivos-anulacion-2':
      return getNotificationType(notification, null, lng, t).title
    default:
      if (matched) {
        return (
          <Trans values={{ productTypes, address }}>
            Aquí tienes el resumen de tu factura de <b>{{ productTypes }}</b> de <b>{{ address }} </b>
          </Trans>
        )
      }

      return (
        <Trans values={{ productTypes, address }}>
          Aquí tienes el resumen de tu factura de <b>{{ productTypes }}</b> de <b>{{ address }}</b>
        </Trans>
      )
  }
}

export const getTotalAdjustments = adjustments => {
  let gas = 0
  let ele = 0
  if (!adjustments) return []

  adjustments.map(item => {
    const slug = item.slug.substr(0, 2)
    const price = parseFloat(item.params.importe)

    if (slug === 'ZE') {
      ele += price
    } else if (slug === 'ZG') {
      gas += price
    }
    return false
  })

  return {
    gas,
    ele,
  }
}

export const getTotalTaxes = taxes => {
  if (!taxes || taxes?.length === 0) return null

  let imp = 0
  taxes.map(tax => {
    imp += tax.params.importe
    return false
  })

  return parseFloat(imp)
}

export const getPriceByType = (type, detalle, header) => {
  const slug = detalle?.siebel_slug || ''

  switch (slug) {
    case SIEBEL_SLUGS.REG_PCF:
      return parseFloat(header[`tregularizacion_${type}`] || 0)
    case SIEBEL_SLUGS.CUOTA_PCF:
      return parseFloat(header[`importe_cuota_fija_${type}`] || 0)
    default:
      return parseFloat(detalle?.total_subt || 0)
  }
}

export const checkNotificationByProduct = (notification, invoice) => {
  const { slug } = notification
  const { electricity, gas } = invoice
  const productList = ['21052', '21124', '21132', '41100', '41238', '41312']

  switch (slug) {
    case 'superacion-limite-tarifa-plana-gas':
    case 'limite-tarifa-plana-cercano-gas':
      return productList.some(id => id === gas?.id)
    case 'superacion-limite-tarifa-plana-electricidad':
    case 'limite-tarifa-plana-cercano-electricidad':
      return productList.some(id => id === electricity?.id)
    default:
      return false
  }
}

export const getTaxesByType = (taxes, type) => {
  if (!taxes || taxes?.length === 0) return null

  const imp = taxes.filter(({ slug }) => slug === type).reduce((prev, { importe }) => prev + importe, 0)

  return parseFloat(imp)
}

export const getTextByType = (type, t) => {
  switch (type) {
    case PRODUCT_TYPE.GAS:
      return t('gas')
    case PRODUCT_TYPE.ELE:
      return t('electricidad')
    default:
      return t('electricidad y gas')
  }
}

export const showSummaryPieChart = ({ notifications, client, product_price_no_taxes, pieType }) => {
  if (client && client.is_employee) {
    return false
  }

  if (product_price_no_taxes && product_price_no_taxes < 0) {
    return false
  }

  if (client.budat && pieType === 'percentage') {
    let date = client.budat

    if (notifications.some(({ slug }) => slug === 'motivos-refacturacion')) {
      date = notifications.filter(({ slug }) => slug === 'motivos-refacturacion')[0].params.fecha_facturacion
    }

    if (isBefore(new Date(date), LOCK_PIE_DATE)) {
      return false
    }
  }

  if (notifications) {
    const hasFlag = notifications.some(
      ({ slug }) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        slug === 'motivos-ajuste' ||
        slug === 'fraude-averia' ||
        slug === 'motivos-anulacion' ||
        slug === 'motivos-anulacion-2'
    )
    return !hasFlag
  }

  return true
}

export const priceFormat = (value, minimumFractionDigits = 2, maximumFractionDigits = 2, showZero = false) => {
  if (!value && !showZero) return null
  if (value === null || value === undefined) return null

  const price = value.toLocaleString('de-DE', {
    minimumFractionDigits,
    maximumFractionDigits,
  })
  const integer = price.toString().slice(0, price.toString().indexOf(','))
  const rest = price.toString().slice(price.toString().indexOf(',') + 1, price.length)

  return (
    <>
      {integer}
      <span>,{rest}€</span>
    </>
  )
}

export const getConsumptionFormated = value => {
  if (!value) return '0'
  const consumption = value.replace(/\./g, '').replace(',', '.')

  return parseFloat(consumption).toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}

export const postIframeHeightEvent = isFetching => {
  if (isFetching) {
    window.parent.postMessage(
      {
        id: 'facint_iframe',
        height: 650,
        width: document?.getElementById('default-layout-container').scrollWidth,
      },
      '*'
    )
  } else {
    window.parent.postMessage(
      {
        id: 'facint_iframe',
        height: document?.getElementById('default-layout-last-item').offsetTop + 30,
        width: document?.getElementById('default-layout-container').scrollWidth,
      },
      '*'
    )
  }
}

export const getFontFamilyByType = type => {
  switch (type) {
    case 'semibold':
      return "font-family:'FS Emeric SemiBold';"
    case 'thin':
      return "font-family:'FS Emeric Thin';"
    case 'light':
      return "font-family:'FS Emeric Light';"
    case 'regular':
      return "font-family:'FS Emeric Regular';"
    case 'core':
      return "font-family:'FS Emeric Core';"
    case 'bold':
      return "font-family:'FS Emeric Bold';"
    default:
      return "font-family:'FS Emeric Light';"
  }
}

export const obfuscateString = (text, notObfuscateCharsList) =>
  text
    .split('')
    .map((char, index) => (notObfuscateCharsList.includes(index) || char === ' ' ? char : '*'))
    .join('')

export const getTypeProduct = invoice => {
  const { electricity, gas, services } = invoice || {}

  return {
    ...(electricity && { electricity: ELECTRICITY }),
    ...(gas && { gas: GAS }),
    ...(services && services.data !== undefined && services.data.length > 0 && { services: SERVICES }),
  }
}
