/* eslint-disable react/destructuring-assignment */
import { getTaxesByType } from '~/config/utils'

export const ResumeData = (summary, t, isRdi) => {
  const total_subt_electricidad = {
    key: 'total_subt_electricidad',
    value: summary?.electricity?.subtotal || 0,
    text: t('Electricidad'),
    link: 'light-summary-container',
  }
  const total_subt_gas = {
    key: 'total_subt_gas',
    value: summary?.gas?.subtotal || 0,
    text: t('Gas'),
    link: 'gas-summary-container',
  }
  const total_services = {
    key: 'total_services',
    value: summary?.services,
    text: t('Servicios'),
    link: 'service-summary-container',
  }
  const taxes_IPSI = {
    key: 'taxes',
    slug: 'IPSI',
    value: getTaxesByType(
      [
        {
          slug: summary?.gas?.vat,
          importe: summary?.gas?.vats,
        },
        {
          slug: summary?.electricity?.vat,
          importe: summary?.electricity?.vats,
        },
      ],
      'IPSI'
    ),
    text: t('IPSI'),
    link: null,
  }
  const taxes_IGIC = {
    key: 'taxes',
    slug: 'IGIC',
    value: getTaxesByType(
      [
        {
          slug: summary?.gas?.vat,
          importe: summary?.gas?.vats,
        },
        {
          slug: summary?.electricity?.vat,
          importe: summary?.electricity?.vats,
        },
      ],
      'IGIC'
    ),
    text: t('IGIC'),
    link: null,
  }
  const taxes_IVA = {
    key: 'taxes',
    slug: 'IVA',
    value: getTaxesByType(
      [
        {
          slug: summary?.gas?.vat,
          importe: summary?.gas?.vats,
        },
        {
          slug: summary?.electricity?.vat,
          importe: summary?.electricity?.vats,
        },
        {
          slug: summary?.psva?.vat,
          importe: summary?.psva?.vats,
        },
      ],
      'IVA'
    ),
    text: t('IVA'),
    link: null,
  }
  const total_financings = {
    key: 'total_financings',
    value: summary?.financings,
    text: t('Financiaciones'),
    link: 'financing-summary-container',
  }
  const total_promotions = {
    key: 'total_promotions',
    value: summary?.promotions,
    text: t('Promociones'),
    link: 'promotion-summary-container',
  }

  return isRdi
    ? [
        total_subt_electricidad,
        total_subt_gas,
        taxes_IPSI,
        taxes_IGIC,
        taxes_IVA,
        total_services,
        total_financings,
        total_promotions,
      ]
    : [
        total_subt_electricidad,
        total_subt_gas,
        total_services,
        taxes_IPSI,
        taxes_IGIC,
        taxes_IVA,
        total_financings,
        total_promotions,
      ]
}
