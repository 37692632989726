import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import qs from 'qs'
import * as S from './styled'
import { Contact, Footer } from '~/components/organisms'
import { setIframeMode } from '~/store/modules/auth/actions'

const ErrorPage = ({ location }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  document.title = t('Resumen factura')

  const iframe = location.search.includes('iframe=')
    ? qs.parse(location.search.substring(1, location.search.length)).iframe
    : null

  if (iframe) {
    dispatch(setIframeMode(iframe))
  }

  return (
    <S.ErrorPageContainer>
      <S.ErrorContainer className='layout-wrapper'>
        <S.ErrorPageComponent type={location?.state?.type} isRdi={location?.state?.isRdi} />
      </S.ErrorContainer>
      {!iframe && (
        <>
          <Contact showContactLink={location?.state?.isRdi} isRdi={location?.state?.isRdi} />
          <Footer isRdi={location?.state?.isRdi} />
        </>
      )}
    </S.ErrorPageContainer>
  )
}

ErrorPage.defaultProps = { location: {} }

ErrorPage.propTypes = { location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]) }
export default ErrorPage
