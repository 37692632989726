import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { H4, Text } from '~/components/atoms'

import * as S from './styled'

const ConsumptionCard = ({
  closeToLimit,
  unit,
  productName,
  cardColor,
  valueFormated,
  limitFormated,
  actualFormated,
  percentFormated,
  dayBudat,
  monthBudat,
  yearBudat,
  dayFact,
  monthFact,
  yearFact,
  matched,
  tddAndBudat,
  formatedContract,
  type,
  limit,
  exceededLimit,
  percent,
  formatedContractStart,
  formatedContractEnd,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <S.ConsumptionContainer {...props}>
      <S.Header>
        <H4>{t('Durante este período facturado has consumido')}:</H4>
        <H4 fontType='semibold'>{`${actualFormated} ${unit}`}</H4>
      </S.Header>
      {limit && (
        <S.Content>
          <Text>
            {matched ? (
              <Trans values={{ type }}>Este es tu consumo acumulado de {{ type }}: </Trans>
            ) : (
              <Trans values={{ type }}>Este es tu consumo acumulado de {{ type }}:</Trans>
            )}
          </Text>
          <S.CardPercentBar label={`${percentFormated}%`} value={percent} color={cardColor} />
          <S.InfoText fontColor={cardColor} fontType='semibold'>
            {(exceededLimit || closeToLimit) && (
              <>
                {exceededLimit ? (
                  <>{t('Ya has superado el límite de tu consumo anual')}</>
                ) : (
                  <>
                    {matched ? (
                      <Trans values={{ type }}>Estas a punto de superar el límite de tu consumo de {{ type }} </Trans>
                    ) : (
                      <Trans values={{ type }}>Estas a punto de superar el límite de tu consumo de {{ type }}</Trans>
                    )}
                  </>
                )}
              </>
            )}
          </S.InfoText>
          <S.BarLeyend fontSize='16px'>
            {tddAndBudat && (
              <>
                {matched ? (
                  <Trans
                    values={{
                      valueFormated,
                      unit,
                      type,
                      dayFact,
                      monthFact,
                      yearFact,
                      dayBudat,
                      monthBudat,
                      yearBudat,
                      productName,
                      limitFormated,
                    }}
                  >
                    A {{ dayBudat }} {{ monthBudat }} de {{ yearBudat }} lleva consumidos {{ valueFormated }} {{ unit }}{' '}
                    de {{ type }} desde el {{ dayFact }} {{ monthFact }} de {{ yearFact }}. Le recordamos que el consumo
                    máximo anual pactado de su {{ productName }} es de {{ limitFormated }} {{ unit }}
                  </Trans>
                ) : (
                  <Trans
                    values={{
                      valueFormated,
                      unit,
                      type,
                      dayFact,
                      monthFact,
                      yearFact,
                      dayBudat,
                      monthBudat,
                      yearBudat,
                      productName,
                      limitFormated,
                    }}
                  >
                    A {{ dayBudat }} {{ monthBudat }} de {{ yearBudat }} lleva consumidos {{ valueFormated }} {{ unit }}{' '}
                    de {{ type }} desde el {{ dayFact }} {{ monthFact }} de {{ yearFact }}. Le recordamos que el consumo
                    máximo anual pactado de su {{ productName }} es de {{ limitFormated }} {{ unit }}.
                  </Trans>
                )}
              </>
            )}

            {!tddAndBudat && formatedContract && (
              <>
                {matched ? (
                  <Trans
                    values={{
                      valueFormated,
                      unit,
                      type,
                      limitFormated,
                      formatedContractStart,
                      formatedContractEnd,
                    }}
                  >
                    Has consumido un total de {{ valueFormated }} {{ unit }} de los {{ limitFormated }} {{ unit }} de{' '}
                    {{ type }} que tienes disponibles del {{ formatedContractStart }} al {{ formatedContractEnd }}
                  </Trans>
                ) : (
                  <Trans
                    values={{
                      valueFormated,
                      unit,
                      type,
                      limitFormated,
                      formatedContractStart,
                      formatedContractEnd,
                    }}
                  >
                    Has consumido un total de {{ valueFormated }} {{ unit }} de los {{ limitFormated }} {{ unit }} de{' '}
                    {{ type }} que tienes disponibles del {{ formatedContractStart }} al {{ formatedContractEnd }}.
                  </Trans>
                )}
              </>
            )}

            {!tddAndBudat && !formatedContract && (
              <>
                {matched ? (
                  <Trans
                    values={{
                      valueFormated,
                      unit,
                      type,
                      limitFormated,
                    }}
                  >
                    Has consumido un total de {{ valueFormated }} {{ unit }} de los {{ limitFormated }} {{ unit }} de{' '}
                    {{ type }} que tienes disponibles.
                  </Trans>
                ) : (
                  <Trans
                    values={{
                      valueFormated,
                      unit,
                      type,
                      limitFormated,
                    }}
                  >
                    Has consumido un total de {{ valueFormated }} {{ unit }} de los {{ limitFormated }} {{ unit }} de{' '}
                    {{ type }} que tienes disponibles
                  </Trans>
                )}
              </>
            )}
          </S.BarLeyend>
        </S.Content>
      )}
    </S.ConsumptionContainer>
  )
}

ConsumptionCard.defaultProps = {
  closeToLimit: null,
  unit: null,
  productName: null,
  cardColor: null,
  valueFormated: null,
  limitFormated: null,
  actualFormated: null,
  percentFormated: null,
  dayBudat: null,
  monthBudat: null,
  yearBudat: null,
  dayFact: null,
  monthFact: null,
  yearFact: null,
  matched: false,
  tddAndBudat: null,
  formatedContract: null,
  type: null,
  limit: null,
  exceededLimit: false,
  percent: null,
  formatedContractStart: null,
  formatedContractEnd: null,
}

ConsumptionCard.propTypes = {
  closeToLimit: PropTypes.bool,
  unit: PropTypes.string,
  productName: PropTypes.string,
  cardColor: PropTypes.string,
  valueFormated: PropTypes.string,
  limitFormated: PropTypes.string,
  actualFormated: PropTypes.string,
  percentFormated: PropTypes.string,
  dayBudat: PropTypes.string,
  monthBudat: PropTypes.string,
  yearBudat: PropTypes.string,
  dayFact: PropTypes.string,
  monthFact: PropTypes.string,
  yearFact: PropTypes.string,
  matched: PropTypes.bool,
  tddAndBudat: PropTypes.string,
  formatedContract: PropTypes.string,
  type: PropTypes.string,
  limit: PropTypes.number,
  exceededLimit: PropTypes.bool,
  percent: PropTypes.number,
  formatedContractStart: PropTypes.string,
  formatedContractEnd: PropTypes.string,
}

export default ConsumptionCard
