import styled from 'styled-components/macro'

export const BlockCollapseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
`

export const BlockCollapseWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: 0px;
  padding: 0px;
  opacity: 0;
  position: relative;
  transition: ${({ isOpen }) => {
    if (isOpen) {
      return 'max-height 0.25s ease-in-out, padding 0.25s ease-in-out, opacity 0.45s'
    }
    return 'max-height 0.25s ease-in-out, padding 0.25s ease-in-out, opacity 0.1s'
  }};

  > p {
    text-align: center;
  }

  &.active {
    max-height: 700px;
    opacity: 1;
    padding: 0 0 20px;
  }
`

export const ButtonCollapse = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  > .icon-container {
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > .icon {
      width: 20px !important;
      height: 20px !important;
      transition: 0.25s ease-in-out;
      transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
    }
  }
`
