import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setActiveTabIndex } from '../../../store/modules/tabs/actions'

import * as S from './styled'
import { theme } from '~/styles/theme'
import LightSummary from '../../molecules/LightSummary'
import GasSummary from '../../molecules/GasSummary'
import ServiceSummary from '../../molecules/ServiceSummary'
import { getTypeProduct } from '../../../config/utils'
import { getProductSummary } from '../Welcome/utils'
import { ELECTRICITY, GAS, SERVICES } from '../../../config/constants'
import { findMatchingTabs, getBorderColor, getColor } from './utils'
import Icon from '../../atoms/Icon'

import { GasIcon, SerIcon, EleIcon, EcoIcon } from '~/assets/icons'

const Summaries = () => {
  const { t } = useTranslation()
  const { invoice } = useSelector(state => state.auth || {})
  const { gas, electricity, services } = invoice || {}
  const productType = getTypeProduct(invoice)
  const activeTab = useSelector(state => state.tabs.activeTabIndex) // Usar useSelector para obtener la pestaña activa
  const dispatch = useDispatch()
  const summaries = getProductSummary(productType, electricity, gas, services)

  const tabs = [
    {
      id: ELECTRICITY,
      title: t('Electricidad'),
      action: 0,
      icon: EleIcon,
      eco: electricity?.eco,
      color: theme.colors.blue.regular,
      colorSecondary: theme.colors.blue.inactive,
    },
    {
      id: GAS,
      title: t('Gas'),
      action: 1,
      icon: GasIcon,
      eco: gas?.eco,
      color: theme.colors.orange.regular,
      colorSecondary: theme.colors.orange.inactive,
    },
    {
      id: SERVICES,
      title: t('Servicios'),
      action: 2,
      icon: SerIcon,
      eco: false,
      color: theme.colors.orange.regular,
      colorSecondary: theme.colors.orange.inactive,
    },
  ]

  const currentTab = findMatchingTabs(summaries, tabs)

  useEffect(() => {
    let defaultTab
    if (summaries.includes(ELECTRICITY)) {
      defaultTab = 0
    } else if (summaries.includes(GAS)) {
      defaultTab = 1
    } else {
      defaultTab = 2
    }
    dispatch(setActiveTabIndex(defaultTab))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.SummariesContainer className='layout-wrapper' count={summaries.length} data-cy='summaries'>
      <S.ButtonContainer>
        {currentTab.map(product => (
          <S.ButtonTab
            key={product.id}
            type='button'
            onClick={() => dispatch(setActiveTabIndex(product.action))}
            isActive={activeTab === product.action}
            color={getBorderColor(product.id, activeTab === product.action, product.eco)}
          >
            <S.ButtonIconContainer>
              <S.ButtonIcon color={getColor(product.id, activeTab === product.action, product.eco)}>
                <Icon
                  Source={product.icon}
                  width='28px'
                  height='28px'
                  isActive={activeTab === product.action}
                  color={getColor(product.id, activeTab === product.action, product.eco)}
                />
                {product.title}
              </S.ButtonIcon>
              {product.eco && (
                <S.EcoItem isActive={activeTab === product.action}>
                  <Icon className='eco-icon' Source={EcoIcon} width='20px' height='20px' color='transparent' />
                </S.EcoItem>
              )}
            </S.ButtonIconContainer>
          </S.ButtonTab>
        ))}
      </S.ButtonContainer>
      <div>
        {activeTab === 0 && <LightSummary data={tabs[0]} />}
        {activeTab === 1 && <GasSummary data={tabs[1]} />}
        {activeTab === 2 && <ServiceSummary data={tabs[2]} />}
      </div>
    </S.SummariesContainer>
  )
}

export default Summaries
