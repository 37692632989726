import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'

import { Text, BlockCollapse, ProductEco, H3 } from '~/components/atoms'
import { theme } from '~/styles/theme'
import {
  getActiveLanguage,
  getDateByLocale,
  getProductNameBySlug,
  getProductDescriptionBySlug,
  priceFormat,
} from '~/config/utils'

import SummaryPieChart from '../SummaryPieChart'
import * as S from './styled'

const LightSummary = ({ count, data }) => {
  const { t } = useTranslation()
  const lng = getActiveLanguage()
  const { invoice } = useSelector(state => state.auth || {})
  const { electricity } = invoice || {}
  const { chart, eco, employeeLim, employeeAcu, isEmployee, days, from, to, priceNoTaxes, vats, total, vat } =
    electricity || {}

  const [productName, setProductName] = useState('')
  const [productDescription, setProductDescription] = useState('')

  /* eslint-disable */
  useEffect(() => {
    if (electricity) {
      const name = getProductNameBySlug(electricity, t, lng, Trans)
      const desc = getProductDescriptionBySlug(electricity, Trans, lng) || ''
      setProductName(name)
      setProductDescription(desc)
    }
  }, [electricity])
  /* eslint-disable */
  const displayChart = !!(chart?.totalVats && chart?.energy && chart?.regulatedConcepts)

  return (
    <S.LightSummaryContainer
      id='light-summary-container'
      count={count}
      color={eco ? theme.colors.eco.regular : data?.color}
    >
      <LightSummaryHeader productName={productName} eco={eco} />
      {productDescription ? (
        <LightProduct
          productDescription={productDescription}
          employeeLimit={employeeLim}
          employeeAcum={employeeAcu}
          isEmployee={isEmployee}
        />
      ) : (
        <br />
      )}
      <LightDates days={days} from={from} to={to} />
      <LightPrices priceNoTaxes={priceNoTaxes} vats={vats} total={total} vat={vat} />
      {displayChart && (
        <S.LightPieContainer>
          <Text fontSize='16px' fontType='semibold'>
            {t('Destino del importe de tu factura de electricidad')}
          </Text>
          <BlockCollapse defaultOpen={false} withBorder={false} buttonPosition='top'>
            <SummaryPieChart detailChart={chart} detail={electricity} type='light' eco={eco} />
          </BlockCollapse>
        </S.LightPieContainer>
      )}
      {eco && <ProductEco type='light' />}
    </S.LightSummaryContainer>
  )
}

LightSummary.propTypes = {
  count: PropTypes.number,
  data: PropTypes.shape({
    action: PropTypes.number,
    color: PropTypes.string,
    eco: PropTypes.bool,
    icon: PropTypes.any,
    id: PropTypes.string,
    title: PropTypes.string,
  }),
}

const LightSummaryHeader = ({ productName, eco }) => {
  const { t } = useTranslation()

  return (
    <S.LightSummaryHeader>
      <H3 fontType='semibold' fontColor={theme.colors.blue.regular}>
        {productName}
      </H3>
    </S.LightSummaryHeader>
  )
}

LightSummaryHeader.propTypes = {
  productName: PropTypes.string,
  eco: PropTypes.bool,
}

const LightProduct = ({ productDescription, employeeLimit, employeeAcum, isEmployee }) => {
  const { t } = useTranslation()
  const employeeLimitFormated = parseFloat(employeeLimit, 0).toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const employeeAcumFormated = parseFloat(employeeAcum, 0).toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const getEmployeeDescription = () => {
    const isLimited = employeeLimit && employeeLimit > 0

    if (isLimited) {
      return (
        <Trans values={{ employeeLimitFormated, employeeAcumFormated }}>
          El importe de tu factura se ha calculado en virtud del acuerdo tomado con la compañía para la aplicación de la
          bonificación en el suministro. Esta bonificación tiene un límite de consumo de {{ employeeLimitFormated }} kWh
          y hasta la fecha llevas acumulados {{ employeeAcumFormated }} kWh.
        </Trans>
      )
    }
    return t(
      'El importe de tu factura se ha calculado en virtud del acuerdo tomado con la compañía para la aplicación de la bonificación en el suministro.'
    )
  }

  const employeeDescription = isEmployee ? getEmployeeDescription() : null

  return (
    <S.LightProduct>
      <BlockCollapse>
        <Text>{employeeDescription || productDescription || ''}</Text>
      </BlockCollapse>
    </S.LightProduct>
  )
}

LightProduct.propTypes = {
  productDescription: PropTypes.string,
  employeeLimit: PropTypes.number,
  employeeAcum: PropTypes.number,
  isEmployee: PropTypes.bool,
}

const LightDates = ({ days, from, to }) => {
  const lng = getActiveLanguage()

  const dateRangeStart = from ? getDateByLocale(new Date(from), lng, 'dd.MM.yyyy') : null
  const dateRangeEnd = to ? getDateByLocale(new Date(to), lng, 'dd.MM.yyyy') : to

  return (
    <S.LightDates>
      {days && (
        <Text fontSize='12px' fontType='semibold'>
          <Trans values={{ days }}>{{ days }} días facturados</Trans>
        </Text>
      )}
      <Text fontSize='12px'>
        {dateRangeStart && dateRangeEnd && (
          <Trans values={{ dateRangeStart, dateRangeEnd }}>
            Del {{ dateRangeStart }} al {{ dateRangeEnd }}
          </Trans>
        )}
      </Text>
    </S.LightDates>
  )
}

const LightPrices = ({ priceNoTaxes, vats, total, vat }) => {
  const { t } = useTranslation()

  return (
    <S.LightPrices>
      <S.LightPricesItem>
        <Text fontSize='16px'>{t('Electricidad')}</Text>
        <H3>{priceFormat(priceNoTaxes, 2, 2, true)}</H3>
      </S.LightPricesItem>
      <S.LightPricesItem>
        <Text fontSize='16px'>{vat ? t(vat) : t('IVA')}</Text>
        <H3>{priceFormat(parseFloat(vats), 2, 2, true)}</H3>
      </S.LightPricesItem>
      <S.LightPricesItem>
        <Text fontSize='16px' fontType='semibold'>
          {t('Total electricidad')}
        </Text>
        <H3 fontType='semibold'>{priceFormat(total, 2, 2, true)}</H3>
      </S.LightPricesItem>
    </S.LightPrices>
  )
}

LightPrices.propTypes = {
  priceNoTaxes: PropTypes.number,
  vat: PropTypes.string,
  vats: PropTypes.number,
  total: PropTypes.number,
}

export default LightSummary
