import styled from 'styled-components/macro'
import { getFontFamilyByType } from '~/config/utils'
import { theme } from '~/styles/theme'

export const H1 = styled.h1`
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  line-height: 53px;
  color: ${({ fontColor }) => fontColor};

  ${({ fontType }) => getFontFamilyByType(fontType)}

  >span {
    ${({ fontType }) => getFontFamilyByType(fontType)}
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    font-size: 36px;
    line-height: 40px;
  }
`
export const H2 = styled.h2`
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 40px;
  color: ${({ fontColor }) => fontColor};

  ${({ fontType }) => getFontFamilyByType(fontType)}

  >span {
    ${({ fontType }) => getFontFamilyByType(fontType)}
  }
`

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  color: ${({ fontColor }) => fontColor};

  ${({ fontType }) => getFontFamilyByType(fontType)}

  >span {
    ${({ fontType }) => getFontFamilyByType(fontType)}
  }
`

export const H4 = styled.h2`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 22px;
  color: ${({ fontColor }) => fontColor};

  ${({ fontType }) => getFontFamilyByType(fontType)}

  >span {
    ${({ fontType }) => getFontFamilyByType(fontType)}
  }
`

export const H5 = styled.h5`
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  line-height: 32px;
  color: ${({ fontColor }) => fontColor};

  ${({ fontType }) => getFontFamilyByType(fontType)}

  >span {
    ${({ fontType }) => getFontFamilyByType(fontType)}
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    font-size: 22px;
    line-height: 24px;
  }
`

export const H6 = styled.h6`
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
  color: ${({ fontColor }) => fontColor};

  ${({ fontType }) => getFontFamilyByType(fontType)}

  >span {
    ${({ fontType }) => getFontFamilyByType(fontType)}
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    font-size: 18px;
    line-height: 20px;
  }
`
