import axios from 'axios'

const headers = {
  'X-API-Key': process.env.REACT_APP_API_KEY,
  'Content-type': 'application/json',
}

const strapi = axios.create({
  baseURL: process.env.REACT_APP_BASE_STRAPI_URL,
  headers,
})

export default strapi
