import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const SummaryPieChartContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  background: transparent;
  flex-direction: column;
`

export const Header = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  color: ${theme.colors.blue.dark};
  margin: 0 auto;
  justify-content: center;
`

export const Legend = styled.div`
  margin-top: 30px;
`

export const LegendSeparator = styled.div`
  height: 0.5px;
  background-color: ${theme.colors.separator};
  margin: 0 auto;
`

export const LegendContent = styled.div`
  margin-top: 19px;
  margin-bottom: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h3 {
      span {
        font-size: 14px;
      }
    }
  }
`

export const ArrowIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: ${({ up }) => (up ? '25px' : '16px')};
`

export const PieChartContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  > div {
    width: 100% !important;
    > svg {
      width: 100% !important;
    }
  }
`

export const Info = styled.p`
  width: 100%;
  font-family: 'FS Emeric Semibold';
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${theme.colors.blue.regular};
  margin: 30px auto 0 auto;
  padding-top: 20px;
  justify-content: center;
  border-top: 0.5px solid ${theme.colors.separator};
`
