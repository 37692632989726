import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const NotificationsContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: auto;
  margin-bottom: 40px;

  > div {
    background-color: ${theme.colors.gray.light};
    border-radius: ${theme.borderRadius};
    padding: 45px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${theme.breakpoints.mobile}px) {
      padding: 40px 25px;
    }
  }
`

export const NotificationsTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  > .icon-container {
    margin-bottom: 15px;
  }

  > h2 {
    text-align: center;
  }
`

export const NotificationsList = styled.div`
  text-align: center;
  & a {
    color: ${theme.colors.orange.regular};
  }
`

export const NotificationItem = styled.div`
  max-width: 600px;
  margin: auto;
  margin-bottom: 20px;

  > h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
