import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { Text } from '~/components/atoms'

export const UserOptionsContainer = styled.div`
  color: ${theme.colors.white};
  display: flex;
`

export const Label = styled(Text)`
  margin-left: 8px;
  font-size: 14px;
`

export const Name = styled(Text)`
  font-size: 14px;
  margin-left: 5px;
  text-transform: capitalize;
`
