import styled from 'styled-components/macro'
import { ErrorPage } from '~/components/organisms'

export const ErrorPageContainer = styled.div`
  .layout-wrapper {
    max-width: 1280px;
    padding: 0 15px;
    width: 100%;
    margin: auto;
  }
`

export const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ErrorPageComponent = styled(ErrorPage)``
