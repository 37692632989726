import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { H1, Text, Button } from '~/components/atoms'

export const ErrorPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 15px 0;
`

export const ErrorPageWrapper = styled.div`
  width: 100%;
  padding: 100px 0px;
  justify-content: center;
`

export const Title = styled(H1)`
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
`

export const Subtitle = styled(Text)`
  text-align: center;
`

export const FirstRecommendation = styled(Text)`
  font-size: 24px;
  line-height: 37px;
`

export const SecondRecommendation = styled(Text)`
  margin: 25px 0;
  text-align: center;
`

export const TitleContainer = styled.div``

export const ContentContainer = styled.div`
  > a {
    width: auto;
    display: flex;
    justify-content: center;
    max-width: 250px;
    margin: auto;
    margin-top: 80px;
  }
`

export const GoHomeButton = styled(Button)`
  display: flex;

  & .icon-container svg {
    transform: rotateZ(180deg);
    width: 16px;
    height: 16px;
  }
  @media (max-width: ${theme.breakpoints.large}px) {
    justify-content: center;
  }
`
