import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const SummariesContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  > div {
    width: 66%;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      margin: 0;
    }
  }
`
export const ButtonContainer = styled.div`
  z-index: 1;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
`
export const ButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const ButtonIcon = styled.div`
  display: flex;
  width: 92px;
  flex-direction: column;
  align-items: center;
  color: ${({ color }) => color};
`
export const EcoItem = styled.div`
  width: 30px;
  margin-left: -30px;

  svg {
    stroke: ${({ isActive }) => (isActive ? theme.colors.eco.regular : theme.colors.eco.inactive)};
  }
`

export const ButtonTab = styled.button`
  width: 92px;
  height: 71px;
  background-color: white;
  display: flex;
  margin-bottom: ${({ isActive }) => (isActive ? '-2px' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ color }) => color};
  border-radius: ${theme.borderRadius};
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: ${({ isActive }) => (isActive ? '1px solid white' : '0px')};
  color: ${({ color }) => color};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-family: 'FS Emeric SemiBold';
`
