import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { Text } from '~/components/atoms'

export const ContactContainer = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    padding-bottom: 20px;
  }
`

export const ContactHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    margin-bottom: 20px;
  }
`

export const List = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;

  > div {
    width: ${({ count }) => (count > 2 ? `${100 / count}%` : '33%')};
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    margin-bottom: 20px;
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`

export const ListItem = styled.div`
  max-width: 300px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  flex-direction: column;

  > .icon-container {
    margin-bottom: 15px;
  }

  > p {
    margin-bottom: 15px;
  }

  > a {
    height: 40px;
    display: block;
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    max-width: 100%;
    text-align: center;

    > .icon-container {
      margin: auto;
      margin-bottom: 15px;
    }
  }
`

export const ContactTitle = styled.h4`
  margin-bottom: 6px;
`

export const ContactSubtitle = styled.p`
  color: ${theme.colors.orange.regular};
  font-size: 18px;
`

export const ContactLink = styled.div`
  padding-top: 40px;
  border-top: 0.5px solid ${theme.colors.separator};
  text-align: center;

  > p {
    margin-bottom: 20px;
  }

  > a {
    > div {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    padding-top: 20px;
  }
`

export const Link = styled(Text)`
  text-decoration: underline;
`
