import axios from 'axios'

export const baseURL = process.env.REACT_APP_BASE_URL

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  withCredentials: false,
})

export const apiBattery = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_BATTERY,
  withCredentials: false,
})

export default api
