import React from 'react'
import { PropTypes } from 'prop-types'
import { Trans } from 'react-i18next'
import { Text } from '~/components/atoms'
import * as S from './styled'
import ecoImage from '~/assets/images/eco.png'

/**
  Uso:

  <ProductEco type='gas' />
*/

const ProductEco = ({ type }) => (
  <S.EcoContainer>
    <img src={ecoImage} alt='' />
    <Text fontSize='16px'>
      {type === 'gas' ? (
        <Trans>Gas eco, compensación del 100% de las emisiones de gases de efecto invernadero.</Trans>
      ) : (
        <Trans>Electricidad eco, 100% de la energía eléctrica suministrada procedente de fuentes renovables.</Trans>
      )}
    </Text>
  </S.EcoContainer>
)

ProductEco.defaultProps = { type: '' }

ProductEco.propTypes = { type: PropTypes.string }

export default ProductEco
