import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { Text, H3 } from '~/components/atoms'

const PromotionCard = ({ description, value }) => (
  <S.PromotionContainer>
    <Text>{description}</Text>
    <H3>{value}</H3>
  </S.PromotionContainer>
)

PromotionCard.defaultProps = {
  description: '',
  value: '',
}

PromotionCard.propTypes = {
  description: PropTypes.string,
  value: PropTypes.node,
}

export default PromotionCard
