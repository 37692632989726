import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const WelcomeContainer = styled.div`
  padding: 0px;
  width: calc(100% - 400px);
  padding-right: 40px;
  display: flex;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`

export const WelcomeContent = styled.div`
  width: 100%;
  height: auto;
  background-color: transparent;
`

export const WelcomeTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
  height: 103px;
  border-bottom: 0.5px solid ${theme.colors.separator};

  > .icon-container {
    margin-right: 20px;
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    height: auto;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`
export const WelcomeDate = styled.div`
  display: flex;
  gap: 8px;
`

export const UserDataWelcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  max-width: calc(100% - 60px);

  > h1 {
    text-transform: capitalize;
    margin-bottom: 15px;
  }

  > p {
    &:first-letter {
      text-transform: uppercase;
    }

    b {
      font-family: 'FS Emeric Bold';
      font-weight: normal;
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    text-align: left;
    padding-left: 0;
    align-items: left;
    width: 100%;
    max-width: 100%;
    margin-bottom: 35px;
  }
`
