import produce from 'immer'
import { Types } from './constants'

export const INITIAL_STATE = { activeTabIndex: 0 }

export default function tabsReducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_ACTIVE_TAB_INDEX:
        draft.activeTabIndex = action.payload
        break
      default:
        break
    }
  })
}
