import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import { v4 } from 'uuid'

import { H2, Text, Icon, LinkRegular, Button } from '~/components/atoms'
import { theme } from '~/styles/theme'
import { useMediaQuery } from '~/hooks'
import {
  AC_URL,
  TYPE_CLIENT,
  OTHER_CHANNELS_CLIENT,
  OTHER_CHANNELS_COMPANY,
  OTHER_CHANNELS_CLIENT_NEWCO,
} from '~/config/constants'

import * as S from './styled'
import { getContactDescriptions } from './utils'
import { CONTACT_PHONES } from '../../../config/constants'

const Contact = ({ isRdi, showContactLink }) => {
  const { iframeMode } = useSelector(state => state.auth || {})

  if (iframeMode) return null

  return (
    <S.ContactContainer data-cy='contact' className='layout-wrapper'>
      <ContactHeader isRdi={isRdi} />
      <ContactPhones isRdi={isRdi} />
      {showContactLink && <ContactLink isRdi={isRdi} />}
    </S.ContactContainer>
  )
}

Contact.propTypes = {
  isRdi: PropTypes.bool,
  showContactLink: PropTypes.bool,
}

Contact.defaultProps = {
  isRdi: true,
  showContactLink: true,
}

const ContactHeader = () => {
  const { t } = useTranslation()

  return (
    <S.ContactHeader>
      <H2>{t('Tus canales de contacto')}</H2>
    </S.ContactHeader>
  )
}

const ContactPhones = ({ isRdi }) => {
  const { t } = useTranslation()

  const { invoice } = useSelector(state => state.auth || {})
  const { contactPhones, typeClient, language } = invoice || {}
  const phones = getContactDescriptions(contactPhones, t)
  const otherChannels =
    !typeClient || typeClient === TYPE_CLIENT.user ? OTHER_CHANNELS_CLIENT[language] : OTHER_CHANNELS_COMPANY[language]
  const otherChannelsNewco = OTHER_CHANNELS_CLIENT_NEWCO[language]

  return (
    <S.List count={phones?.length}>
      {phones?.map((phone, index) => (
        <Item
          key={v4()}
          image={phone.image}
          title={phone.label}
          asistence={
            index !== 0 ? (
              <>
                <Text fontType='semibold'>{t('Asistencia Técnica')}</Text>
                <Text fontSize='16px'>{CONTACT_PHONES.phone_asistance}</Text>
              </>
            ) : null
          }
          subtitle={phone.phone}
          phoneid={phone.order}
          color={phone.color}
          phoneKey={phone.key}
          footer={
            index === 0 ? (
              <LinkRegular externalPath={isRdi ? otherChannels : otherChannelsNewco}>
                <S.Link fontType='semibold'>{t('Otros canales de contacto')}</S.Link>
              </LinkRegular>
            ) : null
          }
        />
      ))}
    </S.List>
  )
}

const Item = ({ image, title, asistence, subtitle, footer }) => {
  const { isMobile } = useMediaQuery()

  return (
    <S.ListItem>
      <Icon Source={image} width='32px' height='34px' color={theme.colors.blue.regular} />
      <Text fontType='semibold'>{title}</Text>
      {isMobile ? (
        <>
          {subtitle.map(phone => (
            <LinkRegular
              externalPath={`tel:${phone && phone.replaceAll ? phone.replaceAll(' ', '') : ''}`}
              data-cy='telefono-general'
              key={v4()}
            >
              <PhoneText phone={phone} />
            </LinkRegular>
          ))}
        </>
      ) : (
        <>
          {subtitle.map(phone => (
            <PhoneText phone={phone} key={v4()} />
          ))}
        </>
      )}
      {asistence}
      {footer}
    </S.ListItem>
  )
}

const PhoneText = ({ phone }) => (
  <Text fontSize='16px' data-cy={`telefono-${phone}`} fontColor={theme.colors.blue.regular}>
    {phone}
  </Text>
)

PhoneText.defaultProps = { phone: '' }

PhoneText.propTypes = { phone: PropTypes.string }

const ContactLink = ({ isRdi }) => {
  const { t } = useTranslation()
  const { isMobile } = useMediaQuery()

  return (
    <S.ContactLink>
      <Text fontSize={!isMobile ? '20px' : '16px'}>
        {isRdi
          ? t('O entra en tu Área Clientes, donde podrás realizar tus gestiones fácilmente:')
          : t('O accede a Área Clientes, donde podrás realizar tus gestiones fácilmente:')}
      </Text>
      {isRdi ? (
        <LinkRegular externalPath={AC_URL} data-cy='telefono-general'>
          <Button classBtn='orange' label={t('Acceder a mi Área Clientes')} />
        </LinkRegular>
      ) : (
        <LinkRegular externalPath={AC_URL} data-cy='telefono-general'>
          <Button classBtn='orange' label={t('Ir a Área Clientes')} />
        </LinkRegular>
      )}
    </S.ContactLink>
  )
}

ContactLink.propTypes = { isRdi: PropTypes.bool }

ContactLink.defaultProps = { isRdi: true }

Item.defaultProps = {
  image: {},
  title: '',
  asistence: null,
  subtitle: [],
  footer: null,
}

Item.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  asistence: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  subtitle: PropTypes.arrayOf(PropTypes.string),
  footer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

ContactPhones.propTypes = { isRdi: PropTypes.bool }

ContactPhones.defaultProps = { isRdi: false }

export default Contact
