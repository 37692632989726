import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as S from '../ErrorPage/styled'
import { theme } from '~/styles/theme'
import { LinkRegular } from '~/components/atoms'
import { NATURGY_URL } from '~/config/constants'

const UnderConstruction = ({ ...props }) => {
  const { t } = useTranslation()
  const { iframeMode } = useSelector(state => state.auth)

  return (
    <S.ErrorPageContainer {...props}>
      <S.ErrorPageWrapper>
        <S.TitleContainer>
          <S.Title fontColor={theme.colors.blue.regular} fontType='regular'>
            {t('¡Hola! Estamos construyendo tu nuevo espacio de factura.')}
          </S.Title>
          <S.Subtitle fontSize='20px'>
            {t('Pronto estará disponible y podrás consultar tus facturas de manera cómoda y sencilla.')}
          </S.Subtitle>
        </S.TitleContainer>
        <S.ContentContainer>
          {!iframeMode && (
            <LinkRegular externalPath={NATURGY_URL}>
              <S.GoHomeButton classBtn='orange' label={t('Volver al inicio')} />
            </LinkRegular>
          )}
        </S.ContentContainer>
      </S.ErrorPageWrapper>
    </S.ErrorPageContainer>
  )
}

export default UnderConstruction
