import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { getFontFamilyByType } from '~/config/utils'

export const TextContainer = styled.p`
  color: ${({ fontColor, fontSize }) => (fontSize !== 'error' ? fontColor : theme.colors.red.text)};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: ${({ fontLineHeight }) => fontLineHeight || '17px'};

  ${({ fontType }) => getFontFamilyByType(fontType)}

  >span {
    ${({ fontType }) => getFontFamilyByType(fontType)}
  }
`
