import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import * as S from './styled'
import { theme } from '~/styles/theme'
import { LinkRegular } from '~/components/atoms'
import { NATURGY_URL, AC_URL } from '~/config/constants'

const ErrorPage = ({ type, isRdi, ...props }) => {
  const { t } = useTranslation()
  const { iframeMode } = useSelector(state => state.auth)

  const getTexts = errorType => {
    if (errorType === 403) return { title: t('Identificación errónea. Por favor, inténtalo de nuevo.') }
    if (iframeMode) {
      return {
        title: t('¡Ups!'),
        subtitle: t('Estamos trabajando en la publicación del resumen de tu factura'),
        description: t(
          'Pero no te preocupes porque ya puedes descargártela en PDF desde la opción que tienes arriba en esta misma página'
        ),
      }
    }
    if (!isRdi) {
      return {
        title: t('¡Ups! Algo ha fallado'),
        subtitle: t('El resumen de tu factura no está disponible en estos instantes.'),
        description: t(
          'Inténtalo más tarde, por favor. O accede a Área Clientes, donde podrás realizar tus gestiones fácilmente:'
        ),
      }
    }
    return {
      title: t('Oppps! Algo ha fallado'),
      subtitle: t('El Resumen de tu factura no está disponible en estos instantes.'),
      description: t('Inténtalo más tarde'),
    }
  }

  const { title, subtitle, description } = getTexts(type)

  return (
    <S.ErrorPageContainer {...props}>
      <S.ErrorPageWrapper>
        <S.TitleContainer>
          <S.Title fontColor={theme.colors.blue.regular} fontType='regular'>
            {title}
          </S.Title>
          {subtitle && <S.Subtitle fontSize='20px'>{subtitle}</S.Subtitle>}
        </S.TitleContainer>
        <S.ContentContainer>
          {description && (
            <S.SecondRecommendation fontSize='26px' fontLineHeight='30px'>
              {description}
            </S.SecondRecommendation>
          )}

          {!iframeMode && (
            <>
              {isRdi ? (
                <LinkRegular externalPath={NATURGY_URL}>
                  <S.GoHomeButton classBtn='orange' label={t('Volver al inicio')} />
                </LinkRegular>
              ) : (
                <LinkRegular externalPath={AC_URL}>
                  <S.GoHomeButton classBtn='orange' label={t('Ir a Área Clientes')} />
                </LinkRegular>
              )}
            </>
          )}
        </S.ContentContainer>
      </S.ErrorPageWrapper>
    </S.ErrorPageContainer>
  )
}

ErrorPage.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRdi: PropTypes.bool,
}

ErrorPage.defaultProps = {
  type: null,
  isRdi: true,
}

export default ErrorPage
