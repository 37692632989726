import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import * as S from './styled'
import { getActiveLanguage } from '~/config/utils'
import paths from '~/routes/constants'

import {
  Welcome,
  SupplyResume,
  Footer,
  Contact,
  UserData,
  Summaries,
  Financing,
  Promotions,
  Notifications,
  CombinedConsumption,
  HistoricalNewco,
} from '~/components/organisms'

const Home = () => {
  const { qs, client, iframeMode, token } = useSelector(state => state.auth)
  const lng = getActiveLanguage()
  const history = useHistory()
  const { t } = useTranslation()
  const { invoice } = useSelector(state => state.auth || {})

  if (process.env.NODE_ENV === 'development') {
    console.log('INVOICE->', invoice)
  }

  const { financings, promotions } = invoice || {}
  const hasPromotions = promotions?.data?.some(o => o.value !== 0)

  if (!qs && !token) history.push(paths.error)

  if (client && lng) {
    document.documentElement.lang = lng
  }
  document.title = t('Resumen factura')

  const errorHandler = (error, info) => {
    // Do something with the error
    console.error({ error, info })
  }

  const modulesCount = hasPromotions && financings ? 2 : 1

  return (
    <ErrorBoundary FallbackComponent={() => <Redirect to={paths.error} />} onError={errorHandler}>
      <S.HomeContainer iframeMode={iframeMode}>
        <S.WellcomeWrapper className='layout-wrapper'>
          <Welcome />
          <SupplyResume />
        </S.WellcomeWrapper>
        <Summaries />
        {(hasPromotions || financings) && (
          <S.ModulesWrapper count={modulesCount}>
            <div className='layout-wrapper'>
              <Promotions count={modulesCount} />
              <Financing count={modulesCount} />
            </div>
          </S.ModulesWrapper>
        )}
        {invoice?.barChart?.isRdi ? <CombinedConsumption /> : <HistoricalNewco />}
        <Notifications />
        <UserData />
        <Contact isRdi={invoice?.barChart?.isRdi} />
      </S.HomeContainer>
      <Footer />
    </ErrorBoundary>
  )
}

export default Home
