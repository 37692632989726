import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const HomeContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 15px 0;
  ${({ iframeMode }) => iframeMode && 'padding:0px;'}

  .layout-wrapper {
    max-width: 1132px;
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
    ${({ iframeMode }) => iframeMode && 'max-width:100%;padding:0;'}

    @media (max-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    padding: 35px 15px 0;
    ${({ iframeMode }) => iframeMode && 'padding:0;'}
  }
`

export const WellcomeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px !important;
  height: auto;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    flex-direction: column;
    margin-bottom: 20px !important;
  }
`

export const ModulesWrapper = styled.div`
  max-width: 1132px;
  width: 100%;
  margin: auto;
  margin-bottom: 40px;

  > div {
    background-color: ${theme.colors.gray.light};
    border-radius: ${theme.borderRadius};
    padding: 45px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    > div {
      background-color: ${theme.colors.white};
      padding: 0px;
      flex-direction: column;
      
      >div{
        background-color: ${theme.colors.gray.light};
        border-radius: ${theme.borderRadius};
        padding:45px; 25px;
        margin-bottom:25px;

        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
`
