import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { PropTypes } from 'prop-types'
import * as S from './styled'
import { PromotionCard } from '~/components/molecules'
import { H2, H3, Text, Icon } from '~/components/atoms'
import { getActiveLanguage, priceFormat } from '~/config/utils'
import { PromotionsIcon } from '~/assets/icons'
import { theme } from '~/styles/theme'

const Promotions = ({ count, ...props }) => {
  const { invoice } = useSelector(state => state.auth || {})
  const { promotions } = invoice || {}
  const { data, total } = promotions || {}

  const hasPromotions = data?.some(o => o.value !== 0)
  if (!hasPromotions) return null

  return (
    <S.PromotionsContainer {...props} id='promotion-summary-container' data-cy='promotions' count={count}>
      <PromotionsTitle />
      <PromotionsSubtitle />
      <PromotionsList data={data} />
      <PromotionsTotal total={total} />
    </S.PromotionsContainer>
  )
}

Promotions.defaultProps = { count: 1 }

Promotions.propTypes = { count: PropTypes.number }

const PromotionsTitle = () => {
  const { t } = useTranslation()

  return (
    <S.PromotionsTitle>
      <Icon Source={PromotionsIcon} width='30px' height='30px' color={theme.colors.orange.regular} />
      <H2>{t('Promociones')}</H2>
    </S.PromotionsTitle>
  )
}

const PromotionsSubtitle = () => {
  const { t } = useTranslation()

  return (
    <S.PromotionsSubtitle>
      <Text>{t('¡Buenas noticias! En esta factura has conseguido ahorrar:')}</Text>
    </S.PromotionsSubtitle>
  )
}

const PromotionsList = ({ data }) => {
  const lng = getActiveLanguage()

  return (
    <S.List>
      {data.map(promotion => {
        const value = priceFormat(Math.abs(parseFloat(promotion.value)))
        const description = promotion.title || promotion.product?.description[lng] || ''
        return <PromotionCard key={v4()} description={description} value={value} />
      })}
    </S.List>
  )
}

PromotionsList.defaultProps = { data: null }

PromotionsList.propTypes = { data: PropTypes.arrayOf(PropTypes.PromotionsList) }

const PromotionsTotal = ({ total }) => {
  const { t } = useTranslation()

  return (
    <S.PromotionsTotal>
      <Text fontSize='16px' fontType='semibold'>
        {t('Total ahorro')}
      </Text>
      <H3 fontType='semibold'>{priceFormat(Math.abs(total))}</H3>
    </S.PromotionsTotal>
  )
}

PromotionsTotal.defaultProps = { total: 0 }

PromotionsTotal.propTypes = { total: PropTypes.number }

export default Promotions
