import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import * as S from './styled'
import { ResumeData } from './utils'
import { Text, H3 } from '~/components/atoms'
import { theme } from '~/styles/theme'
import { InvoiceSummary } from '~/components/molecules'
import { priceFormat } from '~/config/utils'
import { SHOW_DUAL_IVA } from '~/config/constants'

const SupplyResume = () => {
  const { t } = useTranslation()
  const { invoice, iframeMode } = useSelector(state => state.auth || {})
  const summary = invoice?.amountSummary || {}
  const data = ResumeData(summary, t, invoice?.isRdi)
  const withElectricity = summary?.electricity?.subtotal > 0
  const withGas = summary?.gas?.subtotal > 0

  const taxType = summary?.electricity?.vat || summary?.gas?.vat || null
  const { gas, electricity } = invoice || {}
  const { eco: ecoGas } = gas || {}
  const { eco: ecoElectricity } = electricity || {}

  const ecoResult = () => ecoElectricity || ecoGas
  const ecoCheck = ecoResult(ecoElectricity, ecoGas)

  let withExentConcepts = 0

  return (
    <S.SupplyResumeContainer id='resume-container' data-cy='supply-resume'>
      <Text fontType='semibold' fontSize='18px'>
        <S.TitleSupply isEco={ecoCheck}>{t('Tu factura')}</S.TitleSupply>
      </Text>
      <InvoiceSummary />
      <S.SupplyResumeContentContainer>
        {data.map(concept => {
          const isTax = concept.slug === taxType
          let showConcept = true
          if (withGas && concept.key === 'total_subt_gas' && concept.value === 0) {
            showConcept = true
          } else if (withElectricity && concept.key === 'total_subt_electricidad' && concept.value === 0) {
            showConcept = true
          } else if (!parseFloat(concept.value) && !isTax) {
            showConcept = false
          }

          if (!showConcept) {
            return null
          }
          const acum = parseFloat(concept.value).toFixed(2)
          withExentConcepts = parseFloat(withExentConcepts.toFixed(2)) + parseFloat(acum)

          return (
            <S.SupplyResumeItem isMobile key={v4()}>
              <div>
                <S.ConceptItem>
                  <Text fontColor={theme.colors.blue.regular}>{concept.text}</Text>
                </S.ConceptItem>
                <S.PriceItem>
                  <H3 fontType='semibold'>{priceFormat(parseFloat(concept.value), 2, 2, true)}</H3>
                  {!iframeMode && <S.WithLink isTax={!concept.link} />}
                </S.PriceItem>
              </div>
              {SHOW_DUAL_IVA && concept.key === 'taxes' && withElectricity && withGas && (
                <S.TaxesBlock className='active'>
                  <S.TaxesBlockItem>
                    <Text>{`${t(concept.text)} ${t('electricidad')}`}</Text>
                    <Text fontSize='16px' fontType='semibold'>
                      {priceFormat(summary.electricity.vats)}
                    </Text>
                  </S.TaxesBlockItem>
                  <S.TaxesBlockItem>
                    <Text>{`${t(concept.text)} ${t('gas')}`}</Text>
                    <Text fontSize='16px' fontType='semibold'>
                      {priceFormat(summary.gas.vats)}
                    </Text>
                  </S.TaxesBlockItem>
                </S.TaxesBlock>
              )}
            </S.SupplyResumeItem>
          )
        })}
        {parseFloat(withExentConcepts).toFixed(2) !== parseFloat(summary?.total || 0).toFixed(2) && (
          <S.SupplyResumeItem isMobile key={v4()}>
            <div>
              <S.ConceptItem>
                <Text>{t('Otros conceptos')}</Text>
              </S.ConceptItem>
              <S.PriceItem>
                <H3 fontType='semibold'>
                  {priceFormat(parseFloat(parseFloat(summary.total || 0) - parseFloat(withExentConcepts)))}
                </H3>
              </S.PriceItem>
              <S.WithLink />
            </div>
          </S.SupplyResumeItem>
        )}
      </S.SupplyResumeContentContainer>
    </S.SupplyResumeContainer>
  )
}

export default SupplyResume
