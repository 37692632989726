import React from 'react'

import { useSelector } from 'react-redux'

import spinner from '~/assets/images/naturgy-logo-spinner.gif'

import * as S from './styled'

const Spinner = () => {
  const { iframeMode } = useSelector(state => state.auth)

  const style = iframeMode ? S.iframeModeStyle : {}

  return (
    <S.Spinner style={style}>
      <img src={spinner} alt='loading' />
    </S.Spinner>
  )
}

export default Spinner
