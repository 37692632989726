import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from '~/assets/locales/es/translation.json'
import eu from '~/assets/locales/eu/translation.json'
import ct from '~/assets/locales/ct/translation.json'
import gl from '~/assets/locales/gl/translation.json'

const resources = {
  es: { translation: es },
  eu: { translation: eu },
  ct: { translation: ct },
  gl: { translation: gl },
}

/**
 * Interpolation prefix/suffix changed to avoid compilation error:
 * "Objects are not valid as a React child in <Trans />".
 *
 * @see https://www.i18next.com/translation-function/interpolation#all-interpolation-options
 */
i18n.use(initReactI18next).init({
  resources,
  lng: 'es',
  debug: false,
  keySeparator: false,
  nsSeparator: false,
  interpolation: {
    escapeValue: false,
    prefix: '{{',
    suffix: '}}',
  },
})

export default i18n
