import { getDateByLocale, getConsumptionFormated } from './utils'
import { SECTOR_TYPE } from './constants'

const getProductNameBySlug = (slug, invoice) => {
  if (invoice) {
    switch (slug) {
      case 'bonificacion-proxima-cuota-tarifa-plana-gas':
      case 'limite-tarifa-plana-cercano-gas':
      case 'superacion-limite-tarifa-plana-gas':
        return invoice?.gas?.name || null

      case 'bonificacion-proxima-cuota-tarifa-plana-electricidad':
      case 'limite-tarifa-plana-cercano-electricidad':
      case 'superacion-limite-tarifa-plana-electricidad':
        return invoice?.electricity?.name || null

      default:
        return null
    }
  }

  return false
}

const getProductNameBySector = (sector, invoice) => {
  switch (sector) {
    case '04':
      return invoice?.electricity?.name || null
    case '02':
      return invoice?.gas?.name || null || null
    default:
      return null
  }
}

const getTitleFormated = (notification, params, lng) => {
  if (notification?.message?.title) {
    let msg = notification.message.title[lng]
    Object.keys(params).forEach(key => {
      if (notification.message.title[lng].includes(`{{${key}}}`)) {
        msg = msg.replace(new RegExp(`{{${key}}}`, 'g'), params[key])
      }
    })
    return msg
  }
  return null
}

const getDescriptionFormated = (notification, params, lng) => {
  if (notification?.message?.description) {
    let msg = notification.message.description[lng]

    Object.keys(params).forEach(key => {
      if (msg.includes(`{{${key}}}`)) {
        msg = msg.replace(new RegExp(`{{${key}}}`, 'g'), params[key])
      }
    })
    return msg
  }
  return null
}

const getAlternativeFormated = (notification, params, lng) => {
  if (notification?.message?.alternative) {
    let msg = notification.message.alternative[lng]
    Object.keys(params).forEach(key => {
      if (notification.message.alternative[lng].includes(`{{${key}}}`)) {
        msg = msg.replace(new RegExp(`{{${key}}}`, 'g'), params[key])
      }
    })
    return msg
  }
  return null
}

function parseFloatWithComma(value) {
  return parseFloat(value).toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 6,
  })
}

export const getNotificationType = (notification, invoice, lng = 'es', t) => {
  const { slug } = notification
  const params = notification.params || null

  const paramsList = {
    ...params,
    fechfi: params?.fechfi ? getDateByLocale(new Date(params.fechfi), lng, 'dd.MM.yyyy') : null,
    bldat: params?.bldat ? getDateByLocale(new Date(params.bldat), lng, 'dd.MM.yyyy') : null,
    productTitle: getProductNameBySlug(slug, invoice) || null,
    productTitleWithSector: params?.sector ? getProductNameBySector(params.sector, invoice) : null,
    fechaFact: params?.fecha_facturacion
      ? getDateByLocale(new Date(params.fecha_facturacion), lng, 'dd.MM.yyyy')
      : null,
    facRefact: params?.factura_refacturacion ? params.factura_refacturacion : null,
    facRenov: params?.fecha_renovacion ? getDateByLocale(new Date(params.fecha_renovacion), lng, 'dd.MM.yyyy') : null,
    facRenov4:
      params?.fe_online && params?.fe_online === 'A'
        ? getDateByLocale(new Date('4/1/2020'), lng, 'd MMMM')
        : params?.fe_online && params?.fe_online === 'B'
        ? getDateByLocale(new Date('9/1/2020'), lng, 'd MMMM')
        : null,
    facRenov2: params?.fecha ? getDateByLocale(new Date(params.fecha), lng, 'dd.MM.yyyy') : null,
    fecrenova: params?.fecrenova ? getDateByLocale(new Date(params.fecrenova), lng, 'dd.MM.yyyy') : null,
    facAjuste: params?.factura_ajuste ? params.factura_ajuste : null,
    dateAjuste: params?.fecha_factura_ajuste
      ? getDateByLocale(new Date(params.fecha_factura_ajuste), lng, 'dd.MM.yyyy')
      : null,
    newPrice: params?.cuotafija_futuro
      ? parseFloat(params.cuotafija_futuro).toLocaleString('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      : null,
    limite: params?.limite
      ? parseFloat(params.limite).toLocaleString('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      : null,
    consumo: params?.consumo ? getConsumptionFormated(params.consumo) : null,
    notificationType: params?.sector ? t(SECTOR_TYPE[params.sector]) : null,
    startDate: params?.fifacturacion ? getDateByLocale(new Date(params.fifacturacion), lng, 'dd.MM.yyyy') : null,
    endDate: params?.fffacturacion ? getDateByLocale(new Date(params.fffacturacion), lng, 'dd.MM.yyyy') : null,
    facAnul: params?.factura_anulada ? params.factura_anulada : null,
    precioEnergiaP1: params?.precio_energia_p1 ? parseFloatWithComma(params.precio_energia_p1) : null,
    precioEnergiaP2: params?.precio_energia_p2 ? parseFloatWithComma(params.precio_energia_p2) : null,
    precioEnergiaP3: params?.precio_energia_p3 ? parseFloatWithComma(params.precio_energia_p3) : null,
    precioPotencia: params?.precio_potencia ? parseFloatWithComma(params.precio_potencia) : null,
    precioVariablePE1: params?.pe1_online ? parseFloatWithComma(params.pe1_online) : null,
    precioVariablePP1: params?.pp1_online ? parseFloatWithComma(params.pp1_online) : null,
    precioInfoPP1: params?.ed_inf_pp1 ? parseFloatWithComma(params.ed_inf_pp1) : null,
    precioInfoPP2: params?.ed_inf_pp2 ? parseFloatWithComma(params.ed_inf_pp2) : null,
    precioInfoPP3: params?.ed_inf_pp3 ? parseFloatWithComma(params.ed_inf_pp3) : null,
    precioInfoPP4: params?.ed_inf_pp4 ? parseFloatWithComma(params.ed_inf_pp4) : null,
    precioInfoPP5: params?.ed_inf_pp5 ? parseFloatWithComma(params.ed_inf_pp5) : null,
    precioInfoPP6: params?.ed_inf_pp6 ? parseFloatWithComma(params.ed_inf_pp6) : null,
    precioInfoCP1: params?.ed_inf_cp1 ? parseFloatWithComma(params.ed_inf_cp1) : null,
    precioInfoCP2: params?.ed_inf_cp2 ? parseFloatWithComma(params.ed_inf_cp2) : null,
    precioInfoCP3: params?.ed_inf_cp3 ? parseFloatWithComma(params.ed_inf_cp3) : null,
    precioInfoCP4: params?.ed_inf_cp4 ? parseFloatWithComma(params.ed_inf_cp4) : null,
    precioInfoCP5: params?.ed_inf_cp5 ? parseFloatWithComma(params.ed_inf_cp5) : null,
    precioInfoCP6: params?.ed_inf_cp6 ? parseFloatWithComma(params.ed_inf_cp6) : null,
    precioCuota: params?.cuota ? parseFloatWithComma(params.cuota) : null,
    precioExceso: params?.exceso ? parseFloatWithComma(params.exceso) : null,
    precpot_p1: params?.precpot_p1 ? parseFloatWithComma(params.precpot_p1) : null,
    precpot_p2: params?.precpot_p2 ? parseFloatWithComma(params.precpot_p2) : null,
    precenerg_p1: params?.precenerg_p1 ? parseFloatWithComma(params.precenerg_p1) : null,
    precenerg_p2: params?.precenerg_p2 ? parseFloatWithComma(params.precenerg_p2) : null,
    precenerg_p3: params?.precenerg_p3 ? parseFloatWithComma(params.precenerg_p3) : null,
    precpot_p3: params?.precpot_p3 ? parseFloatWithComma(params.precpot_p3) : null,
    precpot_p4: params?.precpot_p4 ? parseFloatWithComma(params.precpot_p4) : null,
    precpot_p5: params?.precpot_p5 ? parseFloatWithComma(params.precpot_p5) : null,
    precpot_p6: params?.precpot_p6 ? parseFloatWithComma(params.precpot_p6) : null,
    precenerg_p4: params?.precenerg_p4 ? parseFloatWithComma(params.precenerg_p4) : null,
    precenerg_p5: params?.precenerg_p5 ? parseFloatWithComma(params.precenerg_p5) : null,
    precenerg_p6: params?.precenerg_p6 ? parseFloatWithComma(params.precenerg_p6) : null,
  }

  const titleFormated = getTitleFormated(notification, paramsList, lng)
  const descriptionFormated = getDescriptionFormated(notification, paramsList, lng)
  const alternativeFormated = getAlternativeFormated(notification, paramsList, lng)

  return {
    slug,
    title: titleFormated,
    description: descriptionFormated,
    alternative: alternativeFormated,
  }
}
