import React from 'react'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import map from 'lodash/map'
import RouteWrapper from './Route'
import paths from './constants'

const errorRedirect = () => <Redirect to='error' />

const Routes = () => (
  <Router>
    <Switch>
      {map(paths, ({ path, component, parent }, key) => (
        <RouteWrapper key={key} path={path} component={component} parent={parent} />
      ))}
      <RouteWrapper path='/' component={errorRedirect} />
      <RouteWrapper component={errorRedirect} />
    </Switch>
  </Router>
)

export default Routes
