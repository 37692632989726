import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'

import { Text, BlockCollapse, ProductEco, H3 } from '~/components/atoms'
import { theme } from '~/styles/theme'
import {
  getActiveLanguage,
  getDateByLocale,
  getProductNameBySlug,
  getProductDescriptionBySlug,
  priceFormat,
} from '~/config/utils'

import SummaryPieChart from '../SummaryPieChart'
import * as S from './styled'

const GasSummary = ({ count }) => {
  const { t } = useTranslation()
  const lng = getActiveLanguage()

  const [productName, setProductName] = useState('')
  const [productDescription, setProductDescription] = useState('')

  const { invoice } = useSelector(state => state.auth || {})
  const { gas } = invoice || {}
  const { chart, eco, days, employeeLim, employeeAcu, isEmployee, from, to, priceNoTaxes, vats, total, vat } = gas || {}

  /* eslint-disable */
  useEffect(() => {
    if (gas) {
      const name = getProductNameBySlug(gas, t, lng, Trans)
      const desc = getProductDescriptionBySlug(gas, Trans, lng) || ''

      setProductName(name)
      setProductDescription(desc)
    }
  }, [gas])
  /* eslint-disable */
  const displayChart = !!(chart?.totalVats && chart?.energy && chart?.regulatedConcepts)

  return (
    <S.GasSummaryContainer
      id='gas-summary-container'
      count={count}
      color={eco ? theme.colors.eco.regular : theme.colors.blue.regular}
    >
      <GasSummaryHeader eco={eco} productName={productName} />
      {productDescription ? (
        <GasProduct
          productDescription={productDescription}
          employeeLimit={employeeLim}
          employeeAcum={employeeAcu}
          isEmployee={isEmployee}
        />
      ) : (
        <br />
      )}
      <GasDates days={days} from={from} to={to} />
      <GasPrices priceNoTaxes={priceNoTaxes} vats={vats} total={total} vat={vat} />
      {displayChart && (
        <S.GasPieContainer>
          <Text fontSize='16px' fontType='semibold'>
            {t('Destino del importe de tu factura de gas')}
          </Text>
          <BlockCollapse defaultOpen={false} withBorder={false} buttonPosition='top'>
            <SummaryPieChart detailChart={chart} detail={gas} type='gas' eco={eco} />
          </BlockCollapse>
        </S.GasPieContainer>
      )}
      {eco && <ProductEco type='gas' />}
    </S.GasSummaryContainer>
  )
}

GasSummary.propTypes = {
  count: PropTypes.number,
  data: PropTypes.shape({
    action: PropTypes.number,
    color: PropTypes.string,
    eco: PropTypes.bool,
    icon: PropTypes.any,
    id: PropTypes.string,
    title: PropTypes.string,
  }),
}

const GasSummaryHeader = ({ productName, eco }) => {
  const { t } = useTranslation()

  return (
    <S.GasSummaryHeader>
      <H3 fontType='semibold' fontColor={theme.colors.blue.regular}>
        {productName}
      </H3>
    </S.GasSummaryHeader>
  )
}

const GasProduct = ({ employeeLimit, employeeAcum, isEmployee, productDescription }) => {
  const { t } = useTranslation()

  const employeeLimitFormated = parseFloat(employeeLimit, 0).toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const employeeAcumFormated = parseFloat(employeeAcum, 0).toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const getEmployeeDescription = () => {
    const isLimited = employeeLimit && employeeLimit > 0

    if (isLimited) {
      return (
        <Trans values={{ employeeLimitFormated, employeeAcumFormated }}>
          El importe de tu factura se ha calculado en virtud del acuerdo tomado con la compañía para la aplicación de la
          bonificación en el suministro. Esta bonificación tiene un límite de consumo de {{ employeeLimitFormated }} kWh
          y hasta la fecha llevas acumulados {{ employeeAcumFormated }} kWh.
        </Trans>
      )
    }
    return t(
      'El importe de tu factura se ha calculado en virtud del acuerdo tomado con la compañía para la aplicación de la bonificación en el suministro.'
    )
  }

  const employeeDescription = isEmployee ? getEmployeeDescription() : null

  return (
    <S.GasProduct>
      <BlockCollapse>
        <Text>{employeeDescription || productDescription || ''}</Text>
      </BlockCollapse>
    </S.GasProduct>
  )
}

const GasDates = ({ days, from, to }) => {
  const lng = getActiveLanguage()

  const dateRangeStart = from ? getDateByLocale(new Date(from), lng, 'dd.MM.yyyy') : null
  const dateRangeEnd = to ? getDateByLocale(new Date(to), lng, 'dd.MM.yyyy') : to

  return (
    <S.GasDates>
      {days && (
        <Text fontSize='12px' fontType='semibold'>
          <Trans values={{ days }}>{{ days }} días facturados</Trans>
        </Text>
      )}
      <Text fontSize='12px'>
        {dateRangeStart && dateRangeEnd && (
          <Trans values={{ dateRangeStart, dateRangeEnd }}>
            Del {{ dateRangeStart }} al {{ dateRangeEnd }}
          </Trans>
        )}
      </Text>
    </S.GasDates>
  )
}

const GasPrices = ({ priceNoTaxes, vats, total, vat }) => {
  const { t } = useTranslation()

  return (
    <S.GasPrices>
      <S.GasPricesItem>
        <Text fontSize='16px'>{t('Gas')}</Text>
        <H3>{priceFormat(priceNoTaxes, 2, 2, true)}</H3>
      </S.GasPricesItem>
      <S.GasPricesItem>
        <Text fontSize='16px'>{vat ? t(vat) : t('IVA')}</Text>
        <H3>{priceFormat(parseFloat(vats), 2, 2, true)}</H3>
      </S.GasPricesItem>
      <S.GasPricesItem>
        <Text fontSize='16px' fontType='semibold'>
          {t('Total gas')}
        </Text>
        <H3 fontType='semibold'>{priceFormat(total, 2, 2, true)}</H3>
      </S.GasPricesItem>
    </S.GasPrices>
  )
}

export default GasSummary
