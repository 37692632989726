import qs from 'qs'

import api from '../api'

export const getLightHistoricFromApi = ({ token, qc, factnum }) => {
  const params = qs.stringify({ qc, factnum }, { skipNulls: true })
  return api.get(`/invoice/hiselec/${token}?${params}`)
}

export const getGasHistoricFromApi = ({ token, qc, factnum }) => {
  const params = qs.stringify({ qc, factnum }, { skipNulls: true })
  return api.get(`/invoice/hisgas/${token}?${params}`)
}
