import React, { Fragment, useEffect, useState } from 'react'
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, Line, Legend } from 'recharts'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { upperCase } from 'lodash'
import * as S from './styled'
import { theme } from '~/styles/theme'
import { dataTypeObject, parseData, selectFillPattern } from './utils'
import { Icon, Text } from '~/components/atoms'
import { CheckIcon } from '~/assets/icons'
import { getActiveLanguage } from '~/config/utils'
import useMediaQuery from '../../../hooks/useMediaQuery'

const HistoricSupplyStats = ({ colorPrimary, colorSecondary, dataType, historic, eco }) => {
  const lng = getActiveLanguage()
  const { t } = useTranslation()
  const { isMobile } = useMediaQuery()
  const { electricity, gas } = useSelector(state => state.auth.invoice || {})

  const [historyData, setHistoryData] = useState(null)
  const [currentYearActive, setCurrentYearActive] = useState(true)
  const [previousYearActive, setPreviousYearActive] = useState(true)
  /* eslint-disable */
  useEffect(() => {
    if (
      (dataType === 'light' && electricity != null && !isEmpty(historic)) ||
      (dataType === 'gas' && gas != null && !isEmpty(historic))
    ) {
      const selectedType = dataType === 'light' ? electricity : gas
      const data = parseData(historic, selectedType, activeYears, lng)
      setHistoryData(data)
    }
  }, [historic])

  if (!electricity?.to && !gas?.to) return null

  const currentYear =
    dataType === 'light' && electricity?.to
      ? format(new Date(electricity?.to), 'yyyy')
      : format(new Date(gas?.to), 'yyyy')
  const previousYear = currentYear - 1

  const activeYears = {
    currentYear: currentYearActive,
    previousYear: previousYearActive,
  }

  const handlerActiveYears = (type, state) => {
    if (type === 'currentYear') {
      setCurrentYearActive(state)
    } else {
      setPreviousYearActive(state)
    }
  }

  if (historyData?.every(item => item.currentYear === 0 && item.previousYear === 0)) {
    return (
      <S.HistoricSupplyStatsContainer>
        <Text>{t('Sin datos históricos los dos últimos años')}</Text>
      </S.HistoricSupplyStatsContainer>
    )
  }

  const formatter = value => value

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <Fragment>
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor='end' fill={theme.colors.blue.regular} transform='rotate(-90)'>
            {payload.value}
          </text>
        </g>
      </Fragment>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
          }}
        >
          <p>{upperCase(label)}</p>
          {payload.map((entry, index) => {
            let color = entry.color
            if (entry.color === 'url(#patternLight)') {
              color = '#99B5C6'
            }
            if (eco && entry.name === `${t('Consumo') + ' ' + previousYear}`) {
              color = '#BFDA8F'
            }
            return (
              <p key={index} style={{ color: color }}>
                {`${t(entry.name)}: ${entry.value}`}
              </p>
            )
          })}
        </div>
      )
    }
    return null
  }

  const payloadFormatter = payload => {
    return payload.toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
  }

  const showExcessLines =
    historyData &&
    (historyData.some(item => item.excessPrevious > 0) || historyData.some(item => item.excessCurrent > 0))

  return (
    <S.HistoricSupplyStatsContainer>
      <S.HistoricalPeriod>
        <S.YearPeriod
          data-cy={`historic-year-${previousYear}`}
          color={eco ? theme.colors.eco.dark : colorSecondary}
          onClick={() => handlerActiveYears('previousYear', !previousYearActive)}
          active={previousYearActive}
        >
          <Text fontType='bold' fontColor={theme.colors.white}>
            {previousYear}
          </Text>
          <S.YearActive color={eco ? theme.colors.eco.dark : colorSecondary} active={previousYearActive}>
            <Icon Source={CheckIcon} width='12px' height='12px' />
          </S.YearActive>
        </S.YearPeriod>
        <S.YearPeriod
          data-cy={`historic-year-${currentYear}`}
          color={eco ? theme.colors.eco.regular : colorPrimary}
          onClick={() => handlerActiveYears('currentYear', !currentYearActive)}
          active={currentYearActive}
        >
          <Text fontType='bold' fontColor={theme.colors.white}>
            {currentYear}
          </Text>
          <S.YearActive color={eco ? theme.colors.eco.regular : colorPrimary} active={currentYearActive}>
            <Icon Source={CheckIcon} width='12px' height='12px' />
          </S.YearActive>
        </S.YearPeriod>
      </S.HistoricalPeriod>
      <S.HistogramContainer>
        <ComposedChart
          data={historyData}
          margin={{
            top: 40,
            bottom: 40,
            right: 10,
            left: -7,
          }}
        >
          <defs>
            <pattern id='patternLight' patternUnits='userSpaceOnUse' width='2' height='2' patternTransform='rotate(45)'>
              <line x1='0' y1='0' x2='0' y2='4' stroke={theme.colors.blue.chartLight} strokeWidth='1' />
            </pattern>
            <pattern id='patternGas' patternUnits='userSpaceOnUse' width='2' height='2' patternTransform='rotate(45)'>
              <line x1='0' y1='0' x2='0' y2='4' stroke={theme.colors.orange.light} strokeWidth='1' />
            </pattern>
            <pattern id='patternEco' patternUnits='userSpaceOnUse' width='2' height='2' patternTransform='rotate(45)'>
              <line x1='0' y1='0' x2='0' y2='4' stroke={theme.colors.eco.regular} strokeWidth='1' />
            </pattern>
          </defs>
          <XAxis dataKey='month' tickLine={false} tick={<CustomizedAxisTick />} interval={0} />
          <YAxis
            type='number'
            axisLine={true}
            tickLine={false}
            label={{ value: 'kWh', position: 'top' }}
            interval={'preserveStartEnd'}
            tickFormatter={formatter}
          />
          <CartesianGrid horizontal vertical={false} stroke={theme.colors.gray.light} />
          <Tooltip
            contentStyle={{
              fontFamily: 'FS Emeric Light',
              color: eco ? theme.colors.eco.dark : colorSecondary,
            }}
            labelStyle={{
              textTransform: 'capitalize',
              color: theme.colors.blue.regular,
            }}
            cursor={{ fill: 'transparent' }}
            formatter={payloadFormatter}
            content={<CustomTooltip />}
          />
          {dataTypeObject[dataType].bars.map(bar => {
            if (activeYears[bar.key]) {
              const fillPattern = selectFillPattern({ bar, isMobile, eco, dataType })
              return (
                <Bar
                  key={v4()}
                  dataKey={bar.key}
                  maxBarSize={20}
                  radius={0}
                  fill={fillPattern}
                  name={
                    bar.key === 'currentYear'
                      ? `${t('Consumo') + ' ' + currentYear}`
                      : `${t('Consumo') + ' ' + previousYear}`
                  }
                />
              )
            }
          })}
          {showExcessLines && previousYearActive && (
            <Line
              type='monotone'
              dataKey='excessPrevious'
              stroke={eco ? theme.colors.blue.light : theme.colors.eco.line}
              strokeWidth={2}
              dot
              activeDot={{ r: 6 }}
              name={`${t('Excedente') + ' ' + previousYear}`}
            />
          )}
          {showExcessLines && currentYearActive && (
            <Line
              type='monotone'
              dataKey='excessCurrent'
              stroke={eco ? theme.colors.blue.regular : theme.colors.eco.regular}
              strokeWidth={2}
              dot
              activeDot={{ r: 6 }}
              name={`${t('Excedente') + ' ' + currentYear}`}
            />
          )}
          {showExcessLines && <Legend layout='horizontal' align='left' />}
        </ComposedChart>
      </S.HistogramContainer>
    </S.HistoricSupplyStatsContainer>
  )
}

HistoricSupplyStats.defaultProps = {
  historic: null,
  dataType: 'light',
  colorPrimary: 'blue',
  colorSecondary: 'orange',
  hideModuleHandler: () => {},
  eco: false,
}

HistoricSupplyStats.propTypes = {
  historic: PropTypes.object,
  dataType: PropTypes.string,
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string,
  hideModuleHandler: PropTypes.func,
  eco: PropTypes.bool,
  excess: PropTypes.object,
}
export default HistoricSupplyStats
