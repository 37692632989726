import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Text, Button, LinkRegular, Icon, H2 } from '~/components/atoms'
import { theme } from '~/styles/theme'
import { UserIcon, WelcomeIcon } from '~/assets/icons'
import { useMediaQuery } from '~/hooks'
import { getActiveLanguage, getDateByLocale } from '../../../config/utils'
import { NATURGY_ONLINE_PAYMENT_URL } from '../../../config/constants'

import * as S from './styled'

const UserData = () => {
  const { isMobile } = useMediaQuery()
  const { invoice, iframeMode } = useSelector(state => state.auth || {})
  const { billingData } = invoice || {}
  const { t } = useTranslation()

  const clientName = billingData?.customerName?.toLowerCase()
  const formatedFAEDN = getDateByLocale(billingData?.chargeDate, getActiveLanguage(), 'dd.MM.yyyy')
  const formatedBUDAT = getDateByLocale(billingData?.issueDate, getActiveLanguage(), 'dd.MM.yyyy')
  const formatedDUE = getDateByLocale(billingData?.paymentDetails?.dueDate, getActiveLanguage(), 'dd.MM.yyyy')
  const address = billingData?.address || ''

  /* prettier-ignore */
  return (
    <>
      <S.UserDataContainer data-cy='modulo-tus-datos' className='layout-wrapper'>
        <S.UserDataHeader>
          <H2>
            {t('Tus datos de facturación')}
          </H2>
        </S.UserDataHeader>
        <S.InfoWrapper>
          <div>
            <S.InfoContainer>
              <Icon
                Source={UserIcon}
                width='32px'
                height='32px'
                color={theme.colors.blue.light}
              />
              <S.InfoContent>
                <S.InfoItem>
                  <Text fontType='semibold'>
                    {t('Nombre')}<span> {clientName} </span>
                  </Text>
                </S.InfoItem>
                <S.InfoItem>
                  <Text fontType='semibold'>
                    {t('Dirección fiscal')}<span> {address} </span>
                  </Text>
                </S.InfoItem>
                {
                  billingData?.bankAccount && (
                    <S.InfoItem>
                      <Text fontType='semibold'>
                        {t('Datos bancarios')}<span> { billingData.bankAccount } </span>
                      </Text>
                    </S.InfoItem>
                  )
                }
              </S.InfoContent>
            </S.InfoContainer>
            <S.InfoContainer>
              <Icon
                Source={WelcomeIcon}
                width='32px'
                height='32px'
                color={theme.colors.blue.light}
              />
              <S.InfoContent>
                <S.InfoItem>
                  <Text fontType='semibold'>
                    {t('Nº de factura')}<span> {billingData?.invoiceNumber || '-'} </span>
                  </Text>
                </S.InfoItem>
                <S.InfoItem>
                  <Text fontType='semibold'>
                    {t('Fecha de emisión')}<span> {formatedBUDAT} </span>
                  </Text>
                </S.InfoItem>
                {
                  billingData?.bankAccount && formatedFAEDN && (
                    <S.InfoItem>
                      <Text fontType='semibold'>
                        {t('Fecha de cargo')}<span> {formatedFAEDN} </span>
                      </Text>
                    </S.InfoItem>
                  )
                }
                <S.InfoItem>
                  <Text fontType='semibold'>
                    {t('Nº de referencia')}<span> {billingData?.referenceNumber || '-'} </span>
                  </Text>
                </S.InfoItem>
              </S.InfoContent>
            </S.InfoContainer>
          </div>
          {
            (billingData?.paymentDetails?.debitOrder && !iframeMode) && (
              <S.InfoPay>
                <div className='pay-with-button'>
                  {
                    formatedDUE ? (
                      <S.PayBlock>
                        <Text fontSize={!isMobile ? '20px' : '16px'}>
                          <Trans values={{ formatedBUDAT: formatedDUE }}>
                            Efectúa el pago antes del <span>{{ formatedBUDAT }}</span> a través de este enlace:
                          </Trans>
                        </Text>
                        <LinkRegular externalPath={NATURGY_ONLINE_PAYMENT_URL}>
                          <Button classBtn='orange' label={t('Paga aquí tu factura')} />
                        </LinkRegular>
                      </S.PayBlock>
                    ) : (
                      <Text>
                        {t('Contáctanos a través de nuestro teléfono de Atención Cliente para hacer efectivo el ingreso del importe de esta factura')}
                      </Text>
                    )
                  }
                </div>
              </S.InfoPay>
            )
          }
        </S.InfoWrapper>
      </S.UserDataContainer>
    </>
  )
  /* prettier-ignore */
}

export default UserData
