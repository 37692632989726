import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { theme } from '~/styles/theme'

/**
  Uso:

  <H4
    fontColor='#ffffff' // color de fuente (negro por defecto)
    fontType='medium' // estilo de fuente ['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold'] (light por defecto)
  >
    Lorem ipsum
  </H4>
*/

const H4 = ({ children, ...props }) => <S.H4 {...props}>{children}</S.H4>

H4.defaultProps = {
  children: null,
  fontType: 'regular',
  fontColor: theme.colors.blue.regular,
}

H4.propTypes = {
  children: PropTypes.node,
  fontType: PropTypes.oneOf(['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold']),
  fontColor: PropTypes.string,
}

export default H4
