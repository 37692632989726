import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { addYears } from 'date-fns'

import { theme } from '~/styles/theme'
import View from './view'
import { getActiveLanguage, getDateByLocale } from '~/config/utils'
import { formatNumber, getTppDateComponents } from './utils'

const ConsumptionCard = ({ value: data, detail, ...props }) => {
  const { t } = useTranslation()
  const lng = getActiveLanguage()

  if (!data) return null

  const { budat, closeToLimit, exceededLimit } = detail || {}

  const { type, unit, value, actual, limit, dates, color, productName, tpp, dateStart } = data
  const percent = (value * 100) / limit || 0

  const formatedContractStart = dates ? getDateByLocale(dates.params.fecha, lng, 'dd.MM.yyyy') : null
  const formatedContractEnd = dates ? getDateByLocale(addYears(dates.params.fecha, 1), lng, 'dd.MM.yyyy') : null
  const cardColor = exceededLimit ? theme.colors.red.regular : color
  const valueFormated = formatNumber(value)
  const limitFormated = formatNumber(limit)
  const actualFormated = formatNumber(actual)
  const percentFormated = formatNumber(percent)

  const [dayBudat, monthBudat, yearBudat] = getTppDateComponents(budat, lng, t)
  const [dayFact, monthFact, yearFact] = getTppDateComponents(dateStart, lng, t)
  const matched = type === t('electricidad')

  const tddAndBudat = tpp && budat && dateStart
  const formatedContract = formatedContractStart && formatedContractEnd

  const viewProps = {
    closeToLimit,
    unit,
    productName,
    cardColor,
    valueFormated,
    limitFormated,
    actualFormated,
    percentFormated,
    dayBudat,
    monthBudat,
    yearBudat,
    dayFact,
    monthFact,
    yearFact,
    matched,
    tddAndBudat,
    formatedContract,
    type,
    limit,
    exceededLimit,
    percent,
    formatedContractStart,
    formatedContractEnd,
  }

  return <View {...props} {...viewProps} />
}

ConsumptionCard.defaultProps = {
  value: {},
  detail: {},
}

ConsumptionCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  detail: PropTypes.objectOf(PropTypes.any),
}

export default ConsumptionCard
