import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const EcoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 15px 0;
  margin: 50px 0 0;
  position: relative;
  border-top: 1px solid ${theme.colors.green.chart};

  > img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -50px;
  }

  > p {
    text-align: center;

    > span {
      font-size: 8px;
    }
  }
`
