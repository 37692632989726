import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const UserDataContainer = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`

export const UserDataHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    max-width: 720px;
    width: 100%;
    margin: auto;
    display: flex;
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    > div {
      flex-direction: column;
    }
  }
`

export const InfoContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
    padding: 0;
  }
`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`

export const InfoPay = styled.div`
  width: 100%;
  max-width: 720px;
  padding-top: 20px;
  margin: auto;
  border-top: 0.5px solid ${theme.colors.separator};

  .pay-with-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
      width: 100%;
      > span {
        font-family: FS Emeric Bold;
      }
    }

    > a {
      > div {
        display: flex;
        justify-content: center;
      }
    }

    @media (max-width: ${theme.breakpoints.mobile}px) {
      flex-direction: column;

      > p {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
  }
`

export const InfoItem = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  > p {
    text-align: center;
    > span {
      text-transform: capitalize;
      font-family: 'FS Emeric Light';
      margin-left: 5px;
    }
  }
`

export const PayBlock = styled.div`
  padding-top: 20px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;

  > p {
    margin-bottom: 20px;
    text-align: center;
  }
`
