const getAvailableProducts = (productType, electricity, gas, services) =>
  [electricity && productType.electricity, gas && productType.gas, services && productType.services].filter(Boolean)
export const getProductSummary = (productType, electricity, gas, services) =>
  getAvailableProducts(productType, electricity, gas, services)

const formatProductSummaryText = (translateText, t) => {
  if (translateText.length > 1) {
    return `${translateText.slice(0, -1).join(', ')} ${t('y')} ${translateText.slice(-1)}`
  }
  return translateText.join('')
}

export const getProductSummaryText = (productType, electricity, gas, services, t) => {
  const availableProducts = getAvailableProducts(productType, electricity, gas, services)
  const translateText = availableProducts.map(product => t(product))
  return formatProductSummaryText(translateText, t)
}
