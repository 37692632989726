import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LinkRegular } from '~/components/atoms'
import { AC_URL } from '~/config/constants'
import * as S from './styled'

const BatteryWidget = () => {
  const { t } = useTranslation()
  const { invoice } = useSelector(state => state.auth || {})
  const battery = invoice?.battery
  const showBattery = battery?.showBattery

  if (!showBattery) return null
  return (
    <S.Container>
      <S.Title>{t('Batería virtual')}</S.Title>
      {battery?.invoice_amount && parseFloat(battery?.invoice_amount) > 0.0 && (
        <S.Paragraph>
          {t('Esta factura ha cargado en tu Batería Virtual {{value}}€', { value: battery?.invoice_amount })}
        </S.Paragraph>
      )}
      {battery?.invoice_discharge && parseFloat(battery?.invoice_discharge) < 0.0 && (
        <S.Paragraph>
          {t('Esta factura ha descargado de tu batería {{value}}€', { value: battery?.invoice_discharge })}
        </S.Paragraph>
      )}

      <S.Paragraph>{t('Tienes disponible {{value}}€', { value: battery?.current_total || 0.0 })}</S.Paragraph>

      <S.Paragraph>
        {t('Para ver los movimientos de tu cuenta y el saldo disponible en tu Batería Virtual, consulta tu')}
        <LinkRegular externalPath={AC_URL}>
          <S.Link>{t('Área clientes')}</S.Link>
        </LinkRegular>
      </S.Paragraph>
    </S.Container>
  )
}

BatteryWidget.defaultProps = { isRdi: false }

BatteryWidget.propTypes = {}

export default BatteryWidget
