import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

/**
  Uso:

  <PercentBar
    label={`${priceFormat(percent)}%`} // Valor en string del %
    value={percent} // Valor numérico del %
    color='#FFFFFF' //Color del texto (Blanco por defecto)
  />
*/

const PercentBar = ({ label, value, color, ...props }) => {
  const percent = value > 100 ? 100 : value
  return (
    <S.PercentBarContainer {...props}>
      <S.BarWrapper>
        {label && <S.Label fontType='semibold'>{label}</S.Label>}
        <S.PercentBar value={percent} color={color} />
      </S.BarWrapper>
    </S.PercentBarContainer>
  )
}

PercentBar.defaultProps = {
  label: '',
  value: 0,
  color: '#FFFFFF',
}

PercentBar.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  color: PropTypes.string,
}

export default PercentBar
