import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import Icon from '../Icon'

/**
  Uso:

  const handleButtonClick = () =>{
    console.log('click')
  }

  <Button
    label={'Lorem ipsum'} // texto a mostrar
    classBtn={'back'} // tipo ['default','secondary','terciary','light', 'icon', 'orange'] (por defecto default)
    handleClick={() => handleButtonClick()} // función al hacer click
    icon={BackIcon} // Source del icono (por defecto undefined)
    isDisabled={true} // Botón deshabilitado
  />
 */

const Button = ({ label, classBtn, handleClick, icon, isDisabled, width, ...rest }) => (
  <S.ButtonContainer {...rest} isDisabled={isDisabled} width={width} icon={icon} label={label}>
    <button
      className={classBtn ? `btn btn-${classBtn}` : 'btn btn-default'}
      onClick={!isDisabled ? handleClick : null}
      type='button'
    >
      {label && <p>{label}</p>}
      {icon && <Icon Source={icon} width='14px' height='18px' />}
    </button>
  </S.ButtonContainer>
)

Button.defaultProps = {
  label: '',
  classBtn: 'default',
  handleClick: () => {},
  icon: null,
  isDisabled: false,
  width: null,
}

Button.propTypes = {
  label: PropTypes.string,
  classBtn: PropTypes.oneOf(['default', 'secondary', 'terciary', 'light', 'icon', 'orange']),
  handleClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
}

export default Button
