import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as S from './styled'
import { theme } from '~/styles/theme'
import { Text } from '~/components/atoms'
import { priceFormat } from '~/config/utils'

const InvoiceSummary = () => {
  const { t } = useTranslation()
  const { invoice } = useSelector(state => state.auth || {})
  const total = (invoice?.amountSummary && parseFloat(invoice?.amountSummary?.total)) || 0
  const { gas, electricity } = invoice || {}
  const { eco: ecoGas } = gas || {}
  const { eco: ecoElectricity } = electricity || {}

  const ecoResult = () => ecoElectricity || ecoGas
  const ecoCheck = ecoResult(ecoElectricity, ecoGas)

  return (
    <S.InvoiceSummaryContainer>
      <Text fontType='semibold' fontSize='18px' fontLineHeight='28px'>
        {total <= 0 ? t('Importe total') : t('Total a pagar')}
      </Text>
      <S.Price
        data-cy='importe'
        fontType='semibold'
        fontColor={ecoCheck ? theme.colors.eco.regular : theme.colors.orange.regular}
      >
        {priceFormat(total, 2, 2, true)}
      </S.Price>
    </S.InvoiceSummaryContainer>
  )
}

export default InvoiceSummary
