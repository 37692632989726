import React, { useEffect, useState } from 'react'

import { useTranslation, Trans } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { theme } from '~/styles/theme'
import { H2 } from '~/components/atoms'
import { TabPanel, HistoricSupplyStats, ConsumptionCard } from '~/components/molecules'
import { GasIcon, EleIcon } from '~/assets/icons'
import { getActiveLanguage, getProductNameBySlug } from '~/config/utils'

import * as S from './styled'

import { SIEBEL_SLUGS, PRODUCT_TYPE_NEWCO } from '~/config/constants'

import { getLightHistoric, getGasHistoric } from '~/store/modules/historic/actions'
import { ELEC } from '../../../config/constants'

const CombinedConsumption = () => {
  const lng = getActiveLanguage()
  const { t } = useTranslation()
  const { invoice } = useSelector(state => state.auth || {})
  const gasHistoricNewCo = invoice?.barChart?.gas || null
  const lightHistoricNewCo = invoice?.barChart?.electricity || null
  const lightType = invoice?.electricity?.type.toLowerCase() || ''
  const gasType = invoice?.gas?.type.toLowerCase() || ''
  const { gas, electricity, notifications } = useSelector(state => state.auth.invoice || {})
  const { token, qc, factnum } = useSelector(state => state.auth || {})
  const { lightHistoric, gasHistoric } = useSelector(state => state.historic || {})
  const history = useHistory()
  const dispatch = useDispatch()

  const { consumption } = invoice || {}

  const { gas: gasConsumtion, electricity: eleConsumption } = consumption || {}
  const { gas: gasInvoice, electricity: eleInvoice } = invoice || {}
  const { eco: ecoGas } = gasInvoice || {}
  const { eco: ecoElectricity } = eleInvoice || {}

  const [tabList, setTabList] = useState([])
  const coincidences = ['motivos-ajuste', 'fraude-averia', 'motivos-anulacion', 'motivos-anulacion-2']
  const withAdvice = notifications?.some(advice => coincidences.includes(advice.slug))

  useEffect(() => {
    dispatch(getLightHistoric({ token, history, qc, factnum }))
    dispatch(getGasHistoric({ token, history, qc, factnum }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const list = []
    const isSU = invoice?.billingData?.invoiceNumber.includes('SU')

    if (!isSU && eleConsumption?.current) {
      const consumptionElectricidad = {
        type: t('electricidad'),
        actual: eleConsumption.current.value,
        value: eleConsumption.total?.value || 0,
        unit: eleConsumption.current.unit,
        limit: eleConsumption.limit?.value,
        dates: eleConsumption.dates,
        color: ecoElectricity ? theme.colors.green.chart : theme.colors.blue.regular,
        tpp: eleConsumption.tpp,
        productName: getProductNameBySlug(invoice.electricity, t, lng, Trans),
        dateStart: eleConsumption.fechfi,
      }

      list.push({
        index: 0,
        key: t('Electricidad'),
        color: theme.colors.blue.dark,
        colorSecondary: theme.colors.blue.light,
        activeColor: ecoElectricity ? theme.colors.green.chart : theme.colors.blue.regular,
        icon: EleIcon,
        component: <ConsumptionCard value={consumptionElectricidad} detail={eleConsumption} />,
        eco: ecoElectricity,
      })
    }

    if (!isSU && gasConsumtion?.current) {
      const consumptionGas = {
        type: t('gas'),
        actual: gasConsumtion.current.value,
        value: gasConsumtion.total?.value || 0,
        unit: gasConsumtion.total.unit,
        limit: gasConsumtion.limit?.value,
        dates: gasConsumtion.dates,
        color: ecoGas ? theme.colors.green.chart : theme.colors.orange.regular,
        tpp: gasConsumtion.tpp,
        productName: getProductNameBySlug(invoice.gas, t, lng, Trans),
        dateStart: gasConsumtion.fechfi,
      }

      list.push({
        index: 1,
        key: t('Gas'),
        color: theme.colors.orange.regular,
        colorSecondary: theme.colors.orange.light,
        activeColor: ecoGas ? theme.colors.green.chart : theme.colors.orange.regular,
        icon: GasIcon,
        component: <ConsumptionCard value={consumptionGas} detail={gasConsumtion} />,
        eco: ecoGas,
      })
    }

    if (!isEmpty(gasHistoric) || !isEmpty(lightHistoric)) {
      const displayEle = electricity?.type === SIEBEL_SLUGS.ESTABLE
      const displayGas = gas?.type === SIEBEL_SLUGS.ESTABLE
      if (displayEle) {
        list.push({
          index: 0,
          key: t('Electricidad'),
          color: theme.colors.blue.dark,
          colorSecondary: theme.colors.blue.light,
          activeColor: electricity?.eco ? theme.colors.green.chart : theme.colors.blue.regular,
          icon: EleIcon,
          component: (
            <HistoricSupplyStats
              eco={electricity?.eco}
              dataType='light'
              colorPrimary={theme.colors.blue.dark}
              colorSecondary={theme.colors.blue.light}
              historic={lightHistoric}
            />
          ),
          eco: electricity?.eco,
        })
      }

      if (displayGas) {
        list.push({
          index: 1,
          key: t('Gas'),
          color: theme.colors.orange.regular,
          colorSecondary: theme.colors.orange.light,
          activeColor: gas?.eco ? theme.colors.green.chart : theme.colors.orange.regular,
          icon: GasIcon,
          component: (
            <HistoricSupplyStats
              eco={gas?.eco}
              dataType='gas'
              colorPrimary={theme.colors.orange.regular}
              colorSecondary={theme.colors.orange.light}
              historic={gasHistoric}
            />
          ),
          eco: gas?.eco,
        })
      }
    }

    if (
      !isEmpty(lightHistoricNewCo?.values) &&
      !(lightType === PRODUCT_TYPE_NEWCO.PLANA || lightType === PRODUCT_TYPE_NEWCO.PLANA_RD)
    ) {
      list.push({
        index: 0,
        key: t('Electricidad'),
        color: theme.colors.blue.regular,
        colorSecondary: theme.colors.blue.light,
        activeColor: invoice?.gas?.eco ? theme.colors.green.chart : theme.colors.blue.regular,
        icon: EleIcon,
        component: (
          <HistoricSupplyStats
            eco={lightHistoricNewCo.eco}
            dataType='light'
            colorPrimary={theme.colors.blue.regular}
            colorSecondary={theme.colors.blue.light}
            historic={lightHistoricNewCo.values}
          />
        ),
        eco: invoice?.electricity?.eco,
      })
    }

    if (
      !isEmpty(gasHistoricNewCo?.values) &&
      !(gasType === PRODUCT_TYPE_NEWCO.PLANA || gasType === PRODUCT_TYPE_NEWCO.PLANA_RD)
    ) {
      list.push({
        index: 1,
        key: t('Gas'),
        color: theme.colors.orange.dark,
        colorSecondary: theme.colors.orange.light,
        activeColor: invoice?.gas?.eco ? theme.colors.green.chart : theme.colors.orange.regular,
        icon: GasIcon,
        component: (
          <HistoricSupplyStats
            eco={gasHistoricNewCo?.eco}
            dataType='gas'
            colorPrimary={theme.colors.orange.regular}
            colorSecondary={theme.colors.orange.light}
            historic={gasHistoricNewCo.values}
          />
        ),
        eco: invoice?.gas?.eco,
      })
    }

    const orderedList = list.sort((a, b) => {
      if (a.key === ELEC) return -1
      if (b.key === ELEC) return 1
      return 0
    })

    setTabList(orderedList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electricity, gas, t, gasHistoric, lightHistoric])

  if (withAdvice) return null
  if (tabList.length < 1) return null
  if (isEmpty(gasHistoric) && isEmpty(lightHistoric)) return null

  return (
    <S.HistoricalContainer data-cy='historical' className='layout-wrapper'>
      <S.HistoricalHeader>
        <H2>{t('Conoce tu evolución de consumo')}</H2>
      </S.HistoricalHeader>
      <TabPanel list={tabList} />
    </S.HistoricalContainer>
  )
}

export default CombinedConsumption
