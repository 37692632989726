export const Cookies = (window, document, s = 'script', l = 'dataLayer', i = 'GTM-THQDBWX') => {
  window[l] = window[l] || []
  window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const f = document.getElementsByTagName(s)[0]
  const j = document.createElement(s)
  const dl = l !== 'dataLayer' ? `&l=${l}` : ''
  j.async = true
  j.src = `//www.googletagmanager.com/gtm.js?id=${i}${dl}`
  f.parentNode.insertBefore(j, f)
}
