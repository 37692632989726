import { getActiveLanguage, getDateByLocale } from '~/config/utils'

export const formatNumber = value =>
  // eslint-disable-next-line implicit-arrow-linebreak
  parseFloat(value, 0).toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

export const getTppDateComponents = (date, lng, t) => {
  const isAltCtTppDate = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    ['04', '08', '10'].some(o => o === getDateByLocale(new Date(date), lng, 'MM')) && lng === 'ct'

  const day = getDateByLocale(date, getActiveLanguage(), 'dd')
  const year = getDateByLocale(date, getActiveLanguage(), 'yyyy')

  let month = getDateByLocale(date, getActiveLanguage(), 'MMMM')
  if (isAltCtTppDate()) {
    month = `d'${month}`
  } else {
    month = t('de {{month}}', { month })
  }

  return [day, month, year]
}
