import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { theme } from '~/styles/theme'

/**
  Uso:

  <H5
    fontColor='#ffffff' // color de fuente (negro por defecto)
    fontType='medium' // estilo de fuente ['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold'] (light por defecto)
  >
    Lorem ipsum
  </H5>
*/

const H5 = ({ children, ...props }) => <S.H5 {...props}>{children}</S.H5>

H5.defaultProps = {
  children: null,
  fontType: 'regular',
  fontColor: theme.colors.blue.regular,
}

H5.propTypes = {
  children: PropTypes.node,
  fontType: PropTypes.oneOf(['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold']),
  fontColor: PropTypes.string,
}

export default H5
