import React from 'react'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import * as S from './styled'
import { LogoWhite } from '~/assets/images/'
import { UserOptions } from '~/components/molecules'
import { useMediaQuery } from '~/hooks'

const Menu = ({ offline }) => {
  const { isMobile } = useMediaQuery()
  const { iframeMode } = useSelector(state => state.auth)

  if (iframeMode) return null

  return (
    <S.MenuContainer isMobile={isMobile}>
      <S.LogoContainer>
        <img src={LogoWhite} alt='' />
      </S.LogoContainer>
      {!offline && !isMobile && <UserOptions />}
    </S.MenuContainer>
  )
}

Menu.defaultProps = { offline: false }
Menu.propTypes = { offline: PropTypes.bool }

export default Menu
