import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { H2 } from '~/components/atoms'

export const InvoiceSummaryContainer = styled.div`
  width: 100%;
  height: 95px;
  background-color: ${theme.colors.gray.light};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;

  margin-bottom: 5px;

  > p {
    margin-top: 20px;
  }
  > h1 {
    margin-top: 20px;
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    padding: 15px;
  }
`

export const ImportLabel = styled(H2)`
  margin-bottom: 8px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    align-self: start;
    font-size: 24px;
  }
`

export const Price = styled(H2)`
  margin-top: 20px;

  span {
    font-size: 14px;
  }
`
