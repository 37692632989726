import { Types } from './constants'

export const getLightHistoric = payload => ({
  type: Types.GET_lightHistoric,
  payload,
})

export const setLightHistoric = payload => ({
  type: Types.SET_lightHistoric,
  payload,
})

export const getGasHistoric = payload => ({
  type: Types.GET_gasHistoric,
  payload,
})

export const setGasHistoric = payload => ({
  type: Types.SET_gasHistoric,
  payload,
})
