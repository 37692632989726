import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const FinancingContainer = styled.div`
  background-color: transparent;
  width: ${({ count }) => (count < 2 ? '600px' : '300px')};
  padding: 0 25px;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
    padding: 35px !important;
  }
`
export const FinancingTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  > .icon-container {
    margin-bottom: 15px;
  }
`

export const List = styled.div``

export const FinancingDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  margin: auto;
  margin-bottom: 20px;

  > h3 {
    > span {
      font-size: 14px;
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    max-width: 100%;
  }
`

export const FinancingTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  margin: auto;
  margin-bottom: 20px;

  > h3 {
    > span {
      font-size: 14px;
    }
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    max-width: 100%;
  }
`
