import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import { Icon } from '~/components/atoms'
import * as S from './styled'
import { theme } from '~/styles/theme'
import { EcoIcon } from '~/assets/icons'
import { setActiveTabIndex } from '../../../store/modules/tabs/actions'

const TabPanel = ({ list }) => {
  const dispatch = useDispatch()
  const active = useSelector(state => state.tabs.activeTabIndex)
  const isIndividual = list.length < 2
  const [localActive, setLocalActive] = useState(active)

  useEffect(() => {
    if (active === 2) {
      setLocalActive(0)
    } else {
      setLocalActive(active)
    }
    if (isIndividual) {
      setLocalActive(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, localActive])

  return (
    <S.TabPanelContainer>
      <S.TabPanelHeader>
        {list.map(item => (
          <S.TabHeader
            key={v4()}
            active={isIndividual ? localActive === 0 : item.index === localActive}
            onClick={() => dispatch(setActiveTabIndex(item.index))}
            color={item.eco ? theme.colors.eco.regular : theme.colors.blue.regular}
            borderColor={item.eco ? theme.colors.eco.regular : theme.colors.blue.regular}
            disabled={isIndividual}
          >
            <S.ButtonIconContainer>
              <S.ButtonIcon>
                <Icon
                  Source={item.icon}
                  color={item.eco ? theme.colors.eco.regular : theme.colors.blue.regular}
                  width='18px'
                  height='26px'
                />
                {item.key}
              </S.ButtonIcon>
              {item.eco && (
                <S.EcoItem isActive={localActive === item.index}>
                  <Icon className='eco-icon' Source={EcoIcon} width='20px' height='20px' color='transparent' />
                </S.EcoItem>
              )}
            </S.ButtonIconContainer>
          </S.TabHeader>
        ))}
      </S.TabPanelHeader>
      <S.TabPanelContent
        active={localActive}
        contentWidth={list.length * 100}
        count={list.length}
        color={list[localActive]?.activeColor}
        borderColor={list[localActive]?.eco ? theme.colors.eco.regular : theme.colors.blue.regular}
      >
        <div>
          <div>
            {list.map(item => (
              <S.TabContainer key={v4()} className='tab-panel-container'>
                {item.component}
              </S.TabContainer>
            ))}
          </div>
        </div>
      </S.TabPanelContent>
    </S.TabPanelContainer>
  )
}

TabPanel.defaultProps = { list: [] }

TabPanel.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      eco: PropTypes.bool,
      activeColor: PropTypes.string,
    })
  ),
}

export default TabPanel
