import styled from 'styled-components/macro'
import { ResponsiveContainer } from 'recharts'
import { theme } from '~/styles/theme'

export const HistoricSupplyStatsContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .recharts-text {
    font-family: 'FS Emeric Light';
    fill: ${theme.colors.black};
  }

  .recharts-yAxis {
    transform: translateY(10px) translateX(-10px);
    .recharts-cartesian-axis-line {
      stroke: ${theme.colors.blue.dark};
    }

    .recharts-label {
      transform: translateY(-15px) translateX(5px);
      tspan {
        font-weight: bold;
        font-size: 12px;
        fill: ${theme.colors.blue.regular};
      }
    }

    tspan {
      font-size: 12px;
      font-weight: bold;
      fill: ${theme.colors.blue.regular};
    }
  }

  .recharts-xAxis {
    transform: translateY(10px) translateX(-10px);

    .recharts-cartesian-axis-line {
      stroke: ${theme.colors.blue.dark};
    }

    .recharts-cartesian-axis-ticks {
      transform: translateY(10px);

      text {
        font-family: 'FS Emeric Light';
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }
`

export const HistogramContainer = styled(ResponsiveContainer)`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .recharts-wrapper {
    font-size: 14px;
    font-weight: 600;
    font-family: 'FS Emeric Bold';
    color: #004571;

    > .recharts-surface {
      width: 100% !important;
    }
    .recharts-default-legend {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      max-width: 350px;
      width: fit-content;
      margin: 50px 0px -40px 30px !important;
    }
    .recharts-default-legend li:nth-child(1) {
      order: 2;
    }
    .recharts-default-legend li:nth-child(3) {
      order: 2;
    }
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    min-height: 480px;
  }
`

export const HistoricalPeriod = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  left: calc(50% - 100px);
`
export const YearPeriod = styled.div`
  width: 90px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 26px;
  position: relative;
  cursor: pointer;
  padding-top: 4px;
  color: ${theme.colors.white};
  font-family: 'FS Emeric Bold';

  > p {
    transition: 0.25s ease-in-out;
    transform: ${({ active }) => (active ? 'translateX(0)' : 'translateX(10px)')};
  }

  > div {
    transition: 0.25s ease-in-out;
    transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0)')};
  }
`

export const YearActive = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 26px;
  background-color: ${theme.colors.gray.light};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -2px;
  left: 4px;

  .icon {
    fill: ${({ color }) => color};
  }
`
