import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const ButtonContainer = styled.div`
  .btn {
    width: ${({ width }) => width ?? 'auto'};
    height: 55px;
    align-items: center;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: ${({ icon }) => (icon ? 'space-between' : 'center')};
    margin: 0;
    padding: 16px 25px;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ isDisabled }) => (isDisabled ? '0.3' : '1')};

    p {
      font-family: FS Emeric Medium;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 17px;
    }

    .icon-container {
      ${({ icon, label }) => icon && label && 'margin-left:12px;'}
    }

    &-default {
      background-color: ${theme.colors.blue.regular};

      p {
        color: ${theme.colors.white};
      }
    }

    &-secondary {
      background-color: transparent;
      border: 2px solid ${theme.colors.blue.regular};

      p {
        color: ${theme.colors.blue.regular};
      }

      .icon {
        fill: ${theme.colors.blue.regular};
      }
    }

    &-terciary {
      background-color: ${theme.colors.sky.regular};

      p {
        color: ${theme.colors.white};
      }

      .icon {
        fill: ${theme.colors.white};
      }
    }

    &-orange {
      background-color: ${theme.colors.orange.regular};
      border-radius: 25px;
      min-width: 335px;

      p {
        color: ${theme.colors.white};
        font-family: FS Emeric SemiBold;
        font-size: 21px;
        line-height: 23px;
      }

      .icon {
        fill: ${theme.colors.white};
      }

      @media (max-width: ${theme.breakpoints.mobile}px) {
        padding: 10px;
        height: auto;

        p {
          font-size: 20px;
          line-height: 22px;
        }
      }
    }

    &-light {
      background-color: transparent;

      p {
        color: ${theme.colors.sky.regular};
      }

      .icon {
        fill: ${theme.colors.sky.regular};
      }
    }

    &-icon {
      background-color: ${theme.colors.blue.regular};
      height: 28px;
      width: 28px;
      padding: 8px;
      border-radius: 50%;

      .icon {
        fill: ${theme.colors.white};
      }
    }
  }
`
