import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import qs from 'qs'
import * as S from './styled'
import { Contact, Footer } from '~/components/organisms'
import { setIframeMode } from '~/store/modules/auth/actions'

const UnderConstruction = ({ location }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  document.title = t('Resumen factura')

  const iframe = location.search.includes('iframe=')
    ? qs.parse(location.search.substring(1, location.search.length)).iframe
    : null

  if (iframe) {
    dispatch(setIframeMode(iframe))
  }

  return (
    <S.UnderConstructionPageContainer>
      <S.UnderConstructionContainer className='layout-wrapper'>
        <S.UnderConstructionPageComponent />
      </S.UnderConstructionContainer>
      {!iframe && (
        <>
          <Contact />
          <Footer />
        </>
      )}
    </S.UnderConstructionPageContainer>
  )
}

UnderConstruction.defaultProps = { location: {} }

UnderConstruction.propTypes = { location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]) }
export default UnderConstruction
