import { combineReducers } from 'redux'

import auth from './auth/reducer'
import historic from './historic/reducer'
import tabs from './tabs/reducer'

export default combineReducers({
  auth,
  historic,
  tabs,
})
