import styled from 'styled-components/macro'
import { UnderConstruction } from '~/components/organisms'

export const UnderConstructionPageContainer = styled.div`
  .layout-wrapper {
    max-width: 1280px;
    padding: 0 15px;
    width: 100%;
    margin: auto;
  }
`

export const UnderConstructionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const UnderConstructionPageComponent = styled(UnderConstruction)``
