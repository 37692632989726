import React, { createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Menu, Spinner } from '~/components/organisms'
import useRefScrollDimensions from '~/hooks/useRefScrollDimensions'
import { postIframeHeightEvent } from '~/config/utils'

import * as S from './styled'

export default function DefaultLayout({ children }) {
  const ref = createRef()
  const { height, width } = useRefScrollDimensions(ref)

  const { iframeMode, isFetching } = useSelector(state => state.auth)

  useEffect(() => {
    if (iframeMode) postIframeHeightEvent(isFetching)
  }, [height, width, iframeMode, isFetching])

  return (
    <S.DefaultLayoutWrapper id='default-layout-container' ref={ref}>
      <>
        {isFetching && <Spinner />}
        <Menu offline />
        <S.ContentContainer>
          <S.ContentWrapper>
            <S.RouteContainer>{children}</S.RouteContainer>
          </S.ContentWrapper>
        </S.ContentContainer>
        {iframeMode && <div id='default-layout-last-item' />}
      </>
    </S.DefaultLayoutWrapper>
  )
}

DefaultLayout.propTypes = { children: PropTypes.element.isRequired }
