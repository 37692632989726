import React from 'react'
import { v4 } from 'uuid'
import { theme } from '~/styles/theme'

import { GasSummary, LightSummary, ServiceSummary } from '~/components/molecules'

export const parseSummaries = (data, summariesCount) => [
  {
    component: <LightSummary key={v4()} count={summariesCount} />,
    data: data?.electricity || null,
  },
  {
    component: <GasSummary key={v4()} count={summariesCount} />,
    data: data?.gas || null,
  },
  {
    component: <ServiceSummary key={v4()} count={summariesCount} />,
    data: data?.services?.total != null && data?.services?.data?.length > 0 ? data?.services?.data : null,
  },
]

export const findMatchingTabs = (summaries, tabs) => tabs.filter(tab => summaries.includes(tab.id))

export const getBorderColor = (id, isActive, isEco) => {
  if (isActive && isEco) {
    return theme.colors.eco.regular
  }
  if (!isActive && isEco) {
    return theme.colors.eco.inactive
  }
  if (id === 'electricidad' && isActive && !isEco) {
    return theme.colors.blue.regular
  }
  if (id === 'electricidad' && !isActive && !isEco) {
    return theme.colors.blue.light
  }
  if ((id === 'gas' || 'servicios') && isActive && !isEco) {
    return theme.colors.blue.regular
  }
  if ((id === 'gas' || 'servicios') && !isActive && !isEco) {
    return theme.colors.blue.light
  }
  return null
}

export const getColor = (id, isActive, isEco) => {
  if (isActive && isEco) {
    return theme.colors.eco.regular
  }
  if (!isActive && isEco) {
    return theme.colors.eco.inactive
  }
  if (id === 'electricidad' && isActive && !isEco) {
    return theme.colors.blue.regular
  }
  if (id === 'electricidad' && !isActive && !isEco) {
    return theme.colors.blue.light
  }
  if ((id === 'gas' || 'servicios') && isActive && !isEco) {
    return theme.colors.blue.regular
  }
  if ((id === 'gas' || 'servicios') && !isActive && !isEco) {
    return theme.colors.blue.light
  }
  return null
}
