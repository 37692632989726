import styled from 'styled-components/macro'

export const IconContainer = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  svg {
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    fill: ${({ color }) => color};
    stroke: ${({ stroke }) => stroke};
  }
`
