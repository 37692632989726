import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { H4, Text } from '~/components/atoms'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const Paragraph = styled(Text)`
  text-align: center;
`

export const Title = styled(H4)``

export const Link = styled.span`
  font-weight: bold;
  text-decoration: underline;
  color: ${theme.colors.blue.regular};
  margin: 0px 5px;
`
