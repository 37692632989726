import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Cell, Sector } from 'recharts'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { theme } from '~/styles/theme'
import { CHART_TYPE, SIEBEL_SLUGS } from '~/config/constants'
import { Text, H3 } from '~/components/atoms'

import * as S from './styled'

import { getCustomizedLabelValues, getData } from './utils'

const SummaryPieChart = ({ detailChart, detail, type, eco }) => {
  const { t } = useTranslation()

  const { taxes } = useSelector(state => state.auth || {})

  const { costesRegulados, energia, impuestos } = getData({ detailChart, detail, type, t, taxes, eco })
  const [activeIndex, setActiveIndex] = useState(impuestos.value > 0 ? 2 : 0)

  const siebel_slug = detail.type
  const { chartType } = detailChart

  // 0: 'Conceptos Regulados de tu energía '
  // 1: 'Tu energía'
  // 2: 'Impuestos'
  const data = []
  if (costesRegulados.value > 0) data.push(costesRegulados)
  data.push(energia)
  if (impuestos.value > 0) data.push(impuestos)

  return (
    <S.SummaryPieChartContainer>
      <Chart
        data={data}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        disabled={chartType === CHART_TYPE.PERCENTAGE}
      />
      {chartType !== CHART_TYPE.PERCENTAGE && activeIndex >= 0 && data[activeIndex]?.legend && (
        <Leyend dataLegend={data[activeIndex]?.legend} />
      )}
      {(siebel_slug === SIEBEL_SLUGS.PLANA || chartType === CHART_TYPE.PERCENTAGE) && (
        <S.Info>
          <Trans>Importes calculados para un consumo medio</Trans>
        </S.Info>
      )}
    </S.SummaryPieChartContainer>
  )
}

SummaryPieChart.propTypes = {
  type: PropTypes.oneOf(['light', 'gas']).isRequired,
  eco: PropTypes.bool,
  detail: PropTypes.shape({
    type: PropTypes.string,
    product: PropTypes.shape({ eco: PropTypes.bool }),
    iselectricidad: PropTypes.number,
    isgas: PropTypes.number,
    peaje_elec: PropTypes.number,
    peaje_gas: PropTypes.number,
    acontador: PropTypes.number,
    operaciones: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        importe: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  detailChart: PropTypes.shape({
    costes_regulados: PropTypes.number,
    energia: PropTypes.number,
    impuestos: PropTypes.number,
    iva: PropTypes.number,
    chartType: PropTypes.string,
  }),
}

SummaryPieChart.defaultProps = {
  detail: {},
  detailChart: {},
  eco: false,
}

const Leyend = ({ dataLegend }) => {
  if (!dataLegend || !dataLegend.length) return null

  return (
    <S.Legend>
      <S.LegendSeparator />
      <S.LegendContent>
        {dataLegend.map(({ fontStyle, value, concept }) => {
          if (value) {
            return (
              <div key={v4()}>
                <Text fontSize='16px' fontType={fontStyle}>
                  {concept}
                </Text>
                <H3 fontType={fontStyle}>{value}</H3>
              </div>
            )
          }

          return false
        })}
      </S.LegendContent>
      <S.LegendSeparator />
    </S.Legend>
  )
}

Leyend.propTypes = { dataLegend: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired }

const Chart = ({ data, setActiveIndex, activeIndex, disabled }) => {
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index, payload }) => {
    const { x, y, ex, ey, textAnchor } = getCustomizedLabelValues({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      index,
    })

    const commonProps = {
      fontFamily: 'FS Emeric SemiBold',
      fontSize: 14,
      cursor: disabled || index === 1 ? 'auto' : 'pointer',
      onClick: index !== 1 ? () => setActiveIndex(index) : null,
      dominantBaseline: 'central',
      textAnchor: 'middle',
      textDecoration: index !== 1 && !disabled ? 'underline' : '',
    }
    return (
      <>
        {/* Label interior */}
        <text {...commonProps} textDecoration='' x={x} y={y} fill={theme.colors.white}>
          {`${payload.value.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}€`}
        </text>

        {/* Label exterior */}
        <text {...commonProps} x={ex - 4} y={ey - 6} textAnchor={textAnchor} fill={payload.color}>
          {payload.name}
        </text>
      </>
    )
  }

  const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => (
    <g>
      {/* Sector original (queso) */}
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill={fill}
        cursor='pointer'
      />

      {/* Sector exterior (línea) */}
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 2}
        outerRadius={outerRadius + 6}
        fill={fill}
      />
    </g>
  )

  return (
    <S.PieChartContainer className='pie-container'>
      <PieChart width={375} height={270}>
        <Pie
          dataKey='value'
          cx='50%'
          cy='50%'
          labelLine={false}
          outerRadius={110}
          innerRadius={54}
          /* Comienza a pintar abajo a la izda (startAngle), pinta de dcha a izda */
          startAngle={-120}
          endAngle={240}
          paddingAngle={1}
          isAnimationActive={false}
          data={data}
          label={renderCustomizedLabel}
          activeShape={disabled ? null : renderActiveShape}
          activeIndex={activeIndex}
        >
          {data.map((entry, index) => {
            const fill = entry.color
            const handleClick = () => {
              if (index !== 1 && !disabled) setActiveIndex(index)
            }
            return (
              <Cell
                style={{ cursor: disabled || index === 1 ? 'auto' : 'pointer' }}
                key={v4()}
                fill={fill}
                onClick={handleClick}
              />
            )
          })}
        </Pie>
      </PieChart>
    </S.PieChartContainer>
  )
}

Chart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default SummaryPieChart
