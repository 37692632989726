import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const TabPanelContainer = styled.div`
  width: ${(100 / 3) * 2}%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
  }
`

export const TabPanelHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  ${({ isIndividual }) => isIndividual && 'display:none;'}

  @media (max-width: ${theme.breakpoints.tablet}px) {
    justify-content: center;
  }
`

export const TabPanelContent = styled.div`
  width: 100%;
  height: auto;
  padding: 35px;
  background-color: transparent;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: ${theme.borderRadius};
  ${({ count }) => count === 1 && `border-top-right-radius: ${theme.borderRadius};`}

  > div {
    width: 100%;
    overflow: hidden;

    > div {
      width: ${({ contentWidth }) => contentWidth}%;
      display: flex;
      transform: translateX(-${({ active, count }) => active * (100 / count)}%);
      transition: 0.3s ease-in-out;
    }
  }

  @media (max-width: ${theme.breakpoints.mobile}px) {
    padding: 20px 10px;
  }
`

export const TabHeader = styled.button`
  display: flex;
  flex-direction: column;
  width: 92px;
  height: 71px;
  padding: 15px 25px;
  background-color: ${theme.colors.white};
  position: relative;
  top: 2px;
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ color }) => color};
  border-bottom: none;
  ${({ active }) => !active && 'opacity:0.4;'}
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: ${theme.borderRadius};
  border-top-right-radius: ${theme.borderRadius};
  margin-right: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-family: 'FS Emeric SemiBold';

  &:last-child {
    margin-right: 0;
  }
`

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
`

export const ButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const EcoItem = styled.div`
  width: 30px;
  margin-left: -30px;

  svg {
    stroke: ${({ isActive }) => (isActive ? theme.colors.eco.regular : theme.colors.eco.inactive)};
  }
`
export const ButtonIcon = styled.div`
  display: flex;
  width: 92px;
  flex-direction: column;
  align-items: center;
`
