import qs from 'qs'

import { apiBattery } from '../api'

export const getBatteryData = ({ token, factnum, supply, nif }) => {
  const params = qs.stringify({ invoice: factnum, supply }, { skipNulls: true })
  return apiBattery.get(`/bateria/user?${params}`, {
    validateStatus: () => true,
    headers: {
      nif,
      tokenRDI: token,
    },
  })
}
