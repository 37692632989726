import styled from 'styled-components/macro'

export const DefaultLayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  @media only percy {
    overflow-x: visible;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const RouteContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`
