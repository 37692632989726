import React, { useState } from 'react'

const useRefScrollDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  React.useEffect(() => {
    if (ref.current) {
      const { current } = ref
      const newWidth = Math.round(current.scrollWidth)
      const newHeight = Math.round(current.scrollHeight)
      const isResized = dimensions.width !== newWidth || dimensions.height !== newHeight
      if (isResized) {
        setDimensions({ width: newWidth, height: newHeight })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
  return dimensions
}

export default useRefScrollDimensions
