import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { theme } from '~/styles/theme'

/**
  Uso:

  <Text
    fontSize='16px' // Tamaño de fuente (16px por defecto)
    fontType='medium' // estilo de fuente ['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold]] (regular por defecto)
    fontColor='#ffffff' // color de fuente (azul oscuro por defecto)
    fontLineHeight='22px' // line-height (22px por defecto)
  >
    Puedes meter lo que quieras
  </Text>
*/

const Text = ({ children, ...props }) => <S.TextContainer {...props}>{children}</S.TextContainer>

Text.defaultProps = {
  children: null,
  fontSize: '14px',
  fontType: 'light',
  fontColor: theme.colors.blue.regular,
  fontLineHeight: '17px',
}

Text.propTypes = {
  children: PropTypes.node,
  fontSize: PropTypes.string,
  fontType: PropTypes.oneOf(['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold']),
  fontColor: PropTypes.string,
  fontLineHeight: PropTypes.string,
}

export default Text
