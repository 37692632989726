import ecoLogo from '~/assets/images/eco-logo.png'
import co2 from '~/assets/images/co2.png'
import aenor1 from '~/assets/images/9001.png'
import aenor2 from '~/assets/images/14001.png'
import aenor3 from '~/assets/images/45001.png'
import aenor4 from '~/assets/images/covid.png'
import djsi from '~/assets/images/djsi.png'
import android from '~/assets/images/appStore2.png'
import google from '~/assets/images/googleplay2.png'
import instagram from '~/assets/images/instagram.png'
import facebook from '~/assets/images/facebook.png'
import linkedin from '~/assets/images/linkedin.png'
import twitter from '~/assets/images/twitter.png'

export const getFooterItems = t => {
  const regularLinks = [
    {
      title: t('Luz y gas'),
      items: [
        {
          name: t('Tarifa Compromiso'),
          links: {
            es: 'https://www.naturgy.es/hogar/luz/tarifa_compromiso',
            ct: 'https://www.naturgy.es/ca/llar/llum/tarifa_compromis',
            gl: 'https://www.naturgy.es/gl/fogar/luz/tarifa_compromiso',
            eu: 'https://www.naturgy.es/hogar/luz/tarifa_compromiso',
          },
        },
        {
          name: t('Tarifa Plana Zen'),
          links: {
            es: 'https://www.naturgy.es/hogar/luz_y_gas/tarifa_plana_zen',
            ct: 'https://www.naturgy.es/ca/llar/llum_i_gas/tarifa_plana_zen',
            gl: 'https://www.naturgy.es/gl/fogar/luz_e_gas/tarifa_plana_zen',
            eu: 'https://www.naturgy.es/hogar/luz_y_gas/tarifa_plana_zen',
          },
        },
        {
          name: t('Tarifa Por Uso'),
          links: {
            es: 'https://www.naturgy.es/hogar/luz_y_gas/tarifa_por_uso',
            ct: 'https://www.naturgy.es/ca/llar/llum_i_gas/tarifa_per_us',
            gl: 'https://www.naturgy.es/gl/fogar/luz_e_gas/tarifa_por_uso',
            eu: 'https://www.naturgy.es/hogar/luz_y_gas/tarifa_por_uso',
          },
        },
        {
          name: t('Tarifa Noche'),
          links: {
            es: 'https://www.naturgy.es/hogar/luz/tarifa_noche',
            ct: 'https://www.naturgy.es/ca/llar/llum/tarifa_nit',
            gl: 'https://www.naturgy.es/gl/fogar/luz/tarifa_noite',
            eu: 'https://www.naturgy.es/hogar/luz/tarifa_noche',
          },
        },
        {
          name: t('Tarifa Digital'),
          links: {
            es: 'https://www.naturgy.es/hogar/luz_y_gas/tarifa_digital',
            ct: 'https://www.naturgy.es/ca/llar/llum_i_gas/tarifa_digital',
            gl: 'https://www.naturgy.es/gl/fogar/luz_e_gas/tarifa_digital',
            eu: 'https://www.naturgy.es/hogar/luz_y_gas/tarifa_digital',
          },
        },
      ],
    },
    {
      title: t('Servicios '),
      items: [
        {
          name: t('Mantenimientos anuales'),
          links: {
            es: 'https://www.naturgy.es/hogar/servicios/asistencia_para_tu_hogar/mantenimientos_anuales',
            ct: 'https://www.naturgy.es/ca/llar/serveis/assistencia_per_a_la_teva_llar/manteniments_anuals',
            gl: 'https://www.naturgy.es/gl/fogar/servizos/asistencia_para_o_teu_fogar/mantementos_anuais',
            eu: 'https://www.naturgy.es/hogar/servicios/asistencia_para_tu_hogar/mantenimientos_anuales',
          },
        },
        {
          name: t('Reparación e instalación urgente'),
          links: {
            es: 'https://www.naturgy.es/hogar/servicios/asistencia_para_tu_hogar/reparacion_e_instalacion_urgente',
            ct: 'https://www.naturgy.es/ca/llar/contractar/assistencia_per_a_la_teva_llar/reparacio_i_installacio_urgent',
            gl: 'https://www.naturgy.es/gl/fogar/servizos_e_reparacion/mantemento_e_reparacion/reparacion_e_instalacion_urxente',
            eu: 'https://www.naturgy.es/hogar/servicios/asistencia_para_tu_hogar/reparacion_e_instalacion_urgente',
          },
        },
        {
          name: t('Climatización y Calderas'),
          links: {
            es: 'https://www.naturgy.es/hogar/servicios/asistencia_para_tu_hogar/climatizacion_y_calderas',
            ct: 'https://www.naturgy.es/ca/llar/serveis/assistencia_per_a_la_teva_llar/climatitzacio_i_calderes',
            gl: 'https://www.naturgy.es/gl/fogar/contratar/eficiencia_enerxetica/climatizacion_e_caldeiras',
            eu: 'https://www.naturgy.es/hogar/servicios/asistencia_para_tu_hogar/climatizacion_y_calderas',
          },
        },
      ],
    },
    {
      title: t('Beneficios'),
      items: [
        {
          name: t('Ventajas Clientes'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/ventajas_clientes',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/avantatges_clients',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/vantaxes_clientes',
            eu: 'https://www.naturgy.es/hogar/ayuda/ventajas_clientes',
          },
        },
        {
          name: t('Tarjeta Naturgy'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/tarjeta_naturgy',
            ct: 'https://www.naturgy.es/llar/ajuda/targeta_naturgy',
            gl: 'https://www.naturgy.es/fogar/axuda/tarxeta_naturgy',
            eu: 'https://www.naturgy.es/hogar/ayuda/tarjeta_naturgy',
          },
        },
        {
          name: t('Facilidades de pago'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/facilidades_de_pago',
            ct: 'https://www.naturgy.es/llar/ajuda/facilitats_de_pagament',
            gl: 'https://www.naturgy.es/fogar/axuda/facilidades_de_pagamento',
            eu: 'https://www.naturgy.es/hogar/ayuda/facilidades_de_pago',
          },
        },
        {
          name: t('Conoce Área Clientes'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/conoce_area_clientes',
            ct: 'https://www.naturgy.es/llar/ajuda/coneix_area_clients',
            gl: 'https://www.naturgy.es/fogar/axuda/conece_area_clientes',
            eu: 'https://www.naturgy.es/hogar/ayuda/conoce_area_clientes',
          },
        },
      ],
    },
    {
      title: t('Ayuda'),
      items: [
        {
          name: t('Preguntas frecuentes'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/preguntas_frecuentes',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/preguntes_frequents',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/preguntas_frecuentes',
            eu: 'https://www.naturgy.es/hogar/ayuda/preguntas_frecuentes',
          },
        },
        {
          name: t('Cómo contratar'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/como_contratar',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/com_contractar',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/como_contratar',
            eu: 'https://www.naturgy.es/hogar/ayuda/como_contratar',
          },
        },
        {
          name: t('Alta de gas'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/alta_de_gas',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/alta_de_gas',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/alta_de_gas',
            eu: 'https://www.naturgy.es/hogar/ayuda/alta_de_gas',
          },
        },
        {
          name: t('Servicios de videointerpretación'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/servicio_de_videointerpretacion',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/servei_de_videointerpretacio',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/servizo_de_videointerpretacion',
            eu: 'https://www.naturgy.es/hogar/ayuda/servicio_de_videointerpretacion',
          },
        },
        {
          name: t('App móvil Clientes'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/app_movil_clientes',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/app_movil_clients',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/app_movil_clientes',
            eu: 'https://www.naturgy.es/hogar/ayuda/app_movil_clientes',
          },
        },
        {
          name: t('App Servicios'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/app_servicios',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/app_serveis',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/app_servicios',
            eu: 'https://www.naturgy.es/hogar/ayuda/app_servicios',
          },
        },
        {
          name: t('Política de reclamaciones'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/politica_de_reclamaciones',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/politica_de_reclamacions',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/politica_de_reclamacions',
            eu: 'https://www.naturgy.es/hogar/ayuda/politica_de_reclamaciones',
          },
        },
        {
          name: t('Sistema Arbitral de Consumo'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/sistema_arbitral_de_consumo',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/sistema_arbitral_de_consum',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/sistema_arbitral_de_consumo',
            eu: 'https://www.naturgy.es/hogar/ayuda/sistema_arbitral_de_consumo',
          },
        },
        {
          name: t('Consejos de ciberseguridad'),
          links: {
            es: 'https://www.naturgy.es/hogar/ayuda/consejos_de_ciberseguridad',
            ct: 'https://www.naturgy.es/ca/llar/ajuda/consells_de_ciberseguretat',
            gl: 'https://www.naturgy.es/gl/fogar/axuda/consellos_de_ciberseguridad',
            eu: 'https://www.naturgy.es/hogar/ayuda/consejos_de_ciberseguridad',
          },
        },
        {
          name: t('Resolvemos tus dudas'),
          links: {
            es: 'https://www.naturgy.es/hogar/fnt_hogar/resolvemos_tus_dudas',
            ct: 'https://www.naturgy.es/ca/llar/ca_es_fnt_hogar/resolem_els_teus_dubtes',
            gl: 'https://www.naturgy.es/gl/fogar/gl_es_fnt_hogar/resolvemos_as_tuas_dubidas',
            eu: 'https://www.naturgy.es/hogar/fnt_hogar/resolvemos_tus_dudas',
          },
        },
        {
          name: t('Certificaciones de interés'),
          links: {
            es: 'https://www.naturgy.es/legal/es/certificaciones',
            ct: 'https://www.naturgy.es/legal/ca/certificacions',
            gl: 'https://www.naturgy.es/legal/gl/certificacions',
            eu: 'https://www.naturgy.es/legal/es/certificaciones',
          },
        },
        {
          name: t('Asistente virtual'),
          links: {
            es: 'https://www.naturgy.es/asistente_virtual',
            ct: 'https://www.naturgy.es/ca/assistent_virtual',
            gl: null,
            eu: 'https://www.naturgy.es/asistente_virtual',
          },
        },
        {
          name: t('¿Quieres colaborar con Naturgy?'),
          links: {
            es: 'https://www.naturgy.es/colaboradores_naturgy',
            ct: 'https://www.naturgy.es/ca/llar/ca_es_fnt_hogar/collaboradors_naturgy',
            gl: 'https://www.naturgy.es/gl/colaboradores_naturgy',
            eu: 'https://www.naturgy.es/colaboradores_naturgy',
          },
        },
      ],
    },
    {
      title: t('Ahorro y eficiencia'),
      items: [
        {
          name: t('Consejos de ahorro energético'),
          links: {
            es: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/consejos_de_ahorro_energetico',
            ct: 'https://www.naturgy.es/ca/llar/eficiencia/eficiencia_energetica/consells_de_estalvi_energetic',
            gl: 'https://www.naturgy.es/gl/fogar/eficiencia/eficiencia_enerxetica/consellos_de_aforro_enerxetico',
            eu: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/consejos_de_ahorro_energetico',
          },
        },
        {
          name: t('Climatización eficiente'),
          links: {
            es: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/climatizacion_eficiente',
            ct: 'https://www.naturgy.es/ca/llar/eficiencia/eficiencia_energetica/climatitzacio_eficient',
            gl: 'https://www.naturgy.es/gl/fogar/eficiencia/eficiencia_enerxetica/climatizacion_eficiente',
            eu: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/climatizacion_eficiente',
          },
        },
        {
          name: t('Iluminación eficiente'),
          links: {
            es: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/iluminacion_eficiente',
            ct: 'https://www.naturgy.es/ca/llar/eficiencia/eficiencia_energetica/iluminacio_eficient',
            gl: 'https://www.naturgy.es/gl/fogar/eficiencia/eficiencia_enerxetica/iluminacion_eficiente',
            eu: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/iluminacion_eficiente',
          },
        },
        {
          name: t('Seguridad en tus instalaciones'),
          links: {
            es: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/seguridad_de_tus_instalaciones',
            ct: 'https://www.naturgy.es/ca/llar/eficiencia/eficiencia_energetica/seguretat_de_les_teves_installacions',
            gl: 'https://www.naturgy.es/gl/fogar/eficiencia/eficiencia_enerxetica/seguridade_de_as_tuas_instalacions',
            eu: 'https://www.naturgy.es/hogar/eficiencia/eficiencia_energetica/seguridad_de_tus_instalaciones',
          },
        },
        {
          name: t('Blog Buenas Energías'),
          links: {
            es: 'https://www.naturgy.es/hogar/blog_buenas_energias',
            ct: 'https://www.naturgy.es/hogar/blog_buenas_energias',
            gl: 'https://www.naturgy.es/hogar/blog_buenas_energias',
            eu: 'https://www.naturgy.es/hogar/blog_buenas_energias',
          },
        },
      ],
    },
  ]
  const imagesLinks = {
    column1: [
      {
        image: aenor1,
        link: 'https://www.naturgy.es/files/ISO_9001_Naturgy_Iberia_Certificado_ER-06992011-00300.pdf',
      },
      {
        image: aenor2,
        link: 'https://www.naturgy.es/files/ISO_14001_Naturgy_Iberia_S.A_certificado_GA-20110302-00300.pdf',
      },
      {
        image: aenor3,
        link: 'https://www.naturgy.es/files/ISO_45001_NTGY_GROUP_SST-0007-2012_2021-03-31_ES.pdf',
      },
      {
        image: aenor4,
        link: null,
      },

      {
        image: ecoLogo,
        link: null,
      },
      {
        image: co2,
        link: null,
      },
      {
        image: djsi,
        link: null,
      },
    ],
    column2: [
      {
        image: android,
        link: 'https://apps.apple.com/es/app/naturgy-clientes/id1053505418',
      },
      {
        image: google,
        link: 'https://play.google.com/store/apps/details?id=com.gnf.apm&hl=es',
      },
    ],
  }

  const rrssLinks = [
    {
      image: instagram,
      link: 'https://www.instagram.com/naturgyclientes/',
    },
    {
      image: facebook,
      link: 'https://www.facebook.com/NaturgyEspanaClientes',
    },
    {
      image: twitter,
      link: 'https://twitter.com/naturgyclientes',
    },
    {
      image: linkedin,
      link: 'https://www.linkedin.com/showcase/naturgy-servicios-y-actualidad/',
    },
  ]
  const policyLinks = [
    {
      name: t('Accesibilidad'),
      fontType: 'semibold',
      links: {
        es: 'https://www.naturgy.es/legal/es/accesibilidad',
        ct: 'https://www.naturgy.es/ca/llar/es_corporatiu/accessibilitat',
        gl: 'https://www.naturgy.es/gl/legal/gl/accesibilidade',
        eu: 'https://www.naturgy.es/hogar/luz/tarifa_compromiso',
      },
    },
    {
      name: t('Nota Legal'),
      fontType: 'semibold',
      links: {
        es: 'https://www.naturgy.es/legal/es/nota_legal',
        ct: 'https://www.naturgy.es/ca/legal/ca/nota_legal',
        gl: 'https://www.naturgy.es/gl/fogar/es_corporativo/nota_legal',
        eu: 'https://www.naturgy.es/legal/es/nota_legal',
      },
    },
    {
      name: t('Política de Privacidad'),
      fontType: 'semibold',
      links: {
        es: 'https://www.naturgy.es/legal/politica_privacidad_naturgy',
        ct: 'https://www.naturgy.es/ca/legal/politica_de_privacitat_naturgy',
        gl: 'https://www.naturgy.es/gl/legal/politica_de_privacidade_naturgy',
        eu: 'https://www.naturgy.es/legal/politica_privacidad_naturgy',
      },
    },
    {
      name: t('Política de Cookies'),
      fontType: 'semibold',
      links: {
        es: 'https://www.naturgy.es/legal/es/politica_de_cookies',
        ct: 'https://www.naturgy.es/ca/legal/ca/politica_de_cookies',
        gl: 'https://www.naturgy.es/gl/legal/gl/politica_de_cookies',
        eu: 'https://www.naturgy.es/legal/es/politica_de_cookies',
      },
    },
    {
      name: t('Contacto'),
      fontType: 'semibold',
      links: {
        es: 'https://www.naturgy.es/legal/es/contacto',
        ct: 'https://www.naturgy.es/ca/legal/ca/contacte',
        gl: 'https://www.naturgy.es/gl/legal/gl/contacto',
        eu: 'https://www.naturgy.es/legal/es/contacto',
      },
    },
    {
      name: t('Clientes: 900 100 251'),
      fontType: 'medium',
      links: {
        es: 'tel:900100251',
        ct: 'tel:900100251',
        gl: 'tel:900100251',
        eu: 'tel:900100251',
      },
    },
    {
      name: t('Contratación: 900 799 799'),
      fontType: 'medium',
      links: {
        es: 'tel:900799799',
        ct: 'tel:900799799',
        gl: 'tel:900799799',
        eu: 'tel:900799799',
      },
    },
  ]

  const footerLinks = {
    regularLinks,
    imagesLinks,
    rrssLinks,
    policyLinks,
  }

  return footerLinks
}
