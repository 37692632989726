import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const LinkRegularContainer = styled.div`
  width: auto;

  a {
    width: 100%;
    height: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ linkColor }) => linkColor || theme.colors.black};
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      color: ${theme.colors.black};
    }
  }
`
