import * as pages from '../components/pages'
import * as layouts from '../components/pages/_layouts'

let newcoComponent = pages.UnderConstruction
if (process.env.REACT_APP_NEWCO_ACCESS === 'enabled') {
  newcoComponent = pages.Access
}

const paths = {
  access: {
    path: '/access/:token',
    component: pages.Access,
    parent: layouts.FullLayout,
    isInMenu: false,
  },
  home: {
    path: '/home',
    component: pages.Home,
    parent: layouts.DefaultLayout,
    isInMenu: true,
  },
  error: {
    path: '/error',
    component: pages.ErrorPage,
    parent: layouts.FullLayout,
    isInMenu: false,
  },
  accessNewCo: {
    path: '/access-newco/:newcoQs',
    component: newcoComponent,
    parent: layouts.FullLayout,
    isInMenu: false,
  },
}

export default paths
