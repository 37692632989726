import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const Spinner = styled.div`
  max-height: 100vh;
  bottom: 0;
  background: ${theme.colors.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999;
  min-height: 500px;

  > img {
    width: 100%;
    max-width: 250px;
    @media (max-width: ${theme.breakpoints.tablet}px) {
      max-width: 100px;
      max-height: 100px;
    }
  }
`

export const iframeModeStyle = { height: window.screen.height * 0.75, maxHeight: window.screen.height * 0.75 }
