import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import qs from 'qs'
import PropTypes from 'prop-types'
import { setToken } from '~/store/modules/auth/actions'

const Access = ({ location }) => {
  const { token, newcoQs } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const qc = location.search.includes('qc=') ? qs.parse(location.search.substring(1, location.search.length)).qc : null
  const iframe = location.search.includes('iframe=')
    ? qs.parse(location.search.substring(1, location.search.length)).iframe
    : null
  const lng = location.search.includes('lng=')
    ? qs.parse(location.search.substring(1, location.search.length)).lng
    : null
  const factnum = location.search.includes('factnum=')
    ? qs.parse(location.search.substring(1, location.search.length)).factnum
    : null
  const tokenkey = location.search.includes('tokenkey=')
    ? qs.parse(location.search.substring(1, location.search.length)).tokenkey
    : null
  if (!token && !newcoQs) {
    history.push('error')
  } else {
    dispatch(setToken({ token, history, qc, iframe, lng, factnum, newcoQs, tokenkey }))
  }

  return <div />
}

Access.defaultProps = { location: '' }

Access.propTypes = { location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]) }
export default Access
