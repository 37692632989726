import styled from 'styled-components/macro'

export const PromotionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;

  > h3 {
    > span {
      font-size: 14px;
    }
  }
`
