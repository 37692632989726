import React from 'react'
import { PropTypes } from 'prop-types'
import * as S from './styled'
import { Button } from '~/components/atoms'
import { Flecha1 } from '~/assets/icons'

/**
  Uso:

  <ScrollInteraction
    show={true} // Si se muestra el botón para el scroll por módulos o no (por defecto no se muestra)
  />

*/

const ScrollInteraction = ({ show }) => {
  const handlerScroll = () => {
    const layout = document?.getElementById('default-layout-container')
    const resume = document?.getElementById('resume-container').offsetTop

    layout.scroll({ top: resume, left: 0, behavior: 'smooth' })
  }

  return (
    <S.ScrollInteractionContainer show={show}>
      <Button classBtn='icon' icon={Flecha1} handleClick={handlerScroll} />
    </S.ScrollInteractionContainer>
  )
}

ScrollInteraction.defaultProps = { show: false }

ScrollInteraction.propTypes = { show: PropTypes.bool }

export default ScrollInteraction
