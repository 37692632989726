import produce from 'immer'

import { Types } from './constants'

export const INITIAL_STATE = {
  token: null,
  invoice: null,
  client: null,
  summary: null,
  contacts: null,
  notifications: null,
  discounts: null,
  adjustments: null,
  taxes: null,
  qc: null,
  iframeMode: false,
  factnum: null,
  isFetching: false,
  qs: null,
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_TOKEN_SUCCESS: {
        draft.token = action.payload.token
        break
      }
      case Types.SET_QS_SUCCESS: {
        draft.qs = action.payload.qs
        break
      }

      case Types.SET_CLIENT_DATA: {
        draft.client = action.payload.client
        break
      }
      case Types.SET_INVOICE_DATA: {
        draft.invoice = action.payload.invoice
        break
      }
      case Types.SET_SUMMARY_DATA: {
        draft.summary = action.payload.summary
        break
      }
      case Types.SET_CONTACTS: {
        draft.contacts = action.payload.contacts
        break
      }
      case Types.SET_NOTIFICATIONS_DATA: {
        draft.notifications = action.payload.notifications
        break
      }
      case Types.SET_DISCOUNTS_DATA: {
        draft.discounts = action.payload.discounts
        break
      }
      case Types.SET_ADJUSTMENTS_DATA: {
        draft.adjustments = action.payload.adjustments
        break
      }
      case Types.SET_TAXES_DATA: {
        draft.taxes = action.payload.taxes
        break
      }
      case Types.SET_QC: {
        draft.qc = action.payload.qc
        break
      }
      case Types.SET_IFRAME_MODE: {
        draft.iframeMode = action.payload
        break
      }
      case Types.SET_FACNUM: {
        draft.factnum = action.payload.factnum
        break
      }
      case Types.SET_IS_FETCHING: {
        draft.isFetching = action.payload
        break
      }
      default:
        break
    }
  })
}
