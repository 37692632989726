import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { PropTypes } from 'prop-types'
import * as S from './styled'
import { H2, Text, H3, Icon } from '~/components/atoms'
import { theme } from '~/styles/theme'
import { getActiveLanguage, priceFormat } from '~/config/utils'
import { FinancingIcon } from '~/assets/icons'

const Financing = ({ count }) => {
  const { invoice } = useSelector(state => state.auth || {})
  const { financings } = invoice || {}
  const { total, data } = financings || {}

  if (!total || !data) return null

  return (
    <S.FinancingContainer id='financing-summary-container' data-cy='financing' count={count}>
      <FinancingTitle />
      <FinancingList data={data} />
      <FinancingTotal total={total} />
    </S.FinancingContainer>
  )
}

Financing.defaultProps = { count: 1 }

Financing.propTypes = { count: PropTypes.number }

const FinancingTitle = () => {
  const { t } = useTranslation()

  return (
    <S.FinancingTitle>
      <Icon Source={FinancingIcon} width='30px' height='30px' color={theme.colors.orange.regular} />
      <H2>{t('Financiaciones')}</H2>
    </S.FinancingTitle>
  )
}

const FinancingList = ({ data }) => {
  const lng = getActiveLanguage()

  if (!data) return null

  return (
    <S.List>
      {data.map(item => {
        if (!item.product) return false

        return (
          <S.FinancingDetailItem key={v4()} data-cy={`financiacion-${item.id}`}>
            <Text>{item.product.name[lng]}</Text>
            <H3>{priceFormat(parseFloat(item.value))}</H3>
          </S.FinancingDetailItem>
        )
      })}
    </S.List>
  )
}

FinancingList.defaultProps = { data: null }

FinancingList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      product: PropTypes.arrayOf(PropTypes.string),
      value: PropTypes.string,
    })
  ),
}

const FinancingTotal = ({ total }) => {
  const { t } = useTranslation()

  return (
    <S.FinancingTotal>
      <Text fontSize='16px' fontType='semibold'>
        {t('Total financiaciones')}
      </Text>
      <H3 fontType='semibold'>{priceFormat(total)}</H3>
    </S.FinancingTotal>
  )
}

FinancingTotal.defaultProps = { total: 0 }

FinancingTotal.propTypes = { total: PropTypes.number }

export default Financing
