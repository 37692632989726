import strapi from '../strapi'

export const getFinancingBySlug = slugList => {
  let stringIds = ''
  slugList.map((id, index) => {
    stringIds = index === 0 ? `idRdi=${id}` : `${stringIds}&idRdi=${id}`
    return false
  })
  return strapi.get(`/services?${stringIds}`)
}
