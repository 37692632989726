import styled from 'styled-components/macro'
import { Text, PercentBar } from '~/components/atoms'
import { theme } from '~/styles/theme'

export const ConsumptionContainer = styled.div`
  text-align: center;
  padding: 0 50px;

  @media (max-width: ${theme.breakpoints.mobile}px) {
    padding: 0;
  }
`

export const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled.div`
  > p {
    &:first-child {
      margin-bottom: 20px;
    }
  }
`

export const CardPercentBar = styled(PercentBar)`
  margin-bottom: 20px;
`

export const BarLeyend = styled(Text)`
  text-align: left;
`

export const InfoText = styled(Text)`
  height: 38px;
  margin-bottom: 20px;
  padding: 0 0 20px 0;
  border-bottom: 0.5px solid ${theme.colors.separator};
`
