import React, { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'
import * as S from './styled'
import { Text, Icon, H2, H4 } from '~/components/atoms'
import { getNotificationType } from '~/config/notificationTypes'
import { getActiveLanguage, checkNotificationByProduct, getDateByLocale } from '~/config/utils'
import { theme } from '~/styles/theme'
import { BatteryWidget } from '../../molecules'
import { InfoIcon } from '~/assets/icons'

const Notifications = () => {
  const { t } = useTranslation()
  const lng = getActiveLanguage()

  const { invoice } = useSelector(state => state.auth || {})
  const battery = invoice?.battery
  const { notifications } = invoice || {}

  const [notificationList, setNotificationList] = useState(null)
  const [notificationAdjustmentList, setNotificationAdjustmentList] = useState(null)

  useEffect(() => {
    if (notifications) {
      const list = []
      let facList = null

      notifications.map(notification => {
        const item = getNotificationType(notification, invoice, lng, t)
        if (
          item &&
          notification.slug !== 'motivos-refacturacion' &&
          notification.slug !== 'motivos-ajuste' &&
          notification.slug !== 'motivos-anulacion' &&
          notification.slug !== 'motivos-anulacion-2' &&
          notification.slug !== 'aviso-de-consumo-acumulado-gas' &&
          notification.slug !== 'aviso-de-consumo-acumulado-ele' &&
          notification.slug !== 'proxima-renovacion-tarifa-12-ele' &&
          notification.slug !== 'proxima-renovacion-tarifa-12-gas'
        ) {
          list.push(item)
        }

        if (item && notification.slug === 'motivos-ajuste') {
          if (!facList) {
            facList = {
              slug: notification.slug,
              description: item.description,
              list: [],
            }
          }

          facList.list.push(notification.params)
        }
        return false
      })
      setNotificationAdjustmentList(facList)
      setNotificationList(list)
    }
  }, [notifications, lng, invoice, t])

  const hasNotifications = !((!notificationList || notificationList.length === 0) && !notificationAdjustmentList)
  const hasBattery = battery?.showBattery

  const RenderNotifications = () => (
    <div>
      <S.NotificationsTitle>
        <Icon Source={InfoIcon} width='26px' height='26px' color={theme.colors.orange.regular} />
        <H2>{t('Información importante')}</H2>
      </S.NotificationsTitle>
      <S.NotificationsList>
        {notificationList?.map(notification => {
          const withAlternativeText = checkNotificationByProduct(notification, invoice)

          return (
            <S.NotificationItem key={v4()}>
              {notification.title && (
                <H4 fontType='semibold' dangerouslySetInnerHTML={{ __html: notification.title }} />
              )}
              {withAlternativeText
                ? notification.alternative && <Text dangerouslySetInnerHTML={{ __html: notification.alternative }} />
                : notification.description && <Text dangerouslySetInnerHTML={{ __html: notification.description }} />}
            </S.NotificationItem>
          )
        })}
        {notificationAdjustmentList && (
          <S.NotificationItem key={v4()}>
            {notificationAdjustmentList.description && (
              <H4 fontType='semibold'>{notificationAdjustmentList.description}</H4>
            )}
            {notificationAdjustmentList.list.map(n => {
              const formatedDate = getDateByLocale(n.fecha_factura_ajuste, getActiveLanguage(), 'dd.MM.yy')

              return (
                <Text key={v4()}>
                  {`${n.factura_ajuste} `}
                  <Trans values={{ formatedDate }}>de {{ formatedDate }}</Trans>
                </Text>
              )
            })}
          </S.NotificationItem>
        )}
      </S.NotificationsList>
    </div>
  )
  if (!hasNotifications && !hasBattery) return null
  return (
    <S.NotificationsContainer data-cy='notifications'>
      <div className='layout-wrapper'>
        <S.Content>
          {hasNotifications && <RenderNotifications />}
          <BatteryWidget />
        </S.Content>
      </div>
    </S.NotificationsContainer>
  )
}

export default Notifications
