import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { theme } from '~/styles/theme'
import { UserIcon } from '~/assets/icons'
import { Icon } from '~/components/atoms'

import * as S from './styled'

const UserOptions = () => {
  const { t } = useTranslation()

  const { invoice } = useSelector(state => state.auth || {})
  const { userName } = invoice || {}

  if (!userName) return null

  return (
    <S.UserOptionsContainer>
      <Icon Source={UserIcon} width='16px' height='16px' color={theme.colors.white} />
      <S.Label fontColor={theme.colors.white} fontType='light'>
        {t('Hola')}
      </S.Label>
      <S.Name fontColor={theme.colors.white} fontType='medium' data-cy='barra-navegacion-username'>
        {userName.toLowerCase()}
      </S.Name>
    </S.UserOptionsContainer>
  )
}

export default UserOptions
