import { format } from 'date-fns'
import { eu, es, ca, gl } from 'date-fns/locale'
import { theme } from '~/styles/theme'

export const dataTypeObject = {
  light: {
    measure: 'kWh',
    bars: [
      {
        key: 'previousYear',
        color: theme.colors.blue.light,
        colorEco: theme.colors.eco.dark,
      },
      {
        key: 'currentYear',
        color: theme.colors.blue.dark,
        colorEco: theme.colors.eco.regular,
      },
    ],
  },
  gas: {
    measure: 'kWh',
    bars: [
      {
        key: 'previousYear',
        color: theme.colors.orange.light,
        colorEco: theme.colors.eco.dark,
      },
      {
        key: 'currentYear',
        color: theme.colors.orange.regular,
        colorEco: theme.colors.eco.regular,
      },
    ],
  },
}

const getDateByLocale2 = (date, lang, formatStr) => {
  const locales = { es, eu, gl, ct: ca }
  return format(date, formatStr, { locale: locales[lang] })
}

export const parseData = (historyData, detalle, activeYears, lang) => {
  const currentYear = parseInt(format(new Date(detalle.to), 'yyyy'), 10)
  const previousYear = currentYear - 1

  const data = Array.from({ length: 12 }, (_, index) => ({
    month: getDateByLocale2(new Date(2019, index, 1), lang, 'MMM'),
    previousYear: 0,
    currentYear: 0,
    excessPrevious: 0,
    excessCurrent: 0,
  }))

  Object.keys(historyData).forEach(item => {
    const year = parseInt(format(new Date(parseInt(item, 10)), 'yyyy'), 10)
    const month = parseInt(format(new Date(parseInt(item, 10)), 'M'), 10) - 1
    const consumo =
      historyData[item].consumo ||
      (historyData[item].r1 || 0) +
        (historyData[item].r2 || 0) +
        (historyData[item].e1 || 0) +
        (historyData[item].e2 || 0) ||
      0
    const consexc = historyData[item].consexc || 0 // Asumiendo que existe un campo consexc

    if (year === currentYear) {
      data[month].currentYear += consumo
      data[month].excessCurrent += consexc
    } else if (year === previousYear) {
      data[month].previousYear += consumo
      data[month].excessPrevious += consexc
    }
  })

  Object.keys(activeYears).forEach(key => {
    if (!activeYears[key]) {
      delete data[key]
    }
  })

  return data
}

export const selectFillPattern = ({ bar, isMobile, eco, dataType }) => {
  let fillPattern
  const light = dataType === 'light'
  const gas = dataType === 'gas'
  const isPreviousYear = bar.key === 'previousYear'

  if (isPreviousYear && !isMobile && eco) {
    fillPattern = 'url(#patternEco)'
  }
  if (isPreviousYear && isMobile && eco) {
    fillPattern = bar.colorEco
  }
  if (isPreviousYear && !isMobile && !eco && light) {
    fillPattern = 'url(#patternLight)'
  }
  if (isPreviousYear && isMobile && light) {
    fillPattern = theme.colors.blue.chartLight
  }
  if (isPreviousYear && isMobile && eco && light) {
    fillPattern = theme.colors.eco.dark
  }
  if (isPreviousYear && isMobile && eco && gas) {
    fillPattern = theme.colors.eco.dark
  }
  if (isPreviousYear && !isMobile && !eco && gas) {
    fillPattern = 'url(#patternGas)'
  }
  if (isPreviousYear && isMobile && !eco && gas) {
    fillPattern = theme.colors.orange.light
  }
  if (!isPreviousYear && eco) {
    fillPattern = bar.colorEco
  }
  if (!isPreviousYear && !eco) {
    fillPattern = bar.color
  }

  return fillPattern
}
