import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const ServicesSummaryContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${theme.colors.blue.regular};
  border-radius: ${theme.borderRadius};
  padding: 24px ${({ count }) => (count < 3 ? '48px' : '24px')};
`
export const ServicesSummaryHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;

  > h2 {
    text-align: center;
    margin-bottom: 10px;
  }
`

export const ServicesIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IconGroup = styled.div`
  margin-bottom: 10px;
  display: flex;
`

export const ServicesIcons = styled.div`
  width: auto;
`
export const ServicesSummaryDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid ${theme.colors.separator};
  margin-bottom: 20px;

  > h3 {
    text-align: center;
    margin-bottom: 10px;
  }
`

export const ServiceSummaryList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const ServicePricesItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  align-items: center;

  > h3 {
    span {
      font-size: 14px;
    }
  }
`

export const ServicePricesTotalItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0 0;
  align-items: flex-end;

  > h3 {
    span {
      font-size: 14px;
    }
  }
`
