import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { theme } from '~/styles/theme'

/**
  Uso:

  <H3
    fontColor='#ffffff' // color de fuente (negro por defecto)
    fontType='medium' // estilo de fuente ['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold'] (light por defecto)
  >
    Lorem ipsum
  </H3>
*/

const H3 = ({ children, ...props }) => <S.H3 {...props}>{children}</S.H3>

H3.defaultProps = {
  children: null,
  fontColor: theme.colors.blue.regular,
  fontType: 'light',
}

H3.propTypes = {
  children: PropTypes.node,
  fontType: PropTypes.oneOf(['regular', 'thin', 'light', 'semibold', 'medium', 'core', 'bold']),
  fontColor: PropTypes.string,
}

export default H3
