import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'

export const ConsumptionContainer = styled.div`
  background-color: ${theme.colors.white};
  margin-bottom: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ConsumptionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  @media (max-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
    margin-bottom: 20px;

    > h1 {
      max-width: 100%;
    }
  }
`
