import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Menu } from '~/components/organisms'
import { ScrollInteraction } from '~/components/atoms'
import * as S from './styled'
import { useMediaQuery } from '~/hooks'
import useRefScrollDimensions from '~/hooks/useRefScrollDimensions'
import { postIframeHeightEvent } from '~/config/utils'

export default function DefaultLayout({ children }) {
  const [showButton, setShowButton] = useState(false)
  const { iframeMode } = useSelector(state => state.auth)
  const { isMobile } = useMediaQuery()

  const ref = createRef()
  const { height, width } = useRefScrollDimensions(ref)

  useEffect(() => {
    if (iframeMode) postIframeHeightEvent()
  }, [height, width, iframeMode])

  const handlerScroll = () => {
    const el = document?.getElementById('resume-container')
    const resumePosition = el.getBoundingClientRect().top

    if (resumePosition < 0) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  return (
    <S.DefaultLayoutWrapper id='default-layout-container' onScroll={isMobile ? handlerScroll : null} ref={ref}>
      <Menu />
      <S.ContentContainer>
        <S.ContentWrapper>
          <S.RouteContainer>{children}</S.RouteContainer>
        </S.ContentWrapper>
      </S.ContentContainer>
      {iframeMode && <div id='default-layout-last-item' />}
      {!iframeMode && <ScrollInteraction show={showButton} />}
    </S.DefaultLayoutWrapper>
  )
}

DefaultLayout.propTypes = { children: PropTypes.element.isRequired }
