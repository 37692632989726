import styled from 'styled-components/macro'
import { theme } from '~/styles/theme'
import { Text } from '~/components/atoms'

export const PercentBarContainer = styled.div`
  width: 100%;
`

export const Label = styled(Text)`
  color: ${theme.colors.white};
  z-index: 99;
`

export const BarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  background-color: ${theme.colors.gray.regular};
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`

export const PercentBar = styled.div`
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
  width: ${({ value }) => value}%;
  transition: width 0.3s;
  background-color: ${({ color }) => color};
  border-radius: 26px;
  z-index: 95;
`
