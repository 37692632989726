import { UserPhoneIcon, EleUrgencyIcon, GasUrgencyIcon } from '~/assets/icons'
import { theme } from '~/styles/theme'

export const getContactDescriptions = (contacts, t) => {
  if (!contacts) return []

  const list = []
  const descriptions = {
    phone_general: {
      image: UserPhoneIcon,
      order: 0,
      label: t('Atención al cliente'),
      color: theme.colors.orange.regular,
    },
    phone_ele: {
      image: EleUrgencyIcon,
      order: 1,
      label: t('Averías Eléctricas'),
      color: theme.colors.blue.dark,
      asistence: true,
    },
    phone_gas: {
      image: GasUrgencyIcon,
      order: 2,
      label: t('Urgencias de Gas'),
      color: theme.colors.blue.dark,
      asistence: true,
    },
  }

  Object.keys(contacts).forEach(key => {
    if (descriptions[key]) {
      const phones = []

      const phone = {
        order: descriptions[key].order,
        label: descriptions[key].label,
        image: descriptions[key].image,
        color: descriptions[key].color,
        phone: phones.concat(contacts[key]),
        key,
      }

      list.push(phone)
    }
  })

  const listOrdered = list.sort((a, b) => {
    if (a.order > b.order) {
      return 1
    }
    if (a.order < b.order) {
      return -1
    }

    return 0
  })

  return listOrdered
}
